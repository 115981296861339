import {createSlice} from '@reduxjs/toolkit'

const presentationSlice = createSlice({
  name: 'presentations',
  initialState: {
    selectedPresentation: null,
    currentSlide: null,
    isPaused: false,
    isMinimized: !!sessionStorage.getItem('isMinimizedPM'),
    playbackPresentation: null,
    patumSize: localStorage.getItem('patum-text-size') || '14',
    screenshotPresentation: null,
    microArticlePresentation: null,
    presentationsIsShown: false,
  },
  reducers: {
    setSelectedPresentation: (state, action) => {
      state.selectedPresentation = action.payload
    },
    setCurrentSlide: (state, action) => {
      state.currentSlide = action.payload
    },
    setIsPaused: (state, action) => {
      state.isPaused = action.payload
    },
    setIsMinimized: (state, action) => {
      localStorage.setItem('isMinimizedPM', action.payload)
      state.isMinimized = action.payload
    },
    setPatumSize: (state, action) => {
      state.patumSize = action.payload
      sessionStorage.setItem('patum-text-size', action.payload)
    },
    setPlaybackPresentation: (state, action) => {
      state.playbackPresentation = action.payload
    },
    setScreenshotPresentation: (state, action) => {
      state.screenshotPresentation = action.payload
    },
    setPresentationsIsShown: (state, action) => {
      state.presentationsIsShown = action.payload
    },
    setMicroArticlePresentation: (state, action) => {
      state.microArticlePresentation = action.payload
    },
  },
})

export const selectSelectedPresentation = (state) =>
  state.presentations.selectedPresentation

export const selectCurrentSlide = (state) => state.presentations.currentSlide

export const selectIsPaused = (state) => state.presentations.isPaused

export const selectIsMinimized = (state) => state.presentations.isMinimized

export const selectPlaybackPresentation = (state) =>
  state.presentations.playbackPresentation

export const selectPatumSize = (state) => state.presentations.patumSize

export const selectScreenshotPresentation = (state) =>
  state.presentations.screenshotPresentation

export const selectPresentationsIsShown = (state) => state.presentations.presentationsIsShown

export const selectMicroArticlePresentation = (state) => state.presentations.microArticlePresentation

export const {
  setSelectedPresentation,
  setPatumSize,
  setCurrentSlide,
  setPlaybackPresentation,
  setIsPaused,
  setIsMinimized,
  setScreenshotPresentation,
  setPresentationsIsShown,
  setMicroArticlePresentation
} = presentationSlice.actions

export default presentationSlice.reducer
