import React, {useEffect, useRef, useState} from 'react'
import ReactQuill, {Quill} from 'react-quill'
import {Button, Popover} from 'antd'
import 'react-quill/dist/quill.snow.css'
import {v4 as uuidv4} from 'uuid'
import {X} from 'lucide-react'
import {cn} from '../../../../utils/utils'
import {getStringFromHTML} from '../../lib/utils'
import Link2Icon from '../icons/Link2Icon'
import TextEditorLinkSetter from './TextEditorLinkSetter'
import PlayCircle2Icon from '../icons/PlayCircle2Icon'
import {useTranslation} from 'react-i18next'

const icons = Quill.import('ui/icons')

icons['link'] = <Link2Icon />
// icons['list'] = <NumListIcon/>
icons['list'] = icons['list'] || {}

// icons['list']['ordered'] = <Link2Icon/>

const CustomToolbar = ({ onLinkClick, id, onClose, onPlay }) => (
  <div id={id} style={{ position: 'relative' }}>
    <button className="ql-bold" />
    <button className="ql-italic" />
    <button className="ql-underline" />
    <button className="ql-link" onClick={onLinkClick}>
      <Link2Icon />
    </button>
    <button className="ql-list" value="bullet" />
    <button className="ql-list" value="ordered" />
    {onClose && (
      <Button
        type="text"
        onClick={onClose}
        icon={<X size={14} />}
        className="absolute right-1 top-1/2"
        style={{ transform: 'translateY(-50%)' }}
      />
    )}
    {true && (
      <span
        role="button"
        // onClick={onPlay}
        className="absolute opacity-50 pointer-events-none right-3 top-1/2 cursor-pointer translate-y-[-50%] flex items-center justify-center"
        type="text">
        <PlayCircle2Icon />
      </span>
    )}
  </div>
)

const CustomQuillEditor = ({
  onClose,
  className,
  maxCount,
  onChange,
  value,
  showCount,
  ...rest
}) => {
  const { t } = useTranslation()
  const [editorHtml, setEditorHtml] = useState(value || '')
  const [popoverVisible, setPopoverVisible] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [toolbarId] = useState(`toolbar-${uuidv4()}`)

  // **New State for Popover Position**
  const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 })

  const quillRef = useRef(null)
  const stringValue = getStringFromHTML(editorHtml)

  const handleChange = (html, delta, source, editor) => {
    // if (stringValue.length >= maxCount) {
    //     return;
    //     editor.deleteText(maxCount - 1, 1);
    // }
    onChange?.(html)
    setEditorHtml(html)
  }

  const keyDownHandler = (event) => {
    if (stringValue.length >= maxCount) {
      event.preventDefault()
    }
  }

  const handleLinkClick = (event) => {
    event?.preventDefault?.() // Prevent default link behavior

    const quill = quillRef.current.getEditor()
    const range = quill.getSelection()
    if (range) {
      const selectedText = quill.getText(range.index, range.length).trim()

      if (selectedText.length === 0) {
        // Optionally handle cases where no text is selected
        // return;
      }

      setSearchTerm(selectedText)

      // **Calculate Selection Position**
      const selection = window.getSelection()
      if (selection.rangeCount > 0 && selectedText.length !== 0) {
        const range = selection.getRangeAt(0).cloneRange()
        const rect = range.getBoundingClientRect()

        // Get the editor's bounding rectangle
        const editorRect =
          quillRef.current.editor.container.getBoundingClientRect()

        // Calculate position relative to the editor
        setPopoverPosition({
          top: rect.bottom - editorRect.top + 5, // 5px offset
          left: rect.left - editorRect.left,
        })
      }
      setPopoverVisible(true)
    }
  }

  const handleUrlSelect = (url) => {
    const quill = quillRef.current.getEditor()
    quill.focus()
    const range = quill.getSelection()
    if (range) {
      if (range.length > 0) {
        quill.format('link', url)
      } else {
        quill.insertText(range.index, url, 'link', url)
      }
    }
    setPopoverVisible(false)
  }

  const linkPopoverChangeHandler = (visible) => {
      setPopoverVisible(visible);
      if (!visible) {
          setSearchTerm('');
      }
  }

  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor()
      quill.getModule('toolbar').addHandler('link', handleLinkClick)
    }
  }, [])

  const popoverContent = <TextEditorLinkSetter searchValue={searchTerm} onSelect={handleUrlSelect} />


  return (
    <div className={cn('quill-container xh-[80px] relative', className)}>
      <CustomToolbar
        onClose={onClose}
        onLinkClick={handleLinkClick}
        id={toolbarId}
      />
      <ReactQuill
        ref={quillRef}
        value={value || ''}
        // onKeyDown={keyDownHandler}
        // className="h-[80px]"
        modules={{
          toolbar: {
            container: `#${toolbarId}`,
          },
        }}
        formats={['bold', 'italic', 'underline', 'link', 'list']}
        placeholder={t('presentation_mode.insert_description')}
        className="text-base"
        preserveWhitespace={true}
        {...rest}
        onChange={handleChange}
      />
      {showCount && (
        <div className="flex justify-end mt-2.5 absolute -bottom-[20px] right-[10px]">
          {maxCount && (
            <span className="text-[12px] text-[#2C476C50]">
              <span
                className={cn({
                  'text-red-500': stringValue?.length > maxCount,
                })}>
                {stringValue?.length}
              </span>
              /{maxCount}
            </span>
          )}
        </div>
      )}

      {/* **Updated Popover with Positioning** */}
      <Popover
        arrow={false}
        content={popoverContent}
        trigger="click"
        destroyTooltipOnHide
        open={popoverVisible}
        onOpenChange={linkPopoverChangeHandler}
        placement="bottomLeft" // Temporarily set; we'll override with absolute positioning
        // getPopupContainer={() => document.getElementById(toolbarId)}
      >
        {/* **Anchor Element Positioned Absolutely** */}
        <div
          style={{
            position: popoverPosition ? 'absolute' : 'relative',
            top: popoverPosition.top + 40,
            left: popoverPosition.left,
            zIndex: 1000, // Ensure it's above other elements
            pointerEvents: 'none', // Allow clicks to pass through
          }}>
          {/* Invisible trigger for Popover */}
          <div style={{ width: 1, height: 1 }} />
        </div>
      </Popover>
    </div>
  )
}

export default CustomQuillEditor
