import React, {useEffect, useMemo} from 'react'
import {cn} from '../../../../utils/utils'
import {CircleDot} from 'lucide-react'
import {useDispatch, useSelector} from 'react-redux'
import {useGetArticleQuery, useGetMicroArticleQuery,} from '../../store/presentationApi'
import ConnectedSpriteImageComponent from '../../../UI/ConnectedSpriteImageComponent'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {setSelectedSlide,} from '../../../../store/actions/slides'
import {getAllMicroArticles, getCategoriesData} from '../../../../store/selectors'
import {INITIAL_ICON_OBJECT, isIconKeyValid} from "../../../microArticle/utils";

const PlaybackSlideItem = ({
  isActive,
  id,
  isLoading,
  onClick,
  image,
  data,
  number,
  name,
  microArticleId,
  articleId,
}) => {
  const dispatch = useDispatch()

  const { data: microArticleData, refetch: refetchMicroArticle, isUninitialized } = useGetMicroArticleQuery(microArticleId, {
    skip: !microArticleId,
  })
  const { data: articleData } = useGetArticleQuery(articleId, {
    skip: !articleId,
  })

  const microArticles = useSelector(getAllMicroArticles)

  const article = articleData?.data?.[0]
  const microArticle = microArticleData?.data?.[0]

  const categories = useSelector(getCategoriesData)

  const subCategoryInfo = useMemo(() => {
    const subCategoryId = article?.subCategory?.find(
      (item) => item.isMain,
    )?.subCategoryId
    if (!subCategoryId || !categories?.length) {
      return null
    }

    let icon
    let color

    categories?.forEach((item) => {
      item.subCategories?.forEach?.((subCategory) => {
        if (subCategory?.id === subCategoryId) {
          icon = subCategory?.spriteIcon
          color = subCategory?.color
        }
      })
    })

    return { icon, color }
  }, [article, categories])

  const clickHandler = () => {
    dispatch(setSelectedSlide(data))
    onClick?.(id)
  }

  useEffect(() => {
    if (microArticles?.length && !isUninitialized
        // && microArticles?.find(item => +item?.id === +microArticleId)
    ) {
      refetchMicroArticle()
    }
  }, [microArticles,microArticleId, isUninitialized]);

  if (microArticleId && !microArticle) {
    return null
  }

  return (
    <>
      <div
        onClick={clickHandler}
        className={cn(
          'presentation-slide--item cursor-pointer mb-0.5 hover:bg-[#F6F6F6] text-[#2C476C]',
          {
            'bg-[#F6F6F6]': isActive,
          },
        )}>
        <small data-is-loading={isLoading} className="text-sm">
          {number}
        </small>
        <div
          data-is-loading={isLoading}
          className="scale-75 w-[30px] h-[30px] rounded-full flex items-center justify-center">
          {image ? (
            <img className="w-5 h-5 rounded" src={image} alt="slide" />
          ) : article ? (
            <ConnectedSpriteImageComponent name={subCategoryInfo?.icon} />
          ) : microArticle ? (
            <div style={{filter: "drop-shadow(0px 0px 1px #999)"}}>
              <ConnectedSpriteImageComponent name={isIconKeyValid(microArticle.iconKey) ? microArticle.iconKey : INITIAL_ICON_OBJECT.darkKey} />
            </div>
          ) : (
            <CircleDot
              color="#2C476C"
              fontSize={12}
              className="!h-[28px] !w-[28px]"
            />
          )}
        </div>
        <div data-is-loading={isLoading} className="flex flex-1 items-center">
          <span className="flex-1 block text-base line-clamp-1 ">{name}</span>
        </div>
      </div>
    </>
  )
}

export default PlaybackSlideItem
