import React, {cloneElement, useMemo} from 'react'
import ListSkeleton from '../elements/ListSkeleton'
import InfiniteScroll from 'react-infinite-scroll-component'
import {cn} from '../../../../utils/utils'
import ConnectedSpriteImageComponent from '../../../UI/ConnectedSpriteImageComponent'
import {Button, Input, Menu, Popover, Skeleton} from 'antd'
import {useGetArticleCategoriesQuery, useGetArticlesQuery,} from '../../store/presentationApi'
import i18n from '../../../../locales/i18n'
import SearchIcon from '../icons/SearchIcon'
import SettingsIcon from '../icons/SettingsIcon'
import FilterIcon from '../icons/FilterIcon'
import {Check} from 'lucide-react'
import useDebounce from '../../../../hooks/useDebounce'
import {useTranslation} from 'react-i18next'

const PresentationArticlesList = ({ selectedArticles, onSelect }) => {
  const lng = i18n.language === 'en' ? 2 : 1
  const { t } = useTranslation()
  const [pageNumber, setPageNumber] = React.useState(0)
  const [filterIsOpen, setFilterIsOpen] = React.useState(false)
  const [termValue, setTermValue] = React.useState('')
  const debouncedTermValue = useDebounce(termValue, 500)

  const [activeCategory, setActiveCategory] = React.useState(null)

  const {
    data: articlesData,
    isFetching,
    isLoading,
  } = useGetArticlesQuery(
    {
      pageNumber: pageNumber,
      pageSize: 20,
      languageId: lng,
      Term: debouncedTermValue,
      CategoryId: activeCategory?.key || undefined,
    },
    {
      skip: debouncedTermValue?.length < 3,
    },
  )
  const { data: categoriesData, isFetching: categoriesIsFetching } =
    useGetArticleCategoriesQuery({})

  const records = articlesData?.data?.[0]?.items || []
  const filteredTotal = articlesData?.data?.[0]?.total

  const items = useMemo(() => {
    if (!categoriesData?.data?.[0]?.items) return []

    return [
      {
        id: 0,
        title: t('presentation_mode.all'),
        key: 0,
        // spriteIcon: 'https://cdn-icons-png.flaticon.com/512/149/149071.png'
      },
      ...categoriesData?.data?.[0]?.items,
    ].map((item) => {
      return {
        icon:
          item.key === 0 ? (
            <FilterIcon width={24} height={26} />
          ) : (
            <ConnectedSpriteImageComponent name={item.spriteIcon} />
          ),
        name: item.title,
        key: item.id,
      }
    })
  }, [categoriesData])

  const handleNextPage = () => {
    setPageNumber(pageNumber + 1)
  }

  const changeTermValue = (e) => {
    setPageNumber(0)
    setTermValue(e.target.value)
  }

  const categoriesPopover = (
    <div className="-m-[12px] select-none">
      <span className="p-4 text-[#2C476C80] block uppercase">
        {t('presentation_mode.filter')}
      </span>
      <Menu
        className="p-0 overflow-y-auto max-h-[270px] bg-transparent"
        selectable={false}>
        {items.map((item) => (
          <Menu.Item
            className="p-0"
            key={item.name}
            onClick={() => {
              setActiveCategory(item)
              setFilterIsOpen(false)
              setPageNumber(0)
            }}>
            <div
              className={cn('flex items-center -ml-[14px] pr-6', {
                'font-semibold':
                  activeCategory?.key === item.key ||
                  (!activeCategory?.key && !item.key),
              })}>
              <div className="w-8 h-5 text-center flex items-center justify-center">
                {(activeCategory?.key === item.key ||
                    (!activeCategory?.key && !item.key)) && (
                    <Check color="green" size={17} />
                  )}
              </div>
              <div
                className="w-6 h-6 flex items-center justify-center"
                style={{ transform: 'scale(0.7)' }}>
                {item.icon}
              </div>
              <span className="ml-3 text-[#2C476C]">{item.name}</span>
            </div>
          </Menu.Item>
        ))}
      </Menu>
    </div>
  )

  return (
    <div>
      <div className="presentation-article-selector--filter mt-3">
        <Input
          variant="borderless"
          placeholder={t('presentation_mode.search_library')}
          rootClassName="search-input [&>input]:!text-[#2C476C]"
          suffix={<SearchIcon />}
          onChange={changeTermValue}
        />
        <Popover
          onOpenChange={(open) => setFilterIsOpen(open)}
          openClassName="p-0"
          placement="bottomLeft"
          content={categoriesPopover}
          open={filterIsOpen}
          trigger={['click']}
          arrow={false}
          overlayClassName="app-dropdown presentation-article-selector--dropdown p-0"
          menu={{ items: items, selectable: true }}
          dropdownRender={(menus) => (
            <div className="">{cloneElement(menus)}</div>
          )}>
          <Button
            icon={<SettingsIcon />}
            onClick={() => setFilterIsOpen(true)}
            type="text"
          />
        </Popover>
      </div>
      <div
        id="scrollableTarget"
        className="presentation-article-selector--items">
        {isFetching && !records?.length && <ListSkeleton />}
        <InfiniteScroll
          dataLength={records?.length}
          next={handleNextPage}
          // hasMore={true}
          // scrollThreshold={200}
          hasMore={filteredTotal > records.length}
          height={450}
          className="max-h-[45dvh]"
          loader={isFetching ? <ListSkeleton /> : null}>
          {records.map((item) => {
            const categorySpriteIcon = categoriesData?.data?.[0]?.items?.find(
              (el) => el.id === item.category?.[0]?.categoryId,
            )?.spriteIcon
            return (
              <div
                className={cn('presentation-article-selector--item mb-1', {
                  'bg-primary hover:bg-primary text-white presentation-article-selector--item-selected':
                    selectedArticles.find((el) => el.id === item.id),
                })}
                key={item.id}
                onClick={() => onSelect(item)}>
                {/*<img src={item.featuredImageResized} className="rounded-full bg-gray-400"*/}
                {/*     alt={item.title}/>*/}
                <div
                  className="w-8 h-8 flex items-center justify-center"
                  style={{ transform: 'scale(0.8)' }}>
                  <ConnectedSpriteImageComponent name={categorySpriteIcon} />
                </div>
                <span>{item.content?.[0]?.mainTitle}</span>
              </div>
            )
          })}
          {isLoading && <Skeleton paragraph={{ rows: 5 }} />}
          {!isFetching && !records?.length && (
            <div className="h-full min-h-[45dvh] flex flex-col justify-center items-center text-center text-2xl md:text-3xl max-w-[280px] mx-auto">
              {debouncedTermValue && (
                <span className="text-[#2C476C80]">
                  {t('presentation_mode.no_search_results_message')}
                </span>
              )}
              {!debouncedTermValue && (
                <span className="text-[#2C476C80]">
                  {t('presentation_mode.no_search_results')}
                </span>
              )}
            </div>
          )}
        </InfiniteScroll>
      </div>
    </div>
  )
}

export default PresentationArticlesList
