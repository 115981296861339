// src/components/YourComponentPath/PopupForm.js
import React, {
    memo,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import {
    Button,
    Card,
    Checkbox,
    Form,
    Input,
    message,
    Modal,
    Popover,
    Space,
    Spin,
    Typography,
    Upload,
} from 'antd'
import {
    CloseOutlined,
    DeleteOutlined,
} from '@ant-design/icons'

import MediaIcon from '../../../../assets/imgs/MicroArticleIcons/media_icon.svg';
import IframeIcon from '../../../../assets/imgs/MicroArticleIcons/iframe_icon.svg';
import CameraIcon from '../../../../assets/imgs/MicroArticleIcons/camera_icon.svg';
import URLIcon from '../../../../assets/imgs/MicroArticleIcons/URL_icon.svg';
import TimeIcon from "../../../../assets/imgs/MicroArticleIcons/time_icon.svg";
import YoutubeIcon from '../../../../assets/imgs/youtube-icon.svg'
import SearchIcon from '../../../../assets/imgs/search-icon.svg';
import PresentationsIcon from '../../../../assets/imgs/MicroArticleIcons/presentation_icon.svg';
import styles from './PopupForm.module.css'
import IconButton from '../../UI/IconButton'
import {
    b64toBlob,
    convertIframeToYoutubeUrl,
    DEFAULT_ICON_SPRITE_NAME,
    extractIframeSrc,
    extractYoutubeId,
    formatDate,
    getBase64, INITIAL_ICON_OBJECT, isIconKeyValid,
    MAX_IMAGE_SIZE,
    MAX_VIDEO_SIZE,
    uploadImageOrVideo,
    validateCoordinates,
} from '../../utils'
import DualDateInput from '../DualDateInput'
import { v4 as uuidv4 } from 'uuid'
import {
    getAllMicroArticles,
    getMicroArticleData, getMicroArticleSelectedCategoryId, getMicroArticleSpriteIconsSearch, getRecentColors,
    getSprite,
    getSpriteBase64Data,
    getSpriteData,
    getSpriteURLData,
} from '../../../../store/selectors'
import {
    setActiveMicroArticleId, setAllMicroArticles,
    setMicroArticleData,
    setMicroArticleIconsKeys,
    setMicroArticleIconsSpriteCategories, setRecentColors
} from '../../../../store/actions/microArticle'
import {connect, useSelector} from 'react-redux'
import DeleteConfirmationModal from '../DeleteConfirmationModal'
import { MicroArticleService } from '../../service'
import i18n from '../../../../locales/i18n'
import { useSearchParams } from 'react-router-dom'
import './style.css'
import ConnectedSpriteSvgComponent from '../../../UI/ConnectedSpriteSvgComponent'
import IconSelectPopup from './IconSelectPopup'
import {useTranslation} from "react-i18next";
import {isMobile} from "react-device-detect";
import WebcamModal from "./WebcamModal";
import {
    MAX_COUNT_VIDEOS_AND_IMAGES,
    MAX_COUNT_URLS_AND_IFRAMES,
    MAX_COUNT_OVERALL,
} from '../../utils';
import PresentationsSelector from "../../../presentation/ui/PresentationsSelector";
import {
    selectMicroArticlePresentation,
    selectScreenshotPresentation, setMicroArticlePresentation
} from "../../../presentation/store/presentationSlice";
import {cn} from "../../../../utils/utils";
import {useMapStateImportToSlideMutation} from "../../../presentation/store/presentationApi";
import {stringify} from "flatted";
import {generateSiteUrl} from "../../../presentation/lib/utils";

const customizeRequiredMark = (label: React.ReactNode, { required }: { required: boolean }) => (
    <>
        {label && <span style={{marginLeft: 0}}>{label}</span>}
        {required && <span style={{ color: "red", marginLeft: 4}}>*</span>}
    </>
);

const PopupForm = ({
                       isVisible,
                       onClose,
                       map,
                       coordinates,
                       handleDeleteMicroArticle,
                       onCoordinatesChange,
                       handleTitleChange,
                       initialColor = '#036147',
                       initialIconKey = DEFAULT_ICON_SPRITE_NAME,
                       onColorChange,
                       onIconChange,
                       isEdit = false,
                       isCreating,
                       onPopupClose,
                       getMicroArticleData,
                       lessonStartDate,
                       lessonEndDate,
                       onCreateSuccess,
                       featureMicroArticleID,
                       getSprite,
                       getSpriteData,
                       getSpriteURLData,
                       getSpriteBase64Data,
                       setMicroArticleIconsSpriteCategories,
                       setMicroArticleIconsKeys,
                       getMicroArticleSelectedCategoryId,
                       getMicroArticleSpriteIconsSearch,
                       setAllMicroArticles,
                       getAllMicroArticles,
                       setMicroArticlePresentation,
                       getRecentColors,
                       setRecentColors,
                   }) => {
    const [form] = Form.useForm()
    const [activeButton, setActiveButton] = useState(null)
    const [fileList, setFileList] = useState([])
    const [youtubeLink, setYoutubeLink] = useState('')
    const [iframeLink, setIframeLink] = useState('')
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [isShowCameraModal, setIsShowCameraModal] = useState(false)
    const [currentMediaUrl, setCurrentMediaUrl] = useState('')
    const [currentMediaType, setCurrentMediaType] = useState('')
    const [currentItemUid, setCurrentItemUid] = useState(null)
    const [currentDescription, setCurrentDescription] = useState('')
    const [date1, setDate1] = useState('')
    const [era1, setEra1] = useState('AD')
    const [error1, setError1] = useState('')
    const [withoutStart, setWithoutStart] = useState(false)
    const [date2, setDate2] = useState('')
    const [era2, setEra2] = useState('AD')
    const [error2, setError2] = useState('')
    const [withoutEnd, setWithoutEnd] = useState(false)
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
    const [isCancelModalVisible, setIsCancelModalVisible] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [selectedIconObject, setSelectedIconObject] = useState(INITIAL_ICON_OBJECT);
    const [selectedIcon, setSelectedIcon] = useState(initialIconKey) // Initialize with default
    const [selectedColor, setSelectedColor] = useState(initialColor)
    const [presentationsIsOpen,setPresentationsIsOpen] = useState(false)
    const [alwaysShowOnMap, setAlwaysShowOnMap] = useState(true)
    const dragItem = useRef()
    const dragOverItem = useRef()
    const previewContainerRef = useRef(null)
    const cameraInputRef = useRef(null)
    const environmentButtonRef = useRef(null)
    const popupRef = useRef(null)

    const microArticlePresentation = useSelector(selectMicroArticlePresentation)
    const [presentations, setPresentations] = useState(microArticlePresentation ? [microArticlePresentation] : [])

    const mapStyledId = useSelector((state) => state?.mapState.mapStylesId)
    const baseMapGroupId = useSelector((state) => state?.mapState.baseMapGroupId)
    const baseMapIsLightSt = useSelector(
        (state) => state?.mapState.baseMapIsLight,
    )

    const [onImportToSlide] = useMapStateImportToSlideMutation()


    const [searchParams] = useSearchParams()

    const {t} = useTranslation()

    const generateVideoThumbnail = useCallback((file) => {
        return new Promise((resolve, reject) => {
            const url = URL.createObjectURL(file)
            const video = document.createElement('video')
            video.src = url
            video.currentTime = 1 // Set to 1 second to avoid black frames
            video.onloadeddata = () => {
                const canvas = document.createElement('canvas')
                canvas.width = video.videoWidth
                canvas.height = video.videoHeight
                const ctx = canvas.getContext('2d')
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
                canvas.toBlob((blob) => {
                    const reader = new FileReader()
                    reader.readAsDataURL(blob)
                    reader.onloadend = () => {
                        resolve(reader.result)
                    }
                }, 'image/jpeg')
            }
            video.onerror = (e) => {
                console.error('Error generating video thumbnail:', e)
                resolve('') // Fallback to empty string on error
            }
        })
    }, [])

    // Function to generate video thumbnail from a video URL
    const generateVideoThumbnailFromURL = useCallback((url) => {
        return new Promise((resolve, reject) => {
            const video = document.createElement('video')
            video.src = url
            video.crossOrigin = 'Anonymous'
            video.currentTime = 1 // 1 second
            video.onloadeddata = () => {
                const canvas = document.createElement('canvas')
                canvas.width = video.videoWidth
                canvas.height = video.videoHeight
                const ctx = canvas.getContext('2d')
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
                canvas.toBlob((blob) => {
                    const reader = new FileReader()
                    reader.readAsDataURL(blob)
                    reader.onloadend = () => {
                        resolve(reader.result)
                    }
                }, 'image/jpeg')
            }
            video.onerror = (e) => {
                console.error('Error generating video thumbnail:', e)
                resolve('') // Fallback to empty string on error
            }
        })
    }, [])

    // Initialize form with existing data or reset for new entry
    useEffect(() => {
        if (isEdit && getMicroArticleData) {
            const data = getMicroArticleData

            const formValues = {
                title: data.title || '',
                description: data.description || '',
                coordinates: `${coordinates.lat.toFixed(4)}, ${coordinates.lng.toFixed(4)}`,
                iconKey: (isIconKeyValid(data.iconKey) ? data.iconKey : DEFAULT_ICON_SPRITE_NAME) || selectedIcon,
            }

            form.setFieldsValue(formValues)
            setSelectedIcon((isIconKeyValid(data.iconKey) ? data.iconKey : DEFAULT_ICON_SPRITE_NAME) || DEFAULT_ICON_SPRITE_NAME) // Set selected icon
            setDate1(formatDate(data.startYear, data.startMonth, data.startDay))
            setEra1(data.startTimeIsBC ? 'BC' : 'AD')
            setWithoutStart(data.withoutStartDate)
            setDate2(formatDate(data.endYear, data.endMonth, data.endDay))
            setEra2(data.endTimeIsBC ? 'BC' : 'AD')
            setWithoutEnd(data.withoutEndDate)
            setAlwaysShowOnMap(data.showAlwaysInMap || false)
            setSelectedColor(initialColor)

            if (data.media && Array.isArray(data.media)) {
                const processMedia = async () => {
                    const processedMediaPromises = data.media.map(
                        async (media, index) => {
                            let preview = ''
                            let isVideo = false
                            let videoUrl = undefined
                            let iframeUrl = undefined

                            if (media.type === 'Image') {
                                preview = media.path
                            } else if (media.type === 'Video') {
                                isVideo = true
                                // Assume 'path' contains the video URL
                                try {
                                    preview = await generateVideoThumbnailFromURL(media.path)
                                    if (!preview) {
                                        preview =
                                            'https://www.biblecenterchurch.com/wp-content/uploads/2018/10/video-placeholder.png'
                                    }
                                } catch (error) {
                                    console.error(
                                        'Failed to generate thumbnail for video:',
                                        error,
                                    )
                                    preview =
                                        'https://www.biblecenterchurch.com/wp-content/uploads/2018/10/video-placeholder.png'
                                }
                            } else if (media.type === 'Url') {
                                isVideo = true
                                videoUrl = media.path
                                const videoId = extractYoutubeId(media.path)
                                preview = videoId
                                    ? `https://img.youtube.com/vi/${videoId}/0.jpg`
                                    : ''
                                if (!preview) {
                                    preview =
                                        'https://www.biblecenterchurch.com/wp-content/uploads/2018/10/video-placeholder.png'
                                }
                            } else if (media.type === 'Iframe') {
                                iframeUrl = media.path
                                preview = null // Will use icon
                            }

                            return {
                                uid: uuidv4(),
                                mediaId: media.mediaId, // **Include the original mediaId**
                                name: media.caption || `Media ${index + 1}`,
                                status: 'done',
                                url: media.path,
                                path: media.path,
                                originFileObj: null,
                                preview,
                                isVideo,
                                isIframe: media.type === 'Iframe',
                                videoUrl: videoUrl,
                                iframeUrl: iframeUrl,
                                description: media.caption,
                            }
                        },
                    )

                    const processedMedia = await Promise.all(processedMediaPromises)
                    setFileList(processedMedia)
                }

                processMedia()
            }
        } else if (isCreating) {
            // Reset form for creating a new micro-article
            form.resetFields()
            setActiveMicroArticleId(null);
            setFileList([])
            setSelectedColor(initialColor)
            setSelectedIcon(DEFAULT_ICON_SPRITE_NAME)
            setDate1('')
            setEra1('AD')
            setDate2('')
            setEra2('AD')
            setWithoutStart(false)
            setWithoutEnd(false)
            setAlwaysShowOnMap(true)
        }
    }, [
        isEdit,
        isCreating,
        getMicroArticleData,
        initialColor,
        form,
        generateVideoThumbnailFromURL,
        extractYoutubeId,
    ])

    useEffect(() => {
        if (coordinates) {
            form.setFieldsValue({
                coordinates: `${coordinates.lat.toFixed(4)}, ${coordinates.lng.toFixed(4)}`,
            })
        }
    }, [coordinates])

    useEffect(() => {
        setSelectedColor(initialColor)
    }, [initialColor])

    useEffect(() => {
        setSelectedIcon(initialIconKey)
    }, [initialIconKey]);

    useEffect(() => {
        setActiveButton(isShowCameraModal ? 'camera' : null)
    }, [isShowCameraModal])

    useEffect(() => {
        switch (activeButton) {
            case 'addToDisplay':
                setPresentationsIsOpen(true)
            break;
            default:
        }
    },[activeButton])

    useEffect(() => {
       return () => {
           setMicroArticlePresentation(null)
       }
    }, []);

    // Handle icon selection
    const handleIconSelect = useCallback(
        (iconName) => {
            setSelectedIcon(iconName)
            onIconChange(iconName)
            form.setFieldsValue({ iconKey: iconName }) // Set in form
        },
        [form, onIconChange],
    )

    // Toggle active button (e.g., environment, link, iframe)
    const handleToggle = useCallback((key) => {
        setActiveButton((prevActiveButton) =>
            prevActiveButton === key ? null : key,
        )
    }, [])

    // Handle title blur event
    const handleTitleBlur = useCallback(() => {
        const value = form.getFieldValue('title')
        handleTitleChange(value)
    }, [form, handleTitleChange])

    // Helper to count how many local images & local videos
    const getVideosAndImagesCount = useCallback(
        (list) => list.filter(
            (item) => !item.isIframe && !(item.isVideo && item.videoUrl)
        ).length,
        []
    )

    // Helper to count how many URLs (YouTube) & iframes
    const getUrlsAndIframesCount = useCallback(
        (list) => list.filter(
            (item) => (item.isVideo && item.videoUrl) || item.isIframe
        ).length,
        []
    )

    const changePresentationsHandler = useCallback((data) => {
        setPresentations([...new Map(data.map(item => [item.id, item])).values()])
        setPresentationsIsOpen(false)
        setActiveButton(null)
    },[]);

    // Handle changes in the upload component
    const handleUploadChange = useCallback(
        async ({ file, fileList: newFileList }) => {
            // --- NEW CHECKS for MAX_COUNTS
            // Let's figure out how many items are *newly* being added
            setActiveButton(null);
            const newlyAddedFiles = newFileList.filter(
                (it) => it.originFileObj && !fileList.some(x => x.uid === it.uid)
            )

            // Check images + local videos
            const currentImagesVideos = getVideosAndImagesCount(fileList)
            let newImagesVideosCount = 0
            newlyAddedFiles.forEach((f) => {
                const isImage = f.type && f.type.startsWith('image/')
                const isLocalVideo = f.type && f.type.startsWith('video/')
                if (isImage || isLocalVideo) {
                    newImagesVideosCount += 1
                }
            })
            // If the sum > MAX_COUNT_VIDEOS_AND_IMAGES => reject
            if (
                currentImagesVideos + newImagesVideosCount >
                MAX_COUNT_VIDEOS_AND_IMAGES
            ) {
                message.warning(
                    t('microArticle.messages.warning.maxVideosAndImages', {MAX_COUNT_VIDEOS_AND_IMAGES: MAX_COUNT_VIDEOS_AND_IMAGES})
                )

                return
            }
            // Check overall
            const currentTotal = fileList.length
            if (currentTotal + newlyAddedFiles.length > MAX_COUNT_OVERALL) {
                message.warning(
                    t('microArticle.messages.warning.maxOverallItems', {MAX_COUNT_OVERALL: MAX_COUNT_OVERALL})
                )
                return
            }
            // --- END NEW CHECKS

            // (Optional existing limit for 8 items)
            if (newFileList.length > MAX_COUNT_OVERALL) {
                message.warning(t('microArticle.messages.warning.maxOverallItems', {MAX_COUNT_OVERALL: MAX_COUNT_OVERALL}))
                return
            }

            // Separate new files (with originFileObj) from existing files
            const existingFiles = fileList.filter(
                (fileItem) => !fileItem.originFileObj
            )
            const justUploaded = newFileList.filter(
                (fileItem) => fileItem.originFileObj
            )

            // Process new uploaded files
            const processedUploadedFiles = await Promise.all(
                justUploaded.map(async (fileItem) => {
                    if (
                        fileItem.originFileObj &&
                        !fileItem.preview &&
                        !fileItem.isIframe
                    ) {
                        try {
                            if (
                                fileItem?.type &&
                                fileItem?.type?.startsWith &&
                                fileItem.type.startsWith('image/')
                            ) {
                                fileItem.preview = await getBase64(fileItem.originFileObj)
                            } else if (
                                fileItem?.type &&
                                fileItem?.type?.startsWith &&
                                fileItem.type.startsWith('video/')
                            ) {
                                fileItem.preview = await generateVideoThumbnail(
                                    fileItem.originFileObj,
                                )
                            }
                        } catch (error) {
                            console.error('Error generating preview:', error)
                        }
                    }
                    return {
                        ...fileItem,
                        status: 'ready',
                        path: '',
                        isVideo:
                            fileItem?.type &&
                            fileItem?.type?.startsWith &&
                            fileItem?.type?.startsWith('video/'),
                        isIframe: false,
                        description: fileItem.description || '',
                        originFileObj: fileItem.originFileObj || null,
                    }
                }),
            )

            // Merge existing files with newly uploaded files
            setFileList([...existingFiles, ...processedUploadedFiles])
        },
        [fileList, generateVideoThumbnail, getVideosAndImagesCount],
    )

    // Handle removing a file from the list
    const handleRemove = useCallback((file) => {
        setFileList((prevList) => prevList.filter((item) => item.uid !== file.uid))
    }, [])

    // Upload component properties
    const uploadProps = useMemo(
        () => ({
            multiple: true,
            customRequest: () => {},
            fileList,
            onChange: handleUploadChange,
            onRemove: handleRemove,
            beforeUpload: (file) => {
                const isImage = file.type.startsWith('image/')
                const isVideo = file.type.startsWith('video/')

                if (!isImage && !isVideo) {
                    message.error(t('microArticle.messages.error.invalidFileType'))
                    return Upload.LIST_IGNORE
                }

                const imageTypes = ['image/png', 'image/jpeg', 'image/jpg'];
                const videoTypes = ['video/mp4'];

                // Validate specific image types
                if (isImage && !imageTypes.includes(file.type)) {
                    message.error(t('microArticle.messages.error.invalidImageFormat'));
                    return Upload.LIST_IGNORE;
                }

                // Validate specific video types
                if (isVideo && !videoTypes.includes(file.type)) {
                    message.error(t('microArticle.messages.error.invalidVideoFormat'));
                    return Upload.LIST_IGNORE;
                }

                if (isImage && file.size > MAX_IMAGE_SIZE) {
                    message.error(
                        t('microArticle.messages.error.imageTooLarge', { MAX_IMAGE_SIZE: MAX_IMAGE_SIZE  / 1024 / 1024 }),
                    )
                    return Upload.LIST_IGNORE
                }

                if (isVideo && file.size > MAX_VIDEO_SIZE) {
                    message.error(
                        t('microArticle.messages.error.videoTooLarge', { MAX_VIDEO_SIZE: MAX_VIDEO_SIZE  / 1024 / 1024 })
                    )
                    return Upload.LIST_IGNORE
                }
                return isImage || isVideo || Upload.LIST_IGNORE
            },
            showUploadList: false,
        }),
        [fileList, handleUploadChange, handleRemove],
    )

    // Drag and drop handlers for reordering media items
    const handleDragStart = useCallback((e, index) => {
        dragItem.current = index
        e.dataTransfer.effectAllowed = 'move'
    }, [])

    const handleDragEnter = useCallback(
        (e, index) => {
            dragOverItem.current = index
            if (dragItem.current === dragOverItem.current) return

            const newFileList = [...fileList]
            const draggedItem = newFileList[dragItem.current]
            newFileList.splice(dragItem.current, 1)
            newFileList.splice(dragOverItem.current, 0, draggedItem)

            dragItem.current = dragOverItem.current
            dragOverItem.current = null
            setFileList(newFileList)
        },
        [fileList],
    )

    const handleDragOver = useCallback((e) => {
        e.preventDefault()
    }, [])

    const handleDragEnd = useCallback(() => {
        dragItem.current = null
        dragOverItem.current = null
    }, [])

    // Handle scroll wheel for the preview container
    const handleWheel = useCallback((e) => {
        if (previewContainerRef.current) {
            previewContainerRef.current.scrollLeft += e.deltaY
        }
    }, [])

    // Handle capturing image from the camera
    const handleCameraCapture = useCallback(
        async (e) => {
            const file = e.target.files[0]
            if (file) {

                // --- NEW CHECKS
                const currentImagesVideos = getVideosAndImagesCount(fileList)
                const currentTotal = fileList.length
                if (currentImagesVideos + 1 > MAX_COUNT_VIDEOS_AND_IMAGES) {
                    message.warning(
                        t('microArticle.messages.warning.maxVideosAndImages', {MAX_COUNT_VIDEOS_AND_IMAGES: MAX_COUNT_VIDEOS_AND_IMAGES})
                    )
                    return
                }
                if (currentTotal + 1 > MAX_COUNT_OVERALL) {
                    message.warning(
                        t('microArticle.messages.warning.maxOverallItems', {MAX_COUNT_OVERALL: MAX_COUNT_OVERALL})
                    )
                    return
                }
                // --- END NEW CHECKS

                try {
                    const preview = await getBase64(file)

                    const newFile = {
                        uid: uuidv4(),
                        name: file.name,
                        status: 'ready',
                        path: '',
                        originFileObj: file,
                        description: '',
                        isVideo: false,
                        isIframe: false,
                        preview, // Set the generated preview
                    }
                    setFileList((prev) => [...prev, newFile])
                } catch (error) {
                    console.error('Camera capture failed:', error)
                    message.error(t('microArticle.messages.error.captureFailed'))
                }
            }
            e.target.value = null
        },
        [fileList.length, getVideosAndImagesCount],
    )

    const handleDesktopCapture = useCallback(async (base64) => {
        if(!base64) return

        // --- NEW CHECKS
        const currentImagesVideos = getVideosAndImagesCount(fileList)
        const currentTotal = fileList.length
        if (currentImagesVideos + 1 > MAX_COUNT_VIDEOS_AND_IMAGES) {
            message.warning(
                t('microArticle.messages.warning.maxVideosAndImages', {MAX_COUNT_VIDEOS_AND_IMAGES: MAX_COUNT_VIDEOS_AND_IMAGES})
            )
            return
        }
        if (currentTotal + 1 > MAX_COUNT_OVERALL) {
            message.warning(
                t('microArticle.messages.warning.maxOverallItems', {MAX_COUNT_OVERALL: MAX_COUNT_OVERALL})
            )
            return
        }
        // --- END NEW CHECKS

        try {
            const file = await b64toBlob(base64)

            const newFile = {
                uid: uuidv4(),
                name: file.name,
                status: 'ready',
                path: '',
                originFileObj: file,
                description: '',
                isVideo: false,
                isIframe: false,
                preview: base64,
            }

            setFileList((prev) => [...prev, newFile])
        } catch (error) {
            console.error('Camera capture failed:', error)
            message.error(t('microArticle.messages.error.captureFailed'))
        }
    }, [fileList.length, getVideosAndImagesCount])

    // Validate iframe URLs
    const isValidIframeUrl = useCallback((url) => {
        try {
            const parsedUrl = new URL(url)
            return ['http:', 'https:'].includes(parsedUrl.protocol)
        } catch (e) {
            return false
        }
    }, [])

    // Handle adding YouTube links
    const handleAddYoutubeLink = useCallback(
        (inputLink) => {
            const link = inputLink.trim()
            if (!link) {
                message.error(t('microArticle.messages.error.emptyYoutubeLink'))
                return
            }

            // 1) Strictly require only YouTube link
            if (
                !link.includes('youtube.com') &&
                !link.includes('youtu.be')
            ) {
                message.error(t('microArticle.messages.error.invalidYoutubeLink'))
                return
            }

            // 2) Also disallow any raw iFrame code in the YouTube field
            if (link.includes('<iframe')) {
                message.error(t('microArticle.messages.error.invalidIframeInput'))
                return
            }

            const videoId = extractYoutubeId(link)
            if (!videoId) {
                message.error(t('microArticle.messages.error.invalidYoutubeLink'))
                return
            }

            // --- NEW CHECKS
            // Count how many iframes + youtube URLs
            const currentUrlsIframes = getUrlsAndIframesCount(fileList)
            // Count total
            const currentTotal = fileList.length
            // We are adding 1 new item
            if (currentUrlsIframes + 1 > MAX_COUNT_URLS_AND_IFRAMES) {
                message.warning(
                    t('microArticle.messages.warning.maxUrlsAndIframes', {MAX_COUNT_URLS_AND_IFRAMES: MAX_COUNT_URLS_AND_IFRAMES})
                )
                return
            }
            if (currentTotal + 1 > MAX_COUNT_OVERALL) {
                message.warning(
                    t('microArticle.messages.warning.maxOverallItems', {MAX_COUNT_OVERALL: MAX_COUNT_OVERALL})
                )
                return
            }
            // --- END NEW CHECKS

            if (fileList.some((item) => item.mediaId === videoId)) {
                message.warning(t('microArticle.messages.warning.duplicateYoutube'))
                return
            }

            const embedUrl = `https://www.youtube.com/embed/${videoId}`
            const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`

            const newItem = {
                uid: uuidv4(),
                mediaId: uuidv4(),
                name: `YouTube Video: ${videoId}`,
                status: 'ready',
                url: thumbnailUrl,
                path: '',
                originFileObj: null,
                preview: thumbnailUrl,
                isVideo: true,
                videoUrl: embedUrl,
                videoId,
                description: '',
            }

            setFileList((prev) => [...prev, newItem])
            setYoutubeLink('')
            setIframeLink('')
            setActiveButton(null)
            message.success(t('microArticle.messages.success.youtubeAdded'))
        },
        [extractYoutubeId, fileList, getUrlsAndIframesCount],
    )

    // Handle adding iframe links
    const handleAddIframeLink = useCallback(
        (inputLink) => {
            // add check for is iframe from youtube
            const youtubeUrl = convertIframeToYoutubeUrl(inputLink);
            if (youtubeUrl) {
                handleAddYoutubeLink(youtubeUrl);
                return;
            }

            let link = inputLink.trim()
            if (!link) {
                message.error(t('microArticle.messages.error.emptyIframeLink'))
                return
            }

            // strictly require an <iframe> tag
            if (!link.includes('<iframe')) {
                message.error(t('microArticle.messages.error.invalidIframeInput1'))
                return
            }

            link = extractIframeSrc(link)

            if (!isValidIframeUrl(link)) {
                message.error(t('microArticle.messages.error.invalidIframe'))
                return
            }

            if (fileList.some((item) => item.mediaId === link)) {
                message.warning(t('microArticle.messages.warning.duplicateIframe'))
                return
            }

            // TODO extractYoutubeId

            // --- NEW CHECKS
            // Count how many iframes + youtube URLs
            const currentUrlsIframes = getUrlsAndIframesCount(fileList)
            // Count total
            const currentTotal = fileList.length
            // We are adding 1 new item
            if (currentUrlsIframes + 1 > MAX_COUNT_URLS_AND_IFRAMES) {
                message.warning(
                    t('microArticle.messages.warning.maxUrlsAndIframes', {MAX_COUNT_URLS_AND_IFRAMES: MAX_COUNT_URLS_AND_IFRAMES})
                )
                return
            }
            if (currentTotal + 1 > MAX_COUNT_OVERALL) {
                message.warning(
                    t('microArticle.messages.warning.maxOverallItems', {MAX_COUNT_OVERALL: MAX_COUNT_OVERALL})
                )
                return
            }
            // --- END NEW CHECKS

            const newItem = {
                uid: uuidv4(),
                mediaId: uuidv4(),
                name: `iFrame: ${link}`,
                status: 'ready',
                url: '',
                path: '',
                originFileObj: null,
                preview: null,
                isIframe: true,
                iframeUrl: link,
                description: '',
            }

            setFileList((prev) => [...prev, newItem])
            setIframeLink('')
            setActiveButton(null)
            message.success(t('microArticle.messages.success.iframeAdded'))
        },
        [extractIframeSrc, isValidIframeUrl, fileList, getUrlsAndIframesCount],
    )
    // Open modal to preview media and edit description
    const openModal = useCallback((file) => {
        if (file.isVideo) {
            if (file.videoUrl) {
                // It's a YouTube video
                setCurrentMediaType('video')
                setCurrentMediaUrl(file.videoUrl)
            } else {
                // It's an uploaded video file
                setCurrentMediaType('uploadedVideo')
                if (file.path) {
                    setCurrentMediaUrl(file.path)
                } else if (file.originFileObj) {
                    const videoUrl = URL.createObjectURL(file.originFileObj)
                    setCurrentMediaUrl(videoUrl)
                }
            }
        } else if (file.isIframe) {
            setCurrentMediaType('iframe')
            setCurrentMediaUrl(file.iframeUrl)
        } else {
            setCurrentMediaType('image')
            setCurrentMediaUrl(file.preview)
        }
        setCurrentItemUid(file.uid)
        setCurrentDescription(file.description || '')
        setIsModalVisible(true)
    }, [])

    // Close the media preview modal
    const closeModal = useCallback(() => {
        setActiveButton(null);
        setIsModalVisible(false)
        setCurrentMediaUrl('')
        setCurrentMediaType('')
        setCurrentItemUid(null)
    }, [])

    // Handle description change in modal
    const handleDescriptionChange = useCallback((e) => {
        setCurrentDescription(e.target.value)
    }, [])

    // Save description from modal
    const handleSaveDescription = useCallback(() => {
        setFileList((prevList) =>
            prevList.map((item) =>
                item.uid === currentItemUid
                    ? { ...item, description: currentDescription }
                    : item,
            ),
        )
        message.success(t('microArticle.messages.success.descriptionSaved'))
        setIsModalVisible(false)
    }, [currentDescription, currentItemUid])

    // Delete a media item
    const handleDeleteItem = useCallback(() => {
        setFileList((prevList) =>
            prevList.filter((item) => item.uid !== currentItemUid),
        )
        message.success(t('microArticle.messages.success.itemDeleted'))
        setIsModalVisible(false)
    }, [currentItemUid])

    const getMicroArticleIconsDataResponse = async (catIds, searchParams) => {
        try {
            const microArticleIconsResponse = await MicroArticleService.getMicroArticleSpriteIconsByCategoryId(catIds && catIds.length ? catIds : null, searchParams ? searchParams : null);
            return microArticleIconsResponse;
        } catch (error) {
            console.error('Error fetching micro article icons data:', error);
        }
    };

    const getMicroArticleCategoriesDataResponse = async () => {
        try {
            const microArticleCategoriesResponse = await MicroArticleService.getMicroArticleSpriteCategories();
            return microArticleCategoriesResponse;
        } catch (error) {
            console.error('Error fetching micro article categories data:', error);
        }
    };

    useEffect(() => {
        getMicroArticleCategoriesDataResponse().then((res) => {
            setMicroArticleIconsSpriteCategories(res.data.items)
        }).catch(e => {
            console.error('e', e);
        });
    }, []);

    useEffect(() => {
        getMicroArticleIconsDataResponse(getMicroArticleSelectedCategoryId ? [getMicroArticleSelectedCategoryId] : null, getMicroArticleSpriteIconsSearch ? getMicroArticleSpriteIconsSearch : null).then((res) => {
            setMicroArticleIconsKeys(res.data.items)
        }).catch(e => {
            console.error('e', e);
        })
    }, [getMicroArticleSelectedCategoryId, getMicroArticleSpriteIconsSearch]);

    // Categories for environment popover
    const environmentCategories = useMemo(
        () => [
            'Climate Change',
            'Renewable Energy',
            'Pollution',
            'Conservation',
            'Sustainability',
        ],
        [],
    )

    // Handle color changes
    const handleColorChangeLocal = useCallback(
        (color) => {
            if(color === selectedColor) return
            setSelectedColor(color)
            onColorChange(color)
            const filtered = getRecentColors.filter((c) => c !== color)
            const newRecent = [color, ...filtered].slice(0, 3)
            setRecentColors(newRecent)
        },
        [onColorChange, selectedColor],
    )

    // Handle form submission
    const handleSubmit = useCallback(async () => {
        // Checking is form valid, on cases when the user manually changing the disabled state of submit button, from dev tools.
        // const hasErrors = form
        //     .getFieldsError()
        //     .some(({ errors }) => errors.length)
        //
        // if (
        //     isSubmitting ||
        //     hasErrors ||
        //     !form.isFieldsTouched() ||
        //     error1 ||
        //     error2 ||
        //     (!alwaysShowOnMap && !date1) ||
        //     (!date1 && date2)
        // ) {
        //     message.warning('Please, do not try broke the app :)')
        //     return;
        // };

        // Parse dates
        const parseDateString = (dateString) => {
            if (!dateString) return { year: null, month: null, day: null }
            const parts = dateString.split('/')
            return {
                year: parts[0] ? parseInt(parts[0], 10) : null,
                month: parts[1] ? parseInt(parts[1], 10) : null,
                day: parts[2] ? parseInt(parts[2], 10) : null,
            }
        }

        let startDate = parseDateString(date1)
        let endDate = parseDateString(date2)
        try {
            if (isSubmitting) return
            // Validate form fields
            const values = await form.validateFields()

            // Parse and validate coordinates
            const coords = values.coordinates.split(',').map((c) => c.trim())
            if (coords.length !== 2) {
                message.error(t('microArticle.messages.error.invalidCoordinates1'))
                return
            }

            const latitude = parseFloat(coords[0])
            const longitude = parseFloat(coords[1])
            if (
                isNaN(latitude) ||
                isNaN(longitude) ||
                latitude < -90 ||
                latitude > 90 ||
                longitude < -180 ||
                longitude > 180
            ) {
                message.error(t('microArticle.messages.error.invalidCoordinates'))
                return
            }
            let era1Copy = era1
            let era2Copy = era2

            // **Defaulting Start Date**
            if (startDate.year) {
                if (!startDate.month) {
                    startDate.month = 1
                }
                if (!startDate.day) {
                    startDate.day = 1
                }
            }

            // Initialize time fields
            let startHour = null
            let startMinute = null
            let startSecond = null
            let endHour = null
            let endMinute = null
            let endSecond = null

            // **Check if Start and End Dates are Identical**
            const isSameDate =
                startDate.year &&
                endDate.year &&
                startDate.year === endDate.year &&
                startDate.month === endDate.month &&
                startDate.day === endDate.day

            if (isSameDate) {
                startHour = 1
                startMinute = 1
                startSecond = 1

                endHour = 23
                endMinute = 59
                endSecond = 59
            }

            // **Defaulting End Date**
            if (endDate.year) {
                if (!endDate.month) {
                    endDate.month = 12
                }
                if (!endDate.day) {
                    endDate.day = new Date(endDate.year, endDate.month, 0).getDate()
                }
            }

            // ADDED: Check if user set "only end date" => not allowed
            if ((!startDate.year && endDate && endDate.year) || (!startDate.year && withoutEnd && !alwaysShowOnMap)) {
                message.error(t('microArticle.messages.error.startDateRequiredWhenEndDateIsSet'))
                return
            }

            // ADDED: Specific Range Calculation if user provided only a start date (and did NOT check "withoutEnd")
            if (!withoutEnd && !endDate.year && startDate.year && !alwaysShowOnMap) {
                // Count how many parts in the date1 string:
                const startDateParts = date1.split('/').filter(Boolean).length;
                const today = new Date();
                const currentYear = today.getFullYear();
                const currentMonth = today.getMonth() + 1; // JS months are 0-indexed
                const currentDay = today.getDate();

                // If user typed only year (1 part)
                if (startDateParts === 1) {
                    if (startDate.year === currentYear) {
                        // If start year is current year, set end date to today
                        endDate.year = currentYear;
                        endDate.month = currentMonth;
                        endDate.day = currentDay;
                    } else {
                        // Otherwise default to the last month and day of that year
                        endDate.year = startDate.year;
                        endDate.month = 12;
                        endDate.day = 31;
                    }
                }
                // If user typed year + month (2 parts)
                else if (startDateParts === 2) {
                    if (startDate.year === currentYear) {
                        // Check: if provided start month is after the current month, reject the input.
                        if (startDate.month > currentMonth) {
                            message.error(
                                t('microArticle.messages.error.startDateCannotBeInFuture')
                            );
                            return;
                        }
                        // If the start month is the current month, then default end date as today's date.
                        if (startDate.month === currentMonth) {
                            endDate.year = currentYear;
                            endDate.month = currentMonth;
                            endDate.day = currentDay;
                        } else {
                            // Otherwise, default end date as the last day of that start month.
                            endDate.year = startDate.year;
                            endDate.month = startDate.month;
                            endDate.day = new Date(startDate.year, startDate.month, 0).getDate();
                        }
                    } else {
                        // For a year in the past or future (not the current year), default normally.
                        endDate.year = startDate.year;
                        endDate.month = startDate.month;
                        endDate.day = new Date(startDate.year, startDate.month, 0).getDate();
                    }
                }
                // If user typed year + month + day (3 parts)
                else if (startDateParts === 3) {
                    if (startDate.year === currentYear) {
                        // Check if the provided month is after the current month
                        if (startDate.month > currentMonth) {
                            message.error(
                                t('microArticle.messages.error.startDateCannotBeInFuture')
                            );
                            return;
                        }
                        // If same month, check that the day is not in the future.
                        if (startDate.month === currentMonth && startDate.day > currentDay) {
                            message.error(
                                t('microArticle.messages.error.startDateCannotBeInFuture')
                            );
                            return;
                        }
                        // For current-year dates that are today or in the past, use the provided date.
                        endDate.year = startDate.year;
                        endDate.month = startDate.month;
                        endDate.day = startDate.day;
                    } else {
                        // For non-current years, default normally.
                        endDate.year = startDate.year;
                        endDate.month = startDate.month;
                        endDate.day = startDate.day;
                    }
                }
            }

            // **Defaulting End Date** (when the user manually provided an end date)
            if (endDate && endDate.year) {
                const today = new Date();
                const currentYear = today.getFullYear();
                const currentMonth = today.getMonth() + 1;
                const currentDay = today.getDate();

                if (endDate.year === currentYear) {
                    // If month is not provided, assume the current month.
                    if (!endDate.month) {
                        endDate.month = currentMonth;
                    } else if (endDate.month > currentMonth) {
                        // Option 1: Adjust automatically to today (or you could choose to reject the value)
                        endDate.month = currentMonth;
                        endDate.day = currentDay;
                        // Alternatively, you might do:
                        // message.error(t('microArticle.messages.error.endDateCannotBeInFuture'));
                        // return;
                    }

                    // If day is not provided, then set it to the current day if in the current month;
                    // otherwise default to the last day of the specified month.
                    if (!endDate.day) {
                        if (endDate.month === currentMonth) {
                            endDate.day = currentDay;
                        } else {
                            endDate.day = new Date(endDate.year, endDate.month, 0).getDate();
                        }
                    } else if (endDate.month === currentMonth && endDate.day > currentDay) {
                        // If the day is in the future (and in the current month), adjust it.
                        endDate.day = currentDay;
                        // Or, if you prefer, you could reject the input:
                        // message.error(t('microArticle.messages.error.endDateCannotBeInFuture'));
                        // return;
                    }
                } else {
                    // For non-current years, use default values if month/day are not provided.
                    if (!endDate.month) {
                        endDate.month = 12;
                    }
                    if (!endDate.day) {
                        endDate.day = new Date(endDate.year, endDate.month, 0).getDate();
                    }
                }
            }

            // If the user chooses "Always show on map"
            if (alwaysShowOnMap) {
                startDate = { year: null, month: null, day: null }
                era1Copy = 'BC'
                endDate = null
            } else {
                if (withoutStart) {
                    startDate = { year: null, month: null, day: null }
                    era1Copy = 'BC'
                }
                if (withoutEnd) {
                    endDate = null
                }
            }

            // Time Validation: Check overlap with lesson date if needed
            if (lessonStartDate && lessonEndDate && !alwaysShowOnMap) {
                const constructDate = (date, era) => {
                    if (!date.year) return null
                    let month = date.month ? date.month - 1 : 0
                    let day = date.day || 1
                    let year = era === 'BC' ? -(date.year - 1) : date.year
                    return new Date(year, month, day)
                }

                const lessonStart = constructDate(lessonStartDate, lessonStartDate.era)
                const lessonEnd = constructDate(lessonEndDate, lessonEndDate.era)

                const articleStartTime = new Date(
                    startDate.year || 0,
                    (startDate.month ? startDate.month - 1 : 0),
                    startDate.day || 1,
                    startHour || 0,
                    startMinute || 0,
                    startSecond || 0,
                ).getTime()

                const articleEndTime = endDate
                    ? new Date(
                        endDate.year || 0,
                        (endDate.month ? endDate.month - 1 : 0),
                        endDate.day || 1,
                        endHour || 0,
                        endMinute || 0,
                        endSecond || 0,
                    ).getTime()
                    : Date.now()

                const isOverlap =
                    articleStartTime <= lessonEnd.getTime() &&
                    articleEndTime >= lessonStart.getTime()

                if (!isOverlap) {
                    message.error(
                        t('microArticle.messages.error.timelineError'),
                    )
                    return
                }
            }

            const topicID = searchParams.get('topicID')
            const lessonID = searchParams.get('lessonID')

            setIsSubmitting(true)

            // Construct payload
            const payload = {
                languageId: i18n.language === 'hy' ? 1 : 2,
                title: values.title,
                description: values.description,
                iconKey: selectedIcon,
                webIconKey: selectedIconObject.key,
                iconBackgroundColor: selectedColor,
                zoomLevel: 0,
                longitude: longitude,
                latitude: latitude,
                media: isEdit ? (getMicroArticleData && [...getMicroArticleData.media]) : [],
                topicIds: topicID ? [+topicID] : [],
                lessonIds: lessonID ? [+lessonID] : [],
                startYear: startDate.year,
                startMonth: startDate.month,
                startDay: startDate.day,
                startHour: startHour,
                startMinute: startMinute,
                startSecond: startSecond,
                startTimeIsBC: era1Copy === 'BC' || withoutStart || alwaysShowOnMap,
                endYear: endDate ? endDate.year : null,
                endMonth: endDate ? endDate.month : null,
                endDay: endDate ? endDate.day : null,
                endHour: endHour,
                endMinute: endMinute,
                endSecond: endSecond,
                endTimeIsBC: endDate ? era2Copy === 'BC' : false,
                backgroundColor: selectedColor,
                showAlwaysInMap: alwaysShowOnMap,
            }

            // Submit the form data to create or update the micro-article
            try {
                let response
                let microArticleId

                if (isEdit) {
                    response = await MicroArticleService.updateMicroArticleByID(
                        featureMicroArticleID,
                        payload,
                    )
                    const updatedMicroArticleResponse = await MicroArticleService.getMicroArticleByID(featureMicroArticleID);
                    const newMicroArticles = [...getAllMicroArticles];
                    const index = newMicroArticles.findIndex((ma) => ma.id === featureMicroArticleID);
                    const newUpdatedMicroArticle = updatedMicroArticleResponse.data.data[0]
                    if (index !== -1) {
                        newMicroArticles[index] = {...newUpdatedMicroArticle, show: newMicroArticles[index].show};
                    }
                    setAllMicroArticles([...newMicroArticles]);
                    if (response.status === 200 || response.status === 204) {
                        microArticleId = featureMicroArticleID
                    } else {
                        message.error(t('microArticle.messages.error.microArticleUpdateFailed'))
                        setIsSubmitting(false)
                        return
                    }
                } else {
                    response = await MicroArticleService.createMicroArticle(payload);
                    const createdMicroArticleResponse = await MicroArticleService.getMicroArticleByID(response.data.data[0]);
                    const newCreatedMicroArticle = createdMicroArticleResponse.data.data[0]
                    // console.log('createdMicroArticle', createdMicroArticle)
                    setAllMicroArticles([...getAllMicroArticles, {...newCreatedMicroArticle, show: true}]);
                    if (response.status === 200 || response.status === 201) {
                        microArticleId = response.data.data[0]
                    } else {
                        message.error(t('microArticle.messages.error.microArticleCreateFailed'))
                        setIsSubmitting(false)
                        return
                    }
                }

                // Now, handle media uploads and associations
                if (fileList.length > 0) {
                    // Separate existing media from new media
                    const existingMedia = fileList.filter(
                        (file) =>
                            file.mediaId &&
                            !file.originFileObj &&
                            !file.isIframe &&
                            !(file.isVideo && file.videoUrl),
                    )
                    const newMedia = fileList.filter((file) => file.originFileObj)

                    // Upload new media
                    const uploadMediaPromises = newMedia.map(async (file) => {
                        try {
                            const blob = file.originFileObj
                            const alt = file.description
                            const title = file.title || file.name

                            const uploadResponse = await uploadImageOrVideo(
                                blob,
                                alt,
                                title,
                                'PublicRead',
                            )
                            return {
                                mediaId: uploadResponse.id,
                                path: uploadResponse.mediaInfo[file.isVideo ? 'processed' : 'optimized'],
                                type: file.isVideo ? 'Video' : 'Image',
                                caption: alt,
                                order: fileList.indexOf(file),
                            }
                        } catch (error) {
                            console.error(`Failed to upload ${file.name}:`, error)
                            message.error(t('microArticle.messages.error.uploadFailed'), {name: file.name})
                            return null
                        }
                    })

                    const uploadedMediaResponses = await Promise.all(uploadMediaPromises)
                    const successfulUploads = uploadedMediaResponses.filter(
                        (response) => response !== null,
                    )

                    // URL-Based Media (YouTube, iFrame)
                    const urlBasedMedia = fileList
                        .filter((file) => file.isIframe || (file.isVideo && file.videoUrl))
                        .map((file) => ({
                            mediaId: file.mediaId,
                            type: file.isIframe ? 'Iframe' : 'Url',
                            path: file.isIframe ? file.iframeUrl : file.videoUrl,
                            caption: file.description,
                            order: fileList.indexOf(file),
                        }))

                    // Combine everything
                    const finalMediaArray = [
                        ...existingMedia.map((media) => ({
                            mediaId: media.mediaId,
                            type: media.isIframe
                                ? 'Iframe'
                                : media.isVideo
                                    ? 'Video'
                                    : 'Image',
                            path: media.path,
                            caption: media.description,
                            order: fileList.indexOf(media),
                        })),
                        ...successfulUploads,
                        ...urlBasedMedia,
                    ]

                    const sortedFinalMediaArray = [...finalMediaArray].sort(
                        (a, b) => a.order - b.order,
                    )

                    try {
                        const mediaUpdateResponse =
                            await MicroArticleService.updateMicroArticleMedia(
                                microArticleId,
                                sortedFinalMediaArray,
                            )
                        if (
                            mediaUpdateResponse.status === 200 ||
                            mediaUpdateResponse.status === 204
                        ) {
                            // message.success(t('microArticle.messages.success.mediaUploaded'))
                        } else {
                            message.error(
                                t('microArticle.messages.error.mediaAssociationFailed'),
                            )
                        }
                    } catch (error) {
                        console.error('Failed to update micro-article media:', error)
                        message.error(t('microArticle.messages.error.mediaAssociationFailed1'))
                    }
                } else {
                    await MicroArticleService.updateMicroArticleMedia(microArticleId, [])
                }
                if (isEdit) {
                    message.success(t('microArticle.messages.success.microArticleUpdated'))
                } else {
                    message.success(t('microArticle.messages.success.microArticleCreated'))
                }

                //TODO move to separate function

                console.log("presentations", presentations);
                presentations.forEach(presentation => {
                    const presentationId = presentation.id
                    // if (presentationId === microArticlePresentation?.id) {
                    //     return;
                    // }
                    onImportToSlide({
                        presentationId,
                        microArticlesData: [{
                            id: microArticleId,
                            languageId: payload.languageId,
                            title: payload.title,
                            topicId: topicID,
                            lessonId: lessonID,
                            state: stringify({
                                // ...state,
                                getMapStyledId: mapStyledId,
                                baseMapGroupIdST: baseMapGroupId,
                                baseMapIsLight: baseMapIsLightSt,
                                microArticleData: payload,
                                url: generateSiteUrl({
                                    presentationId,
                                    microArticleMode: microArticleId,
                                    topicId: topicID,
                                    lessonId: lessonID,
                                }),
                            }),
                        }],
                    })
                })
                // Reset form and state
                onClose(true)
                onCreateSuccess(
                    microArticleId,
                    startDate?.year,
                    endDate?.year,
                    era1Copy === 'BC' || withoutStart || alwaysShowOnMap,
                    endDate ? era2Copy === 'BC' : false
                )
            } catch (error) {
                if (isEdit) {
                    message.error(t('microArticle.messages.error.microArticleUpdateFailed'))
                } else {
                    message.error(t('microArticle.messages.error.microArticleCreateFailed'))
                }
                console.error(error)
            } finally {
                setIsSubmitting(false)
            }
        } catch (e) {
            console.error('e', e)
        }
    }, [
        form,
        fileList,
        selectedColor,
        date1,
        date2,
        era1,
        era2,
        withoutStart,
        withoutEnd,
        alwaysShowOnMap,
        lessonStartDate,
        lessonEndDate,
        onClose,
        isSubmitting,
        onCreateSuccess,
        searchParams,
        isEdit,
        featureMicroArticleID,
        selectedIcon,
        getMicroArticleData,
        presentations
    ])

    const deleteMicroArticle = useCallback(async () => {
        try {
            if(!featureMicroArticleID) return;
            await MicroArticleService.deleteMicroArticleByID(featureMicroArticleID)
            const newMicroArticles = getAllMicroArticles.filter((ma) => ma.id !== featureMicroArticleID)
            setAllMicroArticles([...newMicroArticles]);
        } catch (error) {
            message.error(t('microArticle.messages.error.deleteMicroArticleFailed'))
        }
    }, [featureMicroArticleID])

    // Handle deletion confirmation
    const handleAttemptDelete = useCallback(() => {
        setIsDeleteModalVisible(true)
    }, [])

    const handleAttemptClose = useCallback(() => {
        setIsCancelModalVisible(true)
    }, [])

    const afterPresentationsModalClose = useCallback(() => {
        setPresentationsIsOpen(false);
        setActiveButton(null)
    }, [])

    const confirmDelete = useCallback(() => {
        setIsDeleteModalVisible(false)
        handleDeleteMicroArticle()
        // Reset form and state
        onClose()
        deleteMicroArticle()
            .then(() => {
                message.success(t('microArticle.messages.success.articleDeleted'))
            })
            .catch(() => {
                message.error(t('microArticle.messages.error.deleteMicroArticleFailed'))
            })
    }, [handleDeleteMicroArticle, onClose, deleteMicroArticle])

    const agreeCancelModal = useCallback(() => {
        setIsCancelModalVisible(false)
        setIsModalVisible(false)
        onPopupClose()
    }, [onPopupClose])

    const cancelDelete = useCallback(() => {
        setIsDeleteModalVisible(false)
    }, [])

    const cancelCancelModal = useCallback(() => {
        setIsCancelModalVisible(false)
    }, [])

    // Handle coordinates change
    const handleCoordinatesChange = useCallback(() => {
        const value = form.getFieldValue('coordinates')
        if (!value) return

        const coords = value.split(',').map((c) => parseFloat(c.trim()))
        if (
            coords.length === 2 &&
            !isNaN(coords[0]) &&
            !isNaN(coords[1]) &&
            coords[0] >= -90 &&
            coords[0] <= 90 &&
            coords[1] >= -180 &&
            coords[1] <= 180
        ) {
            const [lat, lng] = coords
            onCoordinatesChange({ lat, lng })
        } else {
            message.error(
                t('microArticle.messages.error.invalidCoordinates'),
            )
            form.setFieldsValue({
                coordinates: `${coordinates.lat.toFixed(4)}, ${coordinates.lng.toFixed(4)}`,
            })
        }
    }, [coordinates])

    useEffect(() => {
        return () => {
            form.resetFields()
            setFileList([])
            setSelectedColor('#036147')
            setSelectedIcon(DEFAULT_ICON_SPRITE_NAME)
            setDate1('')
            setEra1('AD')
            setDate2('')
            setEra2('AD')
            setWithoutStart(false)
            setWithoutEnd(false)
            setAlwaysShowOnMap(true)
        }
    }, [form])

    return (
        <div className={styles.container + ' fontStyles microArticleFormPopupContainer'}>
            <Card
                title={
                    <div className={styles.cardHeader}>
                        <span style={{ fontSize: '16px', letterSpacing: '2px' }}>
                          {isEdit
                              ? t('microArticle.mapView.editPopupTexts.title').toUpperCase()
                              : t(
                                  'microArticle.mapView.createPopupTexts.title',
                              )?.toUpperCase()}
                        </span>
                        <div>
                            {isEdit && (
                                <DeleteOutlined
                                    onClick={handleAttemptDelete}
                                    style={{ marginRight: 10 }}
                                    className={styles.deleteIcon}
                                />
                            )}
                            <CloseOutlined
                                disabled={isSubmitting}
                                onClick={!isEdit ? handleAttemptDelete : handleAttemptClose}
                                className={styles.closeIcon}
                            />
                        </div>
                    </div>
                }
                styles={{
                    body: { padding: 15, maxHeight: 638, overflowY: 'auto' },
                    header: {
                        padding: '0px 20px',
                        minHeight: '45px',
                        height: '45px',
                        borderBottom: 'none',
                    },
                }}
                bordered={false}
                className={`${styles.card} custom-scroll-v3`}>
                <Form requiredMark={customizeRequiredMark} form={form} layout="vertical" className={'microArticle_form'}>
                    {/* Title field with toggle button */}
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                        <Form.Item
                            className={styles.formItemChangedAsterisk}
                            label={t('microArticle.mapView.createPopupTexts.fields.name')}
                            style={{ flex: 1 }}
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message: t('microArticle.messages.error.pleaseInsertName'),
                                },
                                {
                                    validator: (_, value) => {
                                        if (!value || value.trim() === '') {
                                            return Promise.reject("");
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}>
                            <Input.TextArea
                                maxLength={50}
                                // autoSize={{ minRows: 1, maxRows: 1 }}
                                showCount
                                placeholder={t('microArticle.mapView.createPopupTexts.fields.namePlaceholder')}
                                onBlur={handleTitleBlur}
                                className={styles.titleInput}
                            />
                        </Form.Item>
                        <div className={styles.popoverWrapper} ref={popupRef}>
                            <Popover
                                className={styles.environmentPopover}
                                content={
                                    <IconSelectPopup
                                        categories={environmentCategories}
                                        getSpriteData={getSpriteData}
                                        handleIconSelect={handleIconSelect}
                                        selectedIcon={selectedIcon}
                                        selectedIconObject={selectedIconObject}
                                        setSelectedIconObject={setSelectedIconObject}
                                        handleColorChangeLocal={handleColorChangeLocal}
                                        recentColors={getRecentColors}
                                        selectedColor={selectedColor}
                                    />
                                }
                                overlayInnerStyle={
                                    window.innerWidth < 1540 ?
                                        {
                                            padding: 0,
                                            willChange: 'transform',
                                            transform: 'scale(0.85) translateZ(0)',
                                            transformOrigin: 'top right',
                                        }
                                        :
                                        {
                                            padding: 0
                                        }
                                }
                                trigger="click"
                                open={activeButton === 'environment'}
                                placement="bottomRight"
                                arrow={false}
                                onOpenChange={(visible) => {
                                    if (visible) {
                                        setActiveButton('environment')
                                    } else {
                                        setActiveButton(null)
                                    }
                                }}>
                                <IconButton
                                    key="environment"
                                    className={styles.smallButton}
                                    toggled={activeButton === 'environment'}
                                    icon={
                                        <ConnectedSpriteSvgComponent
                                            scale={20/28}
                                            className={styles.icon}
                                            name={selectedIcon}
                                        />
                                    }
                                    color={selectedColor}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginTop: 5,
                                        maxWidth: '35px',
                                        maxHeight: '35px',
                                        minWidth: '35px',
                                        minHeight: '35px',
                                        backgroundColor: selectedColor,
                                        border: 'none',
                                    }}
                                    ref={environmentButtonRef}
                                    disabled={false}
                                />
                            </Popover>
                        </div>
                    </div>

                    {/* Description field */}
                    <Form.Item
                        label={t(
                            'microArticle.mapView.createPopupTexts.fields.description',
                        )}
                        name="description"
                        rules={[
                            {
                                required: false,
                                message: t("microArticle.messages.error.pleaseEnterDescription"),
                            },
                        ]}>
                        <Input.TextArea
                            maxLength={1000}
                            rows={4}
                            autoSize={{ minRows: 4, maxRows: 4 }}
                            showCount
                            placeholder={ t("microArticle.messages.error.enterDescription")}
                            className={styles.resizableTextarea}
                        />
                    </Form.Item>

                    {/* Coordinates field with search button */}
                    <Form.Item
                        label={t(
                            'microArticle.mapView.createPopupTexts.fields.coordinates',
                        )}
                        name="coordinates"
                        initialValue={`${coordinates.lat.toFixed(4)}, ${coordinates.lng.toFixed(4)}`}
                        required
                        rules={[
                            {
                                required: true,
                                validator: (_, value) => validateCoordinates(_, value),
                                message: t('microArticle.messages.error.invalidCoordinates'),
                            },
                        ]}>
                        <Input
                            placeholder={t('microArticle.messages.error.pleaseEnterCoordinates')}
                            onBlur={handleCoordinatesChange}
                            style={{
                                height: 35,
                                maxHeight: 35,
                                minHeight: 35,
                            }}
                            suffix={
                                <Button
                                    icon={
                                        <img
                                            className={styles.customIcon}
                                            src={SearchIcon}
                                            alt={'Search Icon'}
                                        />
                                    }
                                    onClick={handleCoordinatesChange}
                                    type="text"
                                    size="middle"
                                />
                            }
                        />
                    </Form.Item>

                    {/* Action buttons */}
                    <div
                        style={{ display: 'flex', justifyContent: 'space-between' }}
                        className={styles.spaceContainer}>
                        {/* Image and Video upload */}
                        <div
                            style={{
                                width: '50%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}>
                            <Upload {...uploadProps}  className={styles.uploadButton}>
                                <IconButton
                                    key="picture"
                                    title={t('microArticle.mapView.createPopupTexts.fields.uploadTooltip')}
                                    icon={<img className={styles.customIcon} src={MediaIcon} />}
                                    className={styles.smallButton}
                                />
                            </Upload>

                            {/* Camera button */}
                            <IconButton
                                key="camera"
                                title={t('microArticle.mapView.createPopupTexts.fields.cameraTooltip')}
                                toggled={activeButton === "camera"}
                                icon={<img className={
                                    styles.customIcon +
                                    ' ' +
                                    (activeButton === 'camera' ? styles.isActiveIcon : '')
                                } src={CameraIcon} />}
                                onClick={() => {
                                    setActiveButton("camera")
                                    if (isMobile) {
                                        if (cameraInputRef.current) {
                                            cameraInputRef.current.click();
                                        }
                                    } else {
                                        setIsShowCameraModal(true);
                                    }
                                }}
                                className={styles.smallButton}
                            />

                            {/* Toggle buttons (Link and iFrame) */}
                            {[
                                {
                                    key: 'link',
                                    title: t('microArticle.mapView.createPopupTexts.fields.urlTooltip'),
                                    icon: (isActive) => (
                                        <img
                                            className={
                                                styles.customIcon +
                                                ' ' +
                                                (isActive ? styles.isActiveIcon : '')
                                            }
                                            src={URLIcon}
                                            alt={'icon'}
                                        />
                                    ),
                                },
                                {
                                    key: 'iframe',
                                    title: t('microArticle.mapView.createPopupTexts.fields.iframeTooltip'),
                                    icon: (isActive) => (
                                        <img
                                            className={isActive ? styles.isActiveIcon : ''}
                                            style={{ width: '20px', height: '20px' }}
                                            src={IframeIcon}
                                            alt={'icon'}
                                        />
                                    ),
                                },
                            ].map(({ key, title, icon }) => (
                                <IconButton
                                    key={key}
                                    toggled={activeButton === key}
                                    title={title}
                                    icon={icon(activeButton === key)}
                                    onClick={() => handleToggle(key)}
                                    className={styles.smallButton}
                                />
                            ))}
                        </div>

                        {/* Conditional input for YouTube */}
                        {activeButton === 'link' && (
                            <Input
                                placeholder={t('microArticle.mapView.createPopupTexts.fields.youtubeLinkPlaceholder')}
                                className={styles.youtubeLinkInput}
                                value={youtubeLink}
                                autoFocus
                                onChange={(e) => setYoutubeLink(e.target.value)}
                                onPressEnter={() => handleAddYoutubeLink(youtubeLink)}
                                onPaste={(e) => {
                                    e.preventDefault();
                                    const pastedText = e.clipboardData.getData('Text').trim()
                                    setYoutubeLink(pastedText)
                                    handleAddYoutubeLink(pastedText)
                                }}
                            />
                        )}

                        {/* Conditional input for iFrame */}
                        {activeButton === 'iframe' && (
                            <Input
                                placeholder={t('microArticle.mapView.createPopupTexts.fields.iframePlaceholder')}
                                className={styles.youtubeLinkInput}
                                value={iframeLink}
                                autoFocus
                                onChange={(e) => setIframeLink(e.target.value)}
                                onPressEnter={() => handleAddIframeLink(iframeLink)}
                                onPaste={(e) => {
                                    const pastedText = e.clipboardData.getData('Text').trim()
                                    setIframeLink(pastedText)
                                    e.preventDefault()
                                    handleAddIframeLink(pastedText)
                                }}
                            />
                        )}
                    </div>

                    {/* Hidden camera input */}
                    {isMobile ? (
                        <input
                            type="file"
                            accept="image/*"
                            capture="environment"
                            style={{ display: 'none' }}
                            ref={cameraInputRef}
                            onChange={handleCameraCapture}
                        />
                    ) : (
                        <WebcamModal
                            isShowCameraModal={isShowCameraModal}
                            closeModal={closeModal}
                            cameraInputRef={cameraInputRef}
                            setIsShowCameraModal={setIsShowCameraModal}
                            handleDesktopCapture={handleDesktopCapture}
                        />
                    )}

                    {/* Upload previews */}
                    {fileList.length > 0 && (
                        <Form.Item style={{ margin: '5px 0px', padding: 0 }}>
                            <div
                                className={`${styles.previewContainer} custom-scroll-v3`}
                                onWheel={handleWheel}
                                ref={previewContainerRef}>
                                {fileList.map((file, index) => (
                                    <div
                                        key={file.uid}
                                        className={styles.previewItem}
                                        draggable
                                        onDragStart={(e) => handleDragStart(e, index)}
                                        onDragEnter={(e) => handleDragEnter(e, index)}
                                        onDragOver={handleDragOver}
                                        onDragEnd={handleDragEnd}
                                        onClick={() => openModal(file)}>
                                        {/* Image Preview */}
                                        {!file.isVideo && !file.isIframe && file.preview && (
                                            <img
                                                src={file.preview}
                                                alt={file.name}
                                                className={styles.previewImage}
                                            />
                                        )}

                                        {/* Uploaded Video File Preview */}
                                        {file.isVideo &&
                                            !file.isIframe &&
                                            !file.videoUrl &&
                                            file.preview && (
                                                <img
                                                    src={file.preview}
                                                    alt={file.name}
                                                    className={styles.previewImage}
                                                />
                                            )}

                                        {/* YouTube Video Preview (Thumbnail) */}
                                        {file.isVideo && file.videoUrl && file.preview && (
                                            <>
                                                <img
                                                    src={file.preview}
                                                    alt={file.name}
                                                    className={styles.previewImage}
                                                />
                                                <img
                                                    src={YoutubeIcon}
                                                    className={styles.playIcon}
                                                    alt="YouTube Video"
                                                />
                                            </>
                                        )}

                                        {/* iFrame Preview (Icon) */}
                                        {file.isIframe &&  (
                                            <div className={styles.iframeIconContainer}>
                                                <img src={file.preview ? file.preview : IframeIcon} className={styles.codeIcon} alt="iFrame" />
                                            </div>
                                        )}

                                        {/* Uploading Overlay */}
                                        {file.status === 'uploading' && (
                                            <div className={styles.uploadingOverlay}>
                                                <Spin />
                                            </div>
                                        )}

                                        {/* Error Indicator */}
                                        {file.status === 'error' && (
                                            <CloseOutlined
                                                className={styles.removeIcon}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    handleRemove(file)
                                                }}
                                            />
                                        )}
                                    </div>
                                ))}
                            </div>
                        </Form.Item>
                    )}

                    {/* Action buttons continued */}
                    <Space
                        direction="vertical"
                        style={{
                            marginTop: fileList.length ? 5 : 10,
                            width: '100%',
                            maxWidth: '100%',
                        }}>
                        <div className={styles.flexRow}>
                            <IconButton
                                key="timePeriod"
                                icon={
                                    activeButton === 'timePeriod' ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                gap: 5,
                                                padding: 10,
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}>
                                            <img
                                                className={
                                                    styles.customIcon +
                                                    ' ' +
                                                    (activeButton === 'timePeriod'
                                                        ? styles.isActiveIcon
                                                        : '')
                                                }
                                                src={TimeIcon}
                                            />
                                            <span style={{ marginLeft: 12 }}>
                        {t(
                            'microArticle.mapView.timeParameters.labels.timePeriod',
                        )}
                      </span>
                                        </div>
                                    ) : (
                                        <img className={styles.customIcon} src={TimeIcon} />
                                    )
                                }
                                style={{
                                    width: activeButton === 'timePeriod' ? 'fit-content' : 32,
                                }}
                                toggled={activeButton === 'timePeriod'}
                                onClick={() => handleToggle('timePeriod')}
                                className={styles.smallButton}
                            />
                            {activeButton !== 'timePeriod' && (
                                <Typography.Text className={styles.marginLeft10}>
                                    {t('microArticle.mapView.timeParameters.labels.timePeriod')}
                                </Typography.Text>
                            )}
                        </div>
                        {activeButton === 'timePeriod' && (
                            <>
                                <Form.Item style={{ marginBottom: 0 }}>
                                    <Checkbox
                                        checked={alwaysShowOnMap}
                                        onChange={(e) => {
                                            const checked = e.target.checked
                                            setAlwaysShowOnMap(checked)
                                            if (checked) {
                                                setWithoutStart(true)
                                                setWithoutEnd(true)
                                            } else {
                                                setWithoutStart(false)
                                                setWithoutEnd(false)
                                            }
                                        }}>
                                        {t(
                                            'microArticle.mapView.timeParameters.labels.showOnMapAlways',
                                        )}
                                    </Checkbox>
                                </Form.Item>

                                <DualDateInput
                                    date1={date1}
                                    setDate1={setDate1}
                                    era1={era1}
                                    setEra1={setEra1}
                                    error1={error1}
                                    setError1={setError1}
                                    withoutStart={withoutStart}
                                    setWithoutStart={setWithoutStart}
                                    date2={date2}
                                    setDate2={setDate2}
                                    era2={era2}
                                    setEra2={setEra2}
                                    error2={error2}
                                    setError2={setError2}
                                    withoutEnd={withoutEnd}
                                    setWithoutEnd={setWithoutEnd}
                                    alwaysShowOnMap={alwaysShowOnMap}
                                />
                            </>
                        )}
                        <div className={styles.flexRow}>
                            <IconButton
                                key="addToDisplay"
                                icon={
                                    <img
                                        className={cn(styles.customIcon, {
                                            [styles.isActiveIcon]: activeButton === 'addToDisplay' || presentations?.length || microArticlePresentation,
                                        })}
                                        alt="Add to display"
                                        src={PresentationsIcon}
                                    />
                                }
                                toggled={activeButton === 'addToDisplay' || presentations?.length || microArticlePresentation}
                                onClick={() => handleToggle('addToDisplay')}
                                className={styles.smallButton}
                            />
                            <Typography.Text className={styles.marginLeft10}>
                                {t('microArticle.mapView.presentationPart.addToPresentation')}
                            </Typography.Text>
                        </div>
                    </Space>
                    <div className={styles.buttonContainer}>
                        {/* Save button */}
                        <Form.Item noStyle shouldUpdate>
                            {() => {
                                const hasErrors = form
                                    .getFieldsError()
                                    .some(({ errors }) => errors.length)
                                return (
                                    <Button
                                        type="primary"
                                        shape="round"
                                        className={styles.saveButton}
                                        onClick={handleSubmit}
                                        loading={isSubmitting}
                                        disabled={
                                            isSubmitting ||
                                            hasErrors ||
                                            !form.isFieldsTouched() ||
                                            error1 ||
                                            error2 ||
                                            (!alwaysShowOnMap && !date1) ||
                                            (!date1 && date2)
                                        }>
                                        {isSubmitting ? "" : t('save').toUpperCase()}
                                    </Button>
                                )
                            }}
                        </Form.Item>
                    </div>
                </Form>

                {/* Modal for media playback and description */}
                <Modal
                    open={isModalVisible}
                    footer={null}
                    destroyOnClose={true}
                    onCancel={closeModal}
                    centered={true}
                    width={800}
                    className={styles.captionModal}
                    title={
                        <div className={styles.modalHeader}>
                            <Typography.Title
                                className={styles.modalHeaderText}
                                level={4}
                                strong>
                                {currentMediaType === 'image'
                                    ? t(
                                        'microArticle.mapView.mediaPart.mediaPopup.imagePopup.title',
                                    )
                                    : t(
                                        'microArticle.mapView.mediaPart.mediaPopup.videoPopup.title',
                                    )}
                            </Typography.Title>
                        </div>
                    }>
                    <div className={styles.modalContent}>
                        {currentMediaType === 'video' && currentMediaUrl && (
                            <div className={styles.iframeWrapper}>
                                <iframe
                                    key={currentMediaUrl}
                                    width="100%"
                                    height="100%"
                                    src={currentMediaUrl}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    title="YouTube Video"
                                    className={styles.modalMedia}></iframe>
                            </div>
                        )}
                        {currentMediaType === 'iframe' && currentMediaUrl && (
                            <div className={styles.iframeWrapper}>
                                <iframe
                                    key={currentMediaUrl}
                                    width="100%"
                                    height="100%"
                                    src={currentMediaUrl}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    title="iFrame Content"
                                    className={styles.modalMedia}></iframe>
                            </div>
                        )}
                        {currentMediaType === 'image' && currentMediaUrl && (
                            <img
                                src={currentMediaUrl}
                                alt="Selected"
                                className={styles.modalImage}
                            />
                        )}
                        {currentMediaType === 'uploadedVideo' && currentMediaUrl && (
                            <video
                                controls
                                className={styles.modalVideo}
                                src={currentMediaUrl}
                            />
                        )}
                        {/* Description field */}
                        <Form.Item>
                            <Input.TextArea
                                rows={4}
                                maxLength={1000}
                                showCount
                                value={currentDescription}
                                onChange={handleDescriptionChange}
                                placeholder={
                                    currentMediaType === 'image'
                                        ? t(
                                            'microArticle.mapView.mediaPart.mediaPopup.imagePopup.captionPlaceholder',
                                        )
                                        : t(
                                            'microArticle.mapView.mediaPart.mediaPopup.videoPopup.captionPlaceholder',
                                        )
                                }
                            />
                        </Form.Item>
                        {/* Save and Delete buttons */}
                        <div className={styles.modalButtonContainer}>
                            <Button
                                type="primary"
                                onClick={handleSaveDescription}
                                className={styles.modalSaveButton}>
                                {t('microArticle.mapView.mediaPart.mediaPopup.buttons.save')}
                            </Button>
                            <Button
                                type="danger"
                                onClick={handleDeleteItem}
                                className={styles.modalDeleteButton}>
                                {t('microArticle.mapView.mediaPart.mediaPopup.buttons.delete')}
                            </Button>
                        </div>
                    </div>
                </Modal>

                {/* Confirmation modal for deletion */}
                <DeleteConfirmationModal
                    visible={isDeleteModalVisible}
                    onCancel={cancelDelete}
                    onAgree={confirmDelete}
                />

                {/* Confirmation modal for cancel editing */}
                <DeleteConfirmationModal
                    visible={isCancelModalVisible}
                    onCancel={cancelCancelModal}
                    onAgree={agreeCancelModal}
                    isClose={true}
                />
            </Card>

            <Modal
                destroyOnClose
                title={t('presentation_mode.presentations')}
                centered
                classNames={{
                    header:
                        'uppercase text-center !pb-3 text-[18px] tracking-wider font-medium !pt-1 !px-3  [&>.ant-modal-title]:text-[18px] [&>.ant-modal-title]:text-[#2C476C]',
                }}
                // getContainer={() => document.querySelector('.ant-popover-inner')}
                className="app-modal max-h-[90vh]"
                wrapClassName="!z-[1240]"
                width={950}
                footer={false}
                style={{ padding: 0 }}
                onCancel={afterPresentationsModalClose}
                open={presentationsIsOpen}>
                <PresentationsSelector onSelect={changePresentationsHandler} data={presentations} defaultSelected={microArticlePresentation}/>
            </Modal>
        </div>
    )
}

const mapStateToProps = (state) => ({
    getMicroArticleData: getMicroArticleData(state),
    getSprite: getSprite(state),
    getSpriteData: getSpriteData(state),
    getSpriteURLData: getSpriteURLData(state),
    getSpriteBase64Data: getSpriteBase64Data(state),
    getMicroArticleSelectedCategoryId: getMicroArticleSelectedCategoryId(state),
    getMicroArticleSpriteIconsSearch: getMicroArticleSpriteIconsSearch(state),
    getAllMicroArticles: getAllMicroArticles(state),
    getRecentColors: getRecentColors(state),
})

const mapDispatchToProps = {
    setMicroArticleData,
    setMicroArticleIconsSpriteCategories,
    setMicroArticleIconsKeys,
    setAllMicroArticles,
    setRecentColors,
    setMicroArticlePresentation,
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(PopupForm))
