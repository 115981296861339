import React, { Component, useEffect, useLayoutEffect, useState } from "react";
import Account from "../account";
import "./style.css";
import LeftNavigation from "../../components/leftNavigation";
import AccountFooter from "./../../components/accountFooter/index";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import PresentationCard from "../../components/UI/PresentationsCard";
import SearchSection from "../../components/UI/PresentationsCard/SearchSection";
import {
    getUserData,
    getPresentationData,
    getPresentationLoading,
    getRemoveCardId, getPresentationFilteredTotal,
} from "../../store/selectors";
import { getUserFromAPI } from "../../store/actions/user";
import { getAllPresentationsFromAPI } from "../../store/actions/presentation";
import { Pagination, Stack } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import PageLoader from "../../components/UI/PageLoader";
import {useTranslation} from "react-i18next";
import TopBar from "../../components/topBar";

export const withNavigation = (Component) => {
    return (props) => <Component {...props} navigate={useNavigate()} />;
};
const queryParams = new URLSearchParams(window.location.search);
const arr = Array.from({ length: 15 }, (v, i) => i);
const debounce = (fn, wait) => {
    let timeoutID;
    return (...arg) => {
        if (timeoutID) clearTimeout(timeoutID);
        timeoutID = setTimeout(() => fn(...arg), wait);
    };
};
const debeounced300 = debounce((fn) => fn(), 300);
const Presentation = (props) => {
    const {t} = useTranslation()
    const matches = useMediaQuery("(min-width:600px)");
    const [page, setPage] = useState(1);
    const [isDeleted, setIsDeleted] = useState(false)
    const [searchParams, setSearchParams] = useState({});
    const handleChangePage = (e, value) => {
        const pageLoc = +value;
        if (!isNaN(pageLoc) && typeof pageLoc === "number" && pageLoc !== page) {
            setPage(() => pageLoc);
            // props.getAllPresentationsFromAPI({
            //     "page": pageLoc,
            //     "perPage": 10,
            //     ...searchParams
            // }, props.user.id);
            window.history.replaceState(null, null, "?page=" + pageLoc);
        }
    };

    const handleGetData = (deleted) => {
        if(deleted) {
            setIsDeleted(true)
        } else {
            setIsDeleted(false)
        }
        const queryParams = new URLSearchParams(window.location.search);
        let pageLoc = +queryParams.get("page");
        if (pageLoc > Math.ceil(props.getPresentationFilteredTotal / 10))
            pageLoc = Math.ceil(props.getPresentationFilteredTotal / 10);
        if (pageLoc < 1) pageLoc = 1;
        if (!isNaN(pageLoc) && typeof pageLoc === "number") {
            if (pageLoc !== page) setPage(() => pageLoc);
            props.getAllPresentationsFromAPI({
                "page": pageLoc,
                "perPage": 10,
                ...searchParams
            }, props.user.id);
            window.history.replaceState(null, null, "?page=" + pageLoc);
        }
    }
    const handleSetSearchParams = (e) =>{
        debeounced300(()=> {
            handleChangePage(null,1)
            const newSearch = {...searchParams}
            newSearch.searchTerm = e?.target?.value || ''
            setSearchParams(prev => newSearch)
        })
    }
    useEffect(() => {
        if (props?.user?.id) {
            debeounced300(()=> {
                handleGetData()
            })
        }
    }, [props.user,searchParams,page]);

    // useEffect(() => {
    //   const queryParams = new URLSearchParams(window.location.search);
    //   let pageLoc = +queryParams.get("page");
    //   if (pageLoc > Math.ceil(props.getPresentationData.total / 10)) {
    //     pageLoc = Math.ceil(props.getPresentationData.total / 10);
    //     if (!isNaN(pageLoc) && typeof pageLoc === "number") {
    //       if (pageLoc !== page) setPage(() => pageLoc);
    //       window.history.replaceState(null, null, "?page=" + pageLoc);
    //       props.getAllPresentationsFromAPI(pageLoc, props.user.id);
    //     }
    //   }
    // }, [props.getPresentationData]);

    // useLayoutEffect(() => {
    //   HandleToken();
    //   if (!props.user) {
    //     props.getUserFromAPI();
    //   }
    // }, []);

    return (
        <Account onlyBG={true}>
            <div className={'headerWrapper'}>
                <LeftNavigation />
                <TopBar />
            </div>
            <div className="bookmark-dashboards">
                <div className="account-dashboards--search">
                    <SearchSection handleSetSearchParams={handleSetSearchParams}/>
                </div>
                <div className="account-dashboards--content" id="gap">
                    {!props.getPresentationLoading || isDeleted ? (
                        props?.getPresentationData?.length > 0 ? (
                            props?.getPresentationData?.map((el) => (
                                <PresentationCard
                                    key={el.id}
                                    item={el}
                                    removeCardId={props.getRemoveCardId}
                                    handleGetData={handleGetData}
                                />
                            ))
                        ) : (
                            <div className={"dynamic-screen-shot-empty-data"}>
                                <h2>{t('noSavedPresentations')}</h2>
                            </div>
                        )
                    ) : (
                        <div
                            style={{
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%,-50%)",
                                zIndex: 1222,
                            }}
                        >
                            <PageLoader />
                        </div>
                    )}
                </div>
                <div className="account-dashboards--pagination">
                    {(!props.getPresentationLoading || isDeleted) &&
                        Math.ceil(props.getPresentationFilteredTotal / 10) > 0 && (
                            <>
                                <Pagination
                                    className={"small"}
                                    count={Math.ceil(props.getPresentationFilteredTotal / 10)}
                                    size="small"
                                    page={page}
                                    onChange={handleChangePage}
                                />
                                <Pagination
                                    className={"medium"}
                                    count={Math.ceil(props.getPresentationFilteredTotal / 10)}
                                    page={page}
                                    onChange={handleChangePage}
                                />
                            </>
                        )}
                </div>
            </div>
            <AccountFooter />
        </Account>
    );
};
const mapStateTopProps = (state) => ({
    user: getUserData(state),
    getPresentationData: getPresentationData(state),
    getPresentationFilteredTotal: getPresentationFilteredTotal(state),
    getPresentationLoading: getPresentationLoading(state),
    getRemoveCardId: getRemoveCardId(state),
});

const mapDispatchToProps = {
    getUserFromAPI,
    getAllPresentationsFromAPI,
};

export default connect(
    mapStateTopProps,
    mapDispatchToProps
)(withNavigation(Presentation));
