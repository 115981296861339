import React, {useEffect, useRef, useState} from "react";
import "./style.css";
import GalleryTili from "./GalleryTili";
import GalleryView from "./GalleryView";
import FullScreenGalleryItem from "./FullScreenGalleryItem";
import {
  getIsFormPopupVisible,
  getIsSingleMicroArticleLoading,
  getMicroArticleData,
  getPopupDetailFontSize,
  getScreenShotLoadingST
} from "../../../store/selectors";
import {
  setIsSingleMicroArticleLoading,
  setMicroArticleData,
  setPopupDetailFontSize, setViewPopupVisibility
} from "../../../store/actions/microArticle";
import {
  setActiveScreenShot,
  setScreenShotCurrentTiliIndex,
  setScreenShotFullScreen,
  setScreenShotGalleryIndex
} from "../../../store/actions/mapStateAction";
import {connect} from "react-redux";

const GalleryNew = (props) => {
  const {
      title,
      gallery,
      galleryItems,
      toggleGallery,
      mapState,
      articleId,
      fromArticle,
      setActiveScreenShot,
      setScreenShotGalleryIndex,
      setScreenShotFullScreen,
      setScreenShotCurrentTiliIndex,
  } = props;

  console.log("GALLERY PROPS", props);

  const [isPresentation, setIsPresentation] = useState(false);
  const [tili, setTili] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(props.feauturedImageIdx);
  const [currentTiliIndex, setCurrentTiliIndex] = useState(props.feauturedTiliPageIdx);
  const [isSlideMaximize, setIsSlideMaximize] = useState(false);

  const galleryContainer = useRef(null);
  const galleryThumb = useRef(null);
  const galleryCaption = useRef(null);
  const fullScreenRef = useRef(null);
  const galleryRef = useRef(null);
  const galleryTiliRef = useRef(null);
  const gallerySectionRef = useRef(null);
  const galleryTiliContainer = useRef(null);
  const galleryTiliContainerListener = useRef({});
  console.log("currentTiliIndex IN GALLERY", currentTiliIndex);
  const handleSetSlideTiliIndex = (newIndex) => {
    setCurrentTiliIndex(newIndex);
  };

  const handlePopState = () => {
    updateIsPresentation();
  };

  function getPageFromUrl (type) {
    const params = new URLSearchParams(window.location.search);
    return params.get(type);
  };

  const updateIsPresentation = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const isPresentation = searchParams.get("presentation");
    setIsPresentation(!!isPresentation);
  };

  // const searchItemAndUpdateMaximizeState = () => {
  //   const activeItem = document.querySelector(".is-active .is-visible");
  //   if (activeItem?.firstChild?.classList.contains("is-video")) {
  //     setIsSlideMaximize(true);
  //   } else {
  //     setIsSlideMaximize(false);
  //   }
  // };

  const updateSlideMaximizeState = (type) => {
    setIsSlideMaximize(Boolean(type === 1) || Boolean(type === 3));
  };
  const slideMoveHandler = () => {
    const iframes = document.querySelectorAll(".gallery-main iframe");
    iframes.forEach((iframe) => {
      iframe.src = iframe.src;
    });
  }


  const convertToNoCookie = (url) => {
    return url.replace('youtube.com', 'youtube-nocookie.com');
  };


  // const toggleFullScreenFromTili = (action, path, tag) => {
  //   if (!action) {
  //     gallerySectionRef.current.setAttribute('data-full-screen', false);
  //     return;
  //   }
  //   gallerySectionRef.current.setAttribute('data-full-screen', true);
  //   let element = document.createElement(tag);
  //   element.setAttribute('src', path);
  //   if (tag === 'video') element.setAttribute('controls', true);
  //   setTimeout(() => {
  //     fullScreenRef.current.innerHTML = element.outerHTML;
  //   }, 0);
  // };

  const switchToSlide = (idx) => {
    setTimeout(() => {
      toggleMode();
      galleryThumb.current?.splide?.go(idx);
    }, 500);
  };

  const toggleFullScreen = (action, index) => {
    if (!action) {
      props.setGalleryFullScreen(false);
      gallerySectionRef.current.setAttribute('data-full-screen', false);
      setScreenShotFullScreen(false);
      return;
    }
    const activeGalleryItem = props.galleryItems[index ?? currentIndex ?? 0];
    if (!activeGalleryItem) {
      console.error('Active gallery item not found.');
      return;
    }
    const imgHtml = `<img src="${activeGalleryItem.path}" alt="${activeGalleryItem.caption}" />`;
    gallerySectionRef.current.setAttribute('data-full-screen', true);
    setScreenShotFullScreen(true);
    setTimeout(() => {
      fullScreenRef.current.innerHTML = imgHtml;
    }, 0);
  };

  const toggleMode = () => {

    setTili(prev => {
      const showTili = !prev;
      setScreenShotCurrentTiliIndex(showTili ? 0 : null)
      return showTili
    });
  };

  const closeFullScreen = () => {
    setScreenShotFullScreen(false);
    setScreenShotCurrentTiliIndex(null)
    toggleGallery()
  };

  useEffect(() => {
   setTimeout(() => {
     const splideContainer = galleryContainer.current?.splide;
     const splideCaption = galleryCaption.current?.splide;
     const splideThumb = galleryThumb.current?.splide;

     if (splideCaption) {
       splideCaption.on("move", (newIndex) => {
         setCurrentIndex(newIndex);
       });
     }
     if (splideContainer && splideCaption && splideThumb) {
       splideContainer.sync(splideCaption);
       splideContainer.sync(splideThumb);
     }
   },300)
  }, [  ]);


  useEffect(() => {
    // if (props.isFullScreen) {
    console.log("props.isFullScreen, currentIndex", props.isFullScreen, currentIndex);
    toggleFullScreen(props.isFullScreen, currentIndex);
    // }
  }, [props.isFullScreen, currentIndex]);

  useEffect(() => {
    if (props.isGrid) {
      toggleMode();
    }
  }, [props.isGrid]);

  useEffect(() => {
    updateIsPresentation();
  }, []);

  useEffect(() => {
    // TODO see this part
    if (props.feauturedImageIdx !== galleryThumb.current?.splide?.index) {
      const timeoutId = setTimeout(() => {
          galleryThumb.current?.splide?.go(props.feauturedImageIdx );
      }, 0);
    }
  }, [props.feauturedImageIdx]);

  useEffect(() => {
    if (props.isGrid && !tili) {
      // setTili(true);
    }
  }, [props.isGrid, tili]);

  useEffect(() => {
    if (props.screenShotFirstLoad && tili && !galleryTiliContainerListener.current?.["move"]) {
      const timeoutId = setTimeout(() => {
        const splideTili = galleryTiliContainer.current?.splide;
        if (splideTili) {
          splideTili.go(currentTiliIndex);
        }
      }, 500);

      return () => clearTimeout(timeoutId); // Cleanup timeout if necessary
    }
  }, [props.screenShotFirstLoad, tili, currentTiliIndex]);

  useEffect(() => {
    const splideTili = galleryTiliContainer.current?.splide;
    console.log("XXXX", tili);
    if (tili && !galleryTiliContainerListener.current?.["move"]) {
      splideTili.on("move", handleSetSlideTiliIndex);
      galleryTiliContainerListener.current["move"] = handleSetSlideTiliIndex;
    } else if (!tili && galleryTiliContainerListener.current?.['move']) {
      splideTili.off("move", galleryTiliContainerListener.current['move']);
      delete galleryTiliContainerListener.current['move'];
    }

    return () => {
      if (galleryTiliContainerListener.current?.['move']) {
        splideTili.off("move", galleryTiliContainerListener.current['move']);
      }
    };
  }, [tili]);

  useEffect(() => {
    // Attach the event listener
    window.addEventListener('popstate', handlePopState);
    // Cleanup function to run on unmount
    return () => {
      // Reset currentTiliIndex and remove the event listener
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  useEffect(() => {
    setActiveScreenShot('article-gallery')

    return () => {
      const isFromArticleModeView = !!document.getElementById("article-read-more");
      console.log("document.getElementById(\"article-read-more\")", document.getElementById("article-read-more"));
      setActiveScreenShot(isFromArticleModeView ? 'article-read-more' : 'map')
    }
  }, []);

  return (
      <div className={`gallery-section`} ref={gallerySectionRef} id='gallery-section' data-full-screen="false">
        <div id={"image"}></div>
        <GalleryTili
            tili={tili}
            title={title}
            isPresentation={isPresentation}
            articleId={articleId}
            fromArticle={fromArticle}
            currentTiliIndex={currentTiliIndex}
            mapState={mapState}
            startTiliIdx={props.feauturedTiliPageIdx}
            toggleGallery={closeFullScreen}
            galleryTiliRef={galleryTiliRef}
            galleryTiliContainer={galleryTiliContainer}
            getPageFromUrl={getPageFromUrl}
            gallery={gallery}
            galleryItems={galleryItems}
            switchToSlide={switchToSlide}
        />
        <GalleryView
            title={title}
            tili={tili}
            toggleMode={toggleMode}
            toggleFullScreen={toggleFullScreen}
            isSlideMaximize={isSlideMaximize}
            toggleGallery={closeFullScreen}
            galleryRef={galleryRef}
            galleryContainer={galleryContainer}
            galleryThumb={galleryThumb}
            galleryCaption={galleryCaption}
            startIdx={props.feauturedImageIdx}
            gallery={gallery}
            getPageFromUrl={getPageFromUrl}
            isPresentation={isPresentation}
            articleId={articleId}
            fromArticle={fromArticle}
            currentIndex={currentIndex}
            mapState={mapState}
            galleryItems={galleryItems}
            activeGalleryItem={props.galleryItems[currentIndex]}
            updateSlideMaximizeState={updateSlideMaximizeState}
            convertToNoCookie={convertToNoCookie}
            slideMoveHandler={slideMoveHandler}
            setScreenShotGalleryIndex={setScreenShotGalleryIndex}
        />
        <FullScreenGalleryItem
            getPageFromUrl={getPageFromUrl}
            isPresentation={isPresentation}
            articleId={articleId}
            fromArticle={fromArticle}
            currentIndex={currentIndex}
            mapState={mapState}
            toggleFullScreen={toggleFullScreen}
            toggleGallery={closeFullScreen}
            fullScreenRef={fullScreenRef}
        />
      </div>

  );
}

const mapStateToProps = (state) => ({

})

/**
 * Map Redux actions to component props.
 */
const mapDispatchToProps = {
  setActiveScreenShot,
  setScreenShotGalleryIndex,
  setScreenShotFullScreen,
  setScreenShotCurrentTiliIndex,
}

/**
 * Connect the component to Redux and export.
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(React.memo(GalleryNew))


