// src/components/microArticle/components/DualDateInput/MicroArticleBoardCard.js
import React, { useEffect } from 'react';
import { Input, Tooltip, Checkbox, Typography } from 'antd';
import IconButton from './../../UI/IconButton';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";
import {isMobile} from "react-device-detect";
import styles from "../PopupFormMobile/PopupForm.module.css"; // Import PropTypes for type checking
import './style.css';

const DualDateInput = ({
                           date1,
                           setDate1,
                           era1,
                           setEra1,
                           error1,
                           setError1,
                           withoutStart,
                           setWithoutStart,
                           date2,
                           setDate2,
                           era2,
                           setEra2,
                           error2,
                           setError2,
                           withoutEnd,
                           setWithoutEnd,
                           alwaysShowOnMap, // New prop
                       }) => {

    const {t} = useTranslation()

    // Handler for date input changes
    const handleDateChange = (setterDate, setterError, value, era, isStart) => {
        // Allow digits and '/' separators
        const cleaned = value.replace(/[^\d/]/g, '');
        setterDate(cleaned);

        // isEnd === !isStart

        if (isValidDate(cleaned, era, !isStart)) {
            setterError('');
        } else if (cleaned.length > 0) {
            // setterError(t('microArticle.mapView.timeParameters.labels.error'));
        } else {
            setterError('');
        }
    };

    // Function to validate date string in Y+/MM/DD format (accepts partial dates)
    const isValidDate = (dateString, era, isEnd) => {
        const regex = /^(\d+)(\/(\d{1,2})(\/(\d{1,2}))?)?$/;
        const match = dateString.match(regex);
        if (!match) return false;

        let year = parseInt(match[1], 10);
        let month = match[3] ? parseInt(match[3], 10) : null;
        let day   = match[5] ? parseInt(match[5], 10) : null;

        if (year === 0) return false; // Year 0 is invalid

        if (era === 'BC') {
            if (year < 1 || year > 4500) return false;
        } else { // AD
            const currentYear = new Date().getFullYear();
            if (year < 1 || year > currentYear + 100) return false;
        }

        // Month validation
        if (month !== null && (month < 1 || month > 12)) return false;

        // Day validation
        if (day !== null) {
            if (day < 1 || day > 31) return false;

            // If month is provided, validate day against that month’s max length
            if (month !== null) {
                const monthLengths = [
                    31, isLeapYear(year) ? 29 : 28, 31, 30, 31, 30,
                    31, 31, 30, 31, 30, 31
                ];
                if (day > monthLengths[month - 1]) return false;
            }
        }

        // Ensure end date is not beyond current date
        if (isEnd && era === 'AD') {
            const inputDate = new Date(year, (month || 1) - 1, day || 1);
            const currentDate = new Date();
            if (inputDate > currentDate) return false;
        }

        return true;
    };

    // Function to determine if a year is a leap year
    const isLeapYear = (year) => {
        if (year % 4 !== 0) return false;
        if (year % 100 !== 0) return true;
        return year % 400 === 0;
    };

    const parseDate = (date, era) => {
        const parts = date.split('/').map(Number);
        return {
            era,
            year: parts[0],
            month: parts[1],
            day: parts[2],
        };
    };

    // Function to compare two dates
    // Returns 1 if dateA > dateB, -1 if dateA < dateB, 0 if equal
    const compareDates = (dateA, eraA, dateB, eraB) => {
      if (!dateA) return null
      const a = parseDate(dateA, eraA)
      if (a.year === 0) {
        setError1(t('microArticle.mapView.timeParameters.labels.error'))
        return 1
      }

      if (!dateB) return null
      const b = parseDate(dateB, eraB)
      if (b.year === 0) {
          setError2(t('microArticle.mapView.timeParameters.labels.error'));
          return 1
      }

      if (a.era === 'BC' && b.era === 'AD') return -1
      if (a.era === 'AD' && b.era === 'BC') return 1

      if (a.era === 'BC' && b.era === 'BC') {
        if (a.year > b.year) return -1
        if (a.year < b.year) return 1
      } else if (a.era === 'AD' && b.era === 'AD') {
        if (a.year > b.year) return 1
        if (a.year < b.year) return -1
      }

      if (a.month > b.month) return 1
      if (a.month < b.month) return -1

      if (a.day > b.day) return 1
      if (a.day < b.day) return -1

      return 0
    }

    // Function to validate the relationship between start and end dates
    const validateDateRange = () => {
        const a = parseDate(date1, era1);
        const b = parseDate(date2, era2);

        const now = new Date();
        const currentYear = now.getFullYear();
        const currentMonth = now.getMonth() + 1;
        const currentDay = now.getDate();

        const isDateAfterNow = (parsedDate) => {
            if (parsedDate.era === 'AD') {
                if (parsedDate.year > currentYear) return true;
                if (parsedDate.year === currentYear && parsedDate.month > currentMonth) return true;
                if (parsedDate.year === currentYear && parsedDate.month === currentMonth && parsedDate.day > currentDay) return true;
            }
            return false;
        };

        if (isDateAfterNow(a)) {
            setError1(t('microArticle.mapView.timeParameters.labels.error'));
            return;
        }

        if (isDateAfterNow(b)) {
            setError2(t('microArticle.mapView.timeParameters.labels.error'));
            return;
        }

        if (!date1 || !date2) {
            setError1('');
            setError2('');
        }

        const comparison = compareDates(date1, era1, date2, era2);
        if (comparison > 0) {
            setError1(t('microArticle.mapView.timeParameters.labels.error'));
            setError2(t('microArticle.mapView.timeParameters.labels.error'));
        } else {
            setError1('');
            setError2('');
        }
    };

    // useEffect to trigger range validation whenever dates or eras change
    useEffect(() => {
        validateDateRange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date1, era1, date2, era2]);

    // Function to render Input with Tooltip and conditional styling
    const renderInputWithTooltip = (value, onChange, error, disabled) => (
        <Tooltip
            title={error}
            placement="top"
            open={!!error}
            overlayStyle={{ maxWidth: '250px' }}
        >
            <Input
                className={isMobile ? styles.titleInput : ''}
                type="text" // Ensure the input type is text to allow '/'
                value={value}
                onChange={onChange}
                placeholder="YYYY/MM/DD"
                status={error ? 'error' : undefined}
                disabled={disabled}
            />
        </Tooltip>
    );

    // Function to toggle era
    const toggleEra = (currentEra, setEra) => {
        setEra(currentEra === 'AD' ? 'BC' : 'AD');
    };

    // Effect to enforce "Always show on the map" behavior
    useEffect(() => {
        if (alwaysShowOnMap) {
            setWithoutStart(true);
            setWithoutEnd(true);
            setDate1('');
            setDate2('');
            setError1('');
            setError2('');
            setEra1(date1.length ? "AD" : "BC")
        }
    }, [alwaysShowOnMap]);


    return (
        isMobile ? (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                {/* First Date Input Group - Start Date */}
                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <IconButton
                            style={{ textAlign: 'center' }}
                            className={styles.smallButton}
                            icon={
                                <Typography.Text
                                    style={{
                                        "fontFamily": "Noto Sans Armenian",
                                        "fontSize": "16px",
                                        "fontWeight": "400",
                                        "lineHeight": "24px",
                                        "textAlign": "left",
                                        "textUnderlinePosition": "from-font",
                                        "textDecorationSkipInk": "none",
                                        color: era1 === 'BC' ? 'white' : '#2C476C',
                                    }}>
                                    {t('microArticle.mapView.timeParameters.labels.bc').toUpperCase()}
                                </Typography.Text>
                            }
                            onClick={() => toggleEra(era1, setEra1)}
                            toggled={era1 === 'BC'}
                            disabled={alwaysShowOnMap || withoutStart} // Disable when "Always show on the map" or "Without start" is checked
                            aria-label="Toggle Era"
                        />
                        {renderInputWithTooltip(
                            date1,
                            (e) => handleDateChange(setDate1, setError1, e.target.value, era1, true),
                            error1,
                            alwaysShowOnMap || withoutStart, // Disable input when "Always show on the map" or "Without start" is checked
                        )}
                    </div>
                    {/* MAYBE NEEDED AFTER */}
                    {/*<Checkbox*/}
                    {/*    checked={withoutStart}*/}
                    {/*    onChange={(e) => {*/}
                    {/*        const checked = e.target.checked;*/}
                    {/*        setWithoutStart(checked);*/}
                    {/*        if (checked) {*/}
                    {/*            setDate1('');*/}
                    {/*            setError1('');*/}
                    {/*        }*/}
                    {/*    }}*/}
                    {/*    disabled={alwaysShowOnMap} // Disable when "Always show on the map" is checked*/}
                    {/*>*/}
                    {/*    {t('microArticle.mapView.timeParameters.labels.withoutStart')}*/}
                    {/*</Checkbox>*/}
                </div>

                {/* Second Date Input Group - End Date */}
                <div style={{ display: 'flex', alignItems: 'flex-end',flexDirection: 'column', gap: '5px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px', width: '100%' }}>
                        <IconButton
                            style={{ textAlign: 'center' }}
                            className={styles.smallButton}
                            icon={
                                <Typography.Text
                                    style={{
                                        "fontFamily": "Noto Sans Armenian",
                                        "fontSize": "16px",
                                        "fontWeight": "400",
                                        "lineHeight": "24px",
                                        "textAlign": "left",
                                        "textUnderlinePosition": "from-font",
                                        "textDecorationSkipInk": "none",
                                        color: era2 === 'BC' ? 'white' : '#2C476C',
                                    }}>
                                    {t('microArticle.mapView.timeParameters.labels.bc').toUpperCase()}
                                </Typography.Text>
                            }
                            onClick={() => toggleEra(era2, setEra2)}
                            toggled={era2 === 'BC'}
                            disabled={alwaysShowOnMap || withoutEnd} // Disable when "Always show on the map" or "Without end" is checked
                            aria-label="Toggle Era"
                        />
                        {renderInputWithTooltip(
                            date2,
                            (e) => handleDateChange(setDate2, setError2, e.target.value, era2, false),
                            error2,
                            alwaysShowOnMap || withoutEnd, // Disable input when "Always show on the map" or "Without end" is checked
                        )}
                    </div>
                    <Checkbox
                        style={{width: "fit-content"}}
                        checked={withoutEnd}
                        onChange={(e) => {
                            const checked = e.target.checked;
                            setWithoutEnd(checked);
                            if (checked) {
                                setDate2('');
                                setError2('');
                            }
                        }}
                        className={'form-checkbox'}
                        disabled={alwaysShowOnMap} // Disable when "Always show on the map" is checked
                    >
                        {
                            // TODO CHANGE THE DISABLED STATE OF FROM THIS INPUT
                            t('microArticle.mapView.timeParameters.labels.untilToday')
                        }
                    </Checkbox>
                </div>
            </div>
        ) : (
            <div style={{display: 'flex', gap: '20px'}}>
                {/* First Date Input Group - Start Date */}
                <div style={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
                    <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                        <IconButton
                            style={{textAlign: 'center'}}
                            icon={
                                <Typography.Text
                                    style={{
                                        fontWeight: 400,
                                        fontSize: '12px',
                                        color: era1 === 'BC' ? 'white' : '#2C476C',
                                    }}>
                                    {t('microArticle.mapView.timeParameters.labels.bc').toUpperCase()}
                                </Typography.Text>
                            }
                            onClick={() => toggleEra(era1, setEra1)}
                            toggled={era1 === 'BC'}
                            disabled={alwaysShowOnMap || withoutStart} // Disable when "Always show on the map" or "Without start" is checked
                            aria-label="Toggle Era"
                        />
                        {renderInputWithTooltip(
                            date1,
                            (e) => handleDateChange(setDate1, setError1, e.target.value, era1, true),
                            error1,
                            alwaysShowOnMap || withoutStart, // Disable input when "Always show on the map" or "Without start" is checked
                        )}
                    </div>
                    {/* MAYBE NEEDED AFTER */}
                    {/*<Checkbox*/}
                    {/*    checked={withoutStart}*/}
                    {/*    onChange={(e) => {*/}
                    {/*        const checked = e.target.checked;*/}
                    {/*        setWithoutStart(checked);*/}
                    {/*        if (checked) {*/}
                    {/*            setDate1('');*/}
                    {/*            setError1('');*/}
                    {/*        }*/}
                    {/*    }}*/}
                    {/*    disabled={alwaysShowOnMap} // Disable when "Always show on the map" is checked*/}
                    {/*>*/}
                    {/*    {t('microArticle.mapView.timeParameters.labels.withoutStart')}*/}
                    {/*</Checkbox>*/}
                </div>

                {/* Second Date Input Group - End Date */}
                <div style={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
                    <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                        <IconButton
                            style={{textAlign: 'center'}}
                            icon={
                                <Typography.Text
                                    style={{
                                        fontWeight: 400,
                                        fontSize: '12px',
                                        color: era2 === 'BC' ? 'white' : '#2C476C',
                                    }}>
                                    {t('microArticle.mapView.timeParameters.labels.bc').toUpperCase()}
                                </Typography.Text>
                            }
                            onClick={() => toggleEra(era2, setEra2)}
                            toggled={era2 === 'BC'}
                            disabled={alwaysShowOnMap || withoutEnd} // Disable when "Always show on the map" or "Without end" is checked
                            aria-label="Toggle Era"
                        />
                        {renderInputWithTooltip(
                            date2,
                            (e) => handleDateChange(setDate2, setError2, e.target.value, era2, false),
                            error2,
                            alwaysShowOnMap || withoutEnd, // Disable input when "Always show on the map" or "Without end" is checked
                        )}
                    </div>
                    <Checkbox
                        checked={withoutEnd}
                        onChange={(e) => {
                            const checked = e.target.checked;
                            setWithoutEnd(checked);
                            if (checked) {
                                setDate2('');
                                setError2('');
                            }
                        }}
                        className={'form-checkbox'}
                        disabled={alwaysShowOnMap} // Disable when "Always show on the map" is checked
                    >
                        {
                            // TODO CHANGE THE DISABLED STATE OF FROM THIS INPUT
                            t('microArticle.mapView.timeParameters.labels.untilToday')
                        }
                    </Checkbox>
                </div>
            </div>
        )
    );
};

// PropTypes for type checking
DualDateInput.propTypes = {
    date1: PropTypes.string.isRequired,
    setDate1: PropTypes.func.isRequired,
    era1: PropTypes.string.isRequired,
    setEra1: PropTypes.func.isRequired,
    error1: PropTypes.string.isRequired,
    setError1: PropTypes.func.isRequired,
    withoutStart: PropTypes.bool.isRequired,
    setWithoutStart: PropTypes.func.isRequired,
    date2: PropTypes.string.isRequired,
    setDate2: PropTypes.func.isRequired,
    era2: PropTypes.string.isRequired,
    setEra2: PropTypes.func.isRequired,
    error2: PropTypes.string.isRequired,
    setError2: PropTypes.func.isRequired,
    withoutEnd: PropTypes.bool.isRequired,
    setWithoutEnd: PropTypes.func.isRequired,
    alwaysShowOnMap: PropTypes.bool.isRequired, // New prop
};

export default DualDateInput;
