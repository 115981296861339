import React, {cloneElement, useState} from 'react'
import DotsIcon from './icons/DotsIcon'
import {Checkbox, Image, Menu, Modal, Popover} from 'antd'
import PenIcon from './icons/PenIcon'
import CopyIcon from './icons/CopyIcon'
import LinkIcon from './icons/LinkIcon'
import TrashIcon from './icons/TrashIcon'
import PresentationFormWrapper from './form/PresentationFormWrapper'
import {cn} from '../../../utils/utils'
import PlusCircle2Icon from './icons/PlusCircle2Icon'
import {useDispatch} from 'react-redux'
import {
  useClonePresentationMutation,
  useLazyGetPresentationSlidesQuery, useRemoveMediaByPathMutation,
  useRemovePresentationMutation,
} from '../store/presentationApi'
import {setCurrentSlide, setPlaybackPresentation, setSelectedPresentation} from '../store/presentationSlice'
import {generateSiteUrl} from '../lib/utils'
import {useSearchParams} from 'react-router-dom'
import {useTranslation} from "react-i18next";
import PresentationIntro from "./PresentationIntro";



const PresentationCard = ({ name, selectable,disabledSelection, selected, onChange, hideMoreButton, onClick, id, hasSlides, presentation,onStart, image, onSuccess }) => {
  const {t} = useTranslation()
  const [editIsOpen, setIsEditMode] = useState(false)
  const [patumIsOpen, setPatumIsOpen] = useState(false)
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const [isIntroOpen, setIsIntroOpen] = useState(false)

  const [getSlides] = useLazyGetPresentationSlidesQuery()
  const [deleteMediaByPath, { isLoading: isDeleting }] = useRemoveMediaByPathMutation()

  const [queryParams] = useSearchParams()

  const dispatch = useDispatch()

  const [onClone, { isLoading: isCloning }] = useClonePresentationMutation()
  const [onRemove] = useRemovePresentationMutation()


  const startHandler = async (e) => {
    e?.stopPropagation?.()
    setIsIntroOpen(false);
    dispatch(setPlaybackPresentation(presentation))
    dispatch(setCurrentSlide(''))
    let firstSlide;

      try {
        const slidesResponse = await getSlides({
          page: 1,
          perPage: 1,
          columnFiltersMany: {
            presentationId: [String(presentation?.id)],
          },
          sort: [
            {
              field: 'order',
              type: 'asc',
            },
          ],
        }).unwrap()

        firstSlide = slidesResponse.data?.[0].items?.[0]

      } catch (e) {
        console.log(e)
      }

    queryParams.set('presentation', presentation?.id)

    if (!firstSlide?.articleId) {
      // search.set('screenShot', true)
    } else {
      queryParams.set('articleMode', firstSlide?.articleId)
    }
    queryParams.set('screenShot',  "true")
    queryParams.set('loading', true)
    queryParams.set('playBack', true)

    if (firstSlide) {
      queryParams.set('screenSlide', firstSlide?.id)
    }


    window.location.href =
        window.location.origin +
        window.location.pathname +
        '?' +
        queryParams.toString()

    onStart?.(presentation)
  }


  const clickDropdownHandler = (e) => {
    e.domEvent?.stopPropagation()
    switch (e.key) {
      case 'start':
        setIsIntroOpen(true)
        break
      case 'edit':
        setIsEditMode(true)
        break
      case 'copy':
        onClone({ id })
        break
      case 'copy_url':
        getSlides({
          page: 1,
          perPage: 1,
          columnFiltersMany: {
            presentationId: [String(id)],
          },
          sort: [
            {
              field: 'order',
              type: 'asc',
            },
          ],
        })
          .unwrap()
          .then((res) => {
            const slide = res?.data?.[0]?.items?.[0]
            const url = generateSiteUrl({
              presentationId: id,
              articleMode: slide?.articleId && false,
              slideId: slide?.id,
              topicId: queryParams.get('topicID'),
              lessonId: queryParams.get('lessonID'),
            })
            navigator.clipboard.writeText(url)
          })

        break
      case 'remove':
        if (presentation?.imageUrl) {
          deleteMediaByPath({ path: presentation?.imageUrl })
        }
        onRemove(id)
          .unwrap()
          .then(() => {
            dispatch(setSelectedPresentation(null))
          })
        break
      default:
    }
  }

  const items = [
    {
      icon: <PlusCircle2Icon width={17} height={17} />,
      label: t('presentation_mode.play'),
      key: 'start',
      disabled: !hasSlides,
    },
    {
      icon: <PenIcon />,
      label: t('presentation_mode.edit'),
      key: 'edit',
    },
    {
      icon: <LinkIcon />,
      label: t('presentation_mode.copy_url'),
      key: 'copy_url',
    },
    {
      icon: <CopyIcon />,
      label: t('presentation_mode.duplicate'),
      key: 'copy',
    },
    {
      icon: <TrashIcon />,
      label: t('presentation_mode.delete'),
      key: 'remove',
    },
  ]

  const menuPopover = (
    <div className="-m-[12px] max-h-[300px] overflow-y-auto ">
      <Menu className="p-0 bg-transparent" selectable={false}>
        {items.map((item) => (
          <Menu.Item
            className="p-0"
            key={item.name}
            disabled={item.disabled}
            onClick={() => {
              if (item.disabled) return
              clickDropdownHandler(item)
              setMenuIsOpen(false)
            }}>
            <div className={cn('flex items-center -ml-[14px] pr-6', {
              'opacity-50': item.disabled,
            })}>
              <div className="w-6 h-6 flex items-center justify-center text-[#2C476C]">
                {item.icon}
              </div>
              <span className="ml-3 text-[#2C476C]">{item.label}</span>
            </div>
          </Menu.Item>
        ))}
      </Menu>
    </div>
  )

  const afterEditSuccess = () => {
    onSuccess?.()
    setIsEditMode(false)
  }

  return (
    <div className="text-[#2C476C]">
      <div
        id={`presentation-${id}`}
        className={cn('flex flex-col gap-3 mb-2', {
          active: patumIsOpen,
          "cursor-pointer": !!hasSlides
        })}>
        <div className="relative">
          <Image
              onError={e => e.target.src = 'https://community.softr.io/uploads/db9110/original/2X/7/74e6e7e382d0ff5d7773ca9a87e6f6f8817a68a6.jpeg'}
              onClick={onClick}
              className="rounded-2xl w-full aspect-[5/3] object-contain  bg-[#f4f4f4] !h-[169px]"
              src={
                  image ||
                  'https://community.softr.io/uploads/db9110/original/2X/7/74e6e7e382d0ff5d7773ca9a87e6f6f8817a68a6.jpeg'
              }
              preview={false}
          />
          {
            selectable &&  <Checkbox disabled={disabledSelection} checked={selected} onClick={() => !disabledSelection && onChange?.()} rootClassName="absolute bottom-3.5 right-3"/>
          }

        </div>

        <div className="flex items-center justify-between">
          <span className={cn({
            "cursor-pointer": !!hasSlides
          })} onClick={onClick}>
            {name}
          </span>
          {
              !hideMoreButton &&
              <div className="presentation-list--item--icons">
                <Popover
                    onOpenChange={(open) => setMenuIsOpen(open)}
                    openClassName="p-0"
                    placement="rightTop"
                    content={menuPopover}
                    open={menuIsOpen}
                    trigger={['click']}
                    arrow={false}
                    overlayClassName="app-dropdown presentation-article-selector--dropdown rounded-lg p-0 [&>div]:rounded-lg [&>div]:overfloxw-hidden"
                    menu={{items: items, selectable: true}}
                    dropdownRender={(menus) => (
                        <div className="">{cloneElement(menus)}</div>
                    )}>
                  <button
                      className={cn('flex rounded-lg py-2', {
                        'bg-[#F6F6F6]': menuIsOpen,
                      })}
                      onClick={(e) => {
                        e.stopPropagation()
                        setMenuIsOpen(true)
                      }}>
                    <DotsIcon color="#2C476C"/>
                  </button>
                </Popover>
                {/*<Dropdown getPopupContainer={() => document.body} overlayClassName="app-dropdown"*/}
                {/*          placement="bottomLeft" menu={{items, onClick: clickDropdownHandler}}*/}
                {/*          trigger={['click']}>*/}

                {/*</Dropdown>*/}
              </div>
          }
        </div>
      </div>
      <Modal
          destroyOnClose
          title={name}
          centered
          classNames={{
            body: 'max-h-[80vh] overflow-auto',
            header:
                'uppercase text-center !pb-3 text-[16px] font-medium !pt-4 !px-3',
          }}
          getContainer={() => document.body}
          className="app-modal no-padding max-h-[90vh]"
          width={950}
          footer={false}
          style={{padding: 0}}
          onCancel={() => setIsEditMode(false)}
          open={editIsOpen}>
        <PresentationFormWrapper
            id={id}
            createMode={false}
            onSuccess={afterEditSuccess}
        />
      </Modal>

      <Modal
          destroyOnClose
          centered
          classNames={{
            body: 'max-h-[82vh] overflow-auto',
            header:
                'uppercase text-center !pb-3 text-[16px] font-medium !pt-4 !px-3',
          }}
          getContainer={() => document.body}
          className="app-modal no-padding max-h-[80vh]"
          width={700}
          footer={false}
          style={{padding: 0}}
          onCancel={() => setIsIntroOpen(false)}
          open={isIntroOpen}>
        <PresentationIntro id={id} onStart={startHandler}/>
      </Modal>
    </div>
  )
}

export default PresentationCard
