import {
    createScreenShot,
    editScreenShot,
    getScreenShotData,
    getScreenShotSingleData,
    removeScreenShot,
} from "../../service/mapStateService";
import {validate as uuidValidate, v4 as uuidv4} from 'uuid';

import {delay, isValidArray} from '../../utils/utils'
import {
    SET_CATEGORIES_ARTICLE_ITEM_ID,
    SET_LAMP_DATA_FILTERED_SCREEN_SHOT,
    SET_LAMP_SELECTED_ID,
    SET_LAMP_SORTED_TOOLTIP_STATE,
    SET_MAP_CENTER_POSITION,
    SET_MAP_CENTER_POSITION_STATUS,
    SET_MAP_REQUIREMENT_SCREEN_SHOT,
    SET_MAP_STATE_DATA,
    SET_MAP_STATE_SINGLE_DATA,
    SET_MAP_STYLES_DARK_ID,
    SET_MAP_STYLES_ID,
    SET_MAP_STYLES_STATUS,
    SET_MOBILE_MENU_CLOSE,
    SET_OPEN_CATEGORY_ID,
    SET_PAINTER_GEOJSON_DATA_ST,
    SET_REMOVE_CARD_ID,
    SET_RULER_GEOJSON_DATA_ST,
    SET_SCREENSHOT_BUTTON_CLICK,
    SET_MAP_MARKER_INFO,
    SET_SCREENSHOT_BUTTON_STATE,
    SET_SCREENSHOT_INTERMEDIATE_DATA,
    SET_SCREENSHOT_LOADER,
    SET_SCREENSHOT_SEND_DATA,
    SET_SCREEN_SHOT_LOADING,
    SET_TEST_FILTERED_CATEGORIES,
    SET_TIMELINE_CLICK_STATE,
    SET_TIMELINE_CURSOR_STATUS_ST,
    SET_TIMELINE_EVENT_DATA_ST,
    SET_TIMELINE_MOVE_COUNT_ST,
    SET_TIMELINE_MOVE_DATA_ST,
    SET_TIMELINE_MOVE_DATA_STATUS_ST,
    SET_TIMELINE_MOVE_POSITION,
    SET_TIMELINE_MOVE_STATUS,
    SET_TIMELINE_ZOOM_CHANGE,
    SET_TIMELINE_ZOOM_INDEX_ST,
    SET_TIMELINE_ZOOM_LEVEL,
    SET_TIMELINE_ZOOM_ST,
    SET_TOPIC_STATIC_TITLE_ST,
    SET_TIMELINE_HEIGHT,
    SET_SHOW_NOTIFICATION,
    ADD_MAP_STATE_DATA,
    SET_FIRST_TIMELINE_CURSOR_STATUS_ST,
    SET_READ_MORE_MODAL_STATE,
    SET_GALLERY_MODAL_STATE,
    SET_GALLERY_INDEX,
    SET_GALLERY_FULL_SCREEN,
    SET_ARTICLE_POSITION,
    SET_CLOSED_BLOCKS,
    SET_GALLERY_ARTICLE_ID,
    SET_COMMENT_TOOLBOX_MARKERS_STATE,
    SET_COMMENT_TOOLBOX_MARKERS_LOADING_STATE,
    SET_MAPBOX_DRAW_TOOLBOX_GEO_JSON_STATE,
    SET_RULER_POPUP_SHOW_ST,
    SET_IS_RULER_STATES_LOADED,
    SET_BASE_MAP_IS_LIGHT,
    SET_BASE_MAP_GROUP_ID_ST,
    SET_IS_ALL_TOOLBOX_STATES_LOADED,
    SET_SELECTED_TOOL_UNDO_REDO,
    SET_UNDO_REDO_DATA,
    SET_SCREENSHOT_LANGUAGE,
    SET_GALLERY_TILI_INDEX,
    SET_POLYGON_DRAW_TOOLBOX_LINES,
    SET_STICKY_NOTES_MARKERS_LOADING_STATE,
    SET_STICKY_NOTES_MARKERS_STATE,
    SET_TEXT_TOOLBOX_MARKERS_STATE,
    SET_TEXT_TOOLBOX_MARKERS_LOADING_STATE,
    SET_TEXT_TOOLBOX_CLICKED_STATE,
    SET_SHOW_ARTICLE_POPUP,
    SET_ACTIVE_SCREEN_SHOT,
    SET_IS_SCREEN_SHOT_MODE,
    SET_READ_MORE_MODAL_ACTIVE_STATE,
    SET_IS_FROM_READ_MORE,
    SET_ARTICLE_ID,
    SET_SCREEN_SHOT_GALLERY_INDEX,
    SET_SCREEN_SHOT_CURRENT_TILI_INDEX,
    SET_SCREEN_SHOT_FULL_SCREEN,
    SET_CHANGES_TIME_RANGE,
    RESET_ALL_STATE
} from "../constants";
import {getArticleFromAPI, setArticles} from "./articles";
import {setFilteredCategories, setFilteredCategoriesST} from "./categories";
import {setHints} from "./hints";
import {setMapRequirement} from "./mapRequirement";
import {
    changeMapRequirements,
    changeMapRequirementsScreenShot,
} from "./mapRequirements";
import {setMenuExpend} from "./menuExpend";
import {setQuizPopup} from "./quizPopup";

import {parse, stringify} from "flatted";
import {
    setCompassCoordinates,
    setCompassNewAngle,
    setCompassRotate,
} from "./compassStateAction";
import {changeMapStyles, setMapStylesDark} from "./mapStyles";
import {
    setEraserClickedState,
    setLineDrawToolboxState,
    setCommentToolboxState,
    setPainterStart,
    setRulerClickedState,
    setDrawerToolboxClickedState,
    setStickyNotesClickedState,
    setTextToolboxState,
    setPolygonDrawToolboxState, setShaperClickState,
} from "./painterStart";
import {setTimeLineExpend} from "./timeLineExpend";
import {setMapZoom} from "./zoom";
import {setSelectedLesson} from "./handleLesson";
import {setMenu} from "./menu";
import {arrayProxy} from "../../service/categories";
import {arrayProxy as LegendArrayProxy} from "../../service/legends";
import {setLampModalState, setLampModalStateIndex} from "./lamp";
import {setFilteredLegends} from "./legends";
import {getSelectedToolUndoRedo, getUndoRedoData} from "../selectors";
import {setShapeToolState} from "./shapeTool";
import {throttle} from "lodash/function";
import i18n from "i18next";

export const setScreenShotBaseMapIsLightST = (state) => {
    return {
        type: SET_BASE_MAP_IS_LIGHT,
        payload: state,
    };
};
export const setScreenShotBaseMapGroupId = (state) => {
    return {
        type: SET_BASE_MAP_GROUP_ID_ST,
        payload: state,
    };
}
export const setScreenShotButtonStateST = (state) => {
    return {
        type: SET_SCREENSHOT_BUTTON_STATE,
        payload: state,
    };
};

export const setMapStateData = (data) => ({
    type: SET_MAP_STATE_DATA,
    payload: data,
});
export const addMapStateData = (data) => ({
    type: ADD_MAP_STATE_DATA,
    payload: data,
});

export const setMapStateSingleData = (data) => ({
    type: SET_MAP_STATE_SINGLE_DATA,
    payload: data,
});

export const setOpenCategoryID = (id) => {
    return {
        type: SET_OPEN_CATEGORY_ID,
        payload: id,
    }
};

export const setOpenSubCategoryID = (id) => ({
    type: SET_OPEN_CATEGORY_ID,
    payload: id,
});

export const setMapRequiredScreenShot = (id) => ({
    type: SET_MAP_REQUIREMENT_SCREEN_SHOT,
    payload: id,
});


export const setCategoriesClickedArticleId = (id) => ({
    type: SET_CATEGORIES_ARTICLE_ITEM_ID,
    payload: id,
});

export const setLampDataFilteredScreenShot = (data) => {
    return {
        type: SET_LAMP_DATA_FILTERED_SCREEN_SHOT,
        payload: data,
    };
};

export const setLampSortedTooltipState = (data) => {
    return {
        type: SET_LAMP_SORTED_TOOLTIP_STATE,
        payload: data,
    };
};

export const setTestFilteredCategories = (data) => {
    return {
        type: SET_TEST_FILTERED_CATEGORIES,
        payload: data,
    };
};

export const setLampSelectedId = (data) => {
    return {
        type: SET_LAMP_SELECTED_ID,
        payload: data,
    };
};


export const setMapStylesIdST = (id) => {
    return {
        type: SET_MAP_STYLES_ID,
        payload: id,
    };
};
export const setMapStylesDarkId = (id) => {
    return {
        type: SET_MAP_STYLES_DARK_ID,
        payload: id,
    };
};

export const setPainterGeoJsonDataST = (data) => {
    return {
        type: SET_PAINTER_GEOJSON_DATA_ST,
        payload: data,
    };
};

export const setRulerGeoJsonDataST = (data) => {
    return {
        type: SET_RULER_GEOJSON_DATA_ST,
        payload: data,
    };
};

export const setCommentToolboxMarkersLoadingState = (data) => {
    return {
        type: SET_COMMENT_TOOLBOX_MARKERS_LOADING_STATE,
        payload: data,
    };
}
export const setStickyNotesMarkersLoadingState = (data) => {
    return {
        type: SET_STICKY_NOTES_MARKERS_LOADING_STATE,
        payload: data,
    };
}
export const setTextToolboxMarkersLoadingState = (data) => {
    return {
        type: SET_TEXT_TOOLBOX_MARKERS_LOADING_STATE,
        payload: data,
    };
}

export const setCommentToolboxMarkersState = (data) => {
    return {
        type: SET_COMMENT_TOOLBOX_MARKERS_STATE,
        payload: data,
    }
};
export const setStickyNotesMarkersState = (data) => {
    return {
        type: SET_STICKY_NOTES_MARKERS_STATE,
        payload: data,
    }
};

export const setTextToolboxMarkersState = (data) => {
    return {
        type: SET_TEXT_TOOLBOX_MARKERS_STATE,
        payload: data,
    }
};

export const setMapStyleStatus = (state) => {
    return {
        type: SET_MAP_STYLES_STATUS,
        payload: state,
    };
};

export const setTimeLineZoomST = (state) => {
    return {
        type: SET_TIMELINE_ZOOM_ST,
        payload: state,
    };
};

export const setTimeLineZoomIndexST = (state) => {
    return {
        type: SET_TIMELINE_ZOOM_INDEX_ST,
        payload: state,
    };
};

export const setTimeLineMoveCountST = (state) => {
    return {
        type: SET_TIMELINE_MOVE_COUNT_ST,
        payload: state,
    };
};

export const setTimeLineCursorStatusST = (state) => {
    return {
        type: SET_TIMELINE_CURSOR_STATUS_ST,
        payload: state,
    };
};
export const setFirstTimeLineCursorStatusST = (state) => {
    return {
        type: SET_FIRST_TIMELINE_CURSOR_STATUS_ST,
        payload: state,
    };
};

export const setTimeLineEventDataST = (data) => {
    return {
        type: SET_TIMELINE_EVENT_DATA_ST,
        payload: data,
    };
};

export const setActiveScreenShot = (data) => {
    return {
        type: SET_ACTIVE_SCREEN_SHOT,
        payload: data,
    };
};

export const setIsScreenShotMode = (data) => {
    return {
        type: SET_IS_SCREEN_SHOT_MODE,
        payload: data,
    };
};
export const setIsFromReadMore = (data) => {
    return {
        type: SET_IS_FROM_READ_MORE,
        payload: data,
    };
};

export const setTimeLienMovePos = (data) => {
    return {
        type: SET_TIMELINE_MOVE_POSITION,
        payload: data,
    };
};

export const setTimeLIneMoveStatus = (state) => {
    return {
        type: SET_TIMELINE_MOVE_STATUS,
        payload: state,
    };
};

export const setTimeLIneZoomLevelST = (state) => {
    return {
        type: SET_TIMELINE_ZOOM_LEVEL,
        payload: state,
    };
};

export const setScreenShotLoading = (state) => {
    return {
        type: SET_SCREEN_SHOT_LOADING,
        payload: state,
    };
};

export const setMapCenterPositionST = (coordinates) => {
    return {
        type: SET_MAP_CENTER_POSITION,
        payload: coordinates,
    };
};

export const setMapCenterPositionStatusST = (coordinates) => {
  return {
    type: SET_MAP_CENTER_POSITION_STATUS,
    payload: coordinates,
  }
}

export const setScreenShotSendDataST = (data) => {
    return {
        type: SET_SCREENSHOT_SEND_DATA,
        payload: data,
    };
};

export const setIsReadMoreState = (state) => {
    return {
        type: SET_READ_MORE_MODAL_STATE,
        payload: state,
    };
};
export const setIsReadMoreActiveState = (state) => {
    return {
        type: SET_READ_MORE_MODAL_ACTIVE_STATE,
        payload: state,
    };
};
export const setArticleId = (state) => {
    return {
        type: SET_ARTICLE_ID,
        payload: state,
    };
};
export const setScreenShotGalleryIndex = (state) => {
    return {
        type: SET_SCREEN_SHOT_GALLERY_INDEX,
        payload: state,
    };
};
export const setScreenShotCurrentTiliIndex = (state) => {
    return {
        type: SET_SCREEN_SHOT_CURRENT_TILI_INDEX,
        payload: state,
    };
};
export const setScreenShotFullScreen = (state) => {
    return {
        type: SET_SCREEN_SHOT_FULL_SCREEN,
        payload: state,
    };
};

export const setShowArticlePopup = (state) => {
    return {
        type: SET_SHOW_ARTICLE_POPUP,
        payload: state,
    }
}
export const setIsGalleryState = (state) => {
    return {
        type: SET_GALLERY_MODAL_STATE,
        payload: state,
    };
};
export const setGalleryIndex = (state) => {
    return {
        type: SET_GALLERY_INDEX,
        payload: state,
    };
};
export const setGalleryTiliIndex = (state) => {
    return {
        type: SET_GALLERY_TILI_INDEX,
        payload: state,
    };
};
export const setGalleryFullScreen = (state) => {
    return {
        type: SET_GALLERY_FULL_SCREEN,
        payload: state,
    };
};
export const setGalleryArticleId = (state) => {
    return {
        type: SET_GALLERY_ARTICLE_ID,
        payload: state,
    };
};
export const setArticlePosition = (state) => {
    return {
        type: SET_ARTICLE_POSITION,
        payload: state,
    };
};
export const setClosedBlocks = (state) => {
    return {
        type: SET_CLOSED_BLOCKS,
        payload: state,
    };
};
export const setScreenShotLanguage = (data) => ({
    type: SET_SCREENSHOT_LANGUAGE,
    payload: data,
})

export const setScreenShotIntermediateDataST = (data) => {
    return {
        type: SET_SCREENSHOT_INTERMEDIATE_DATA,
        payload: data,
    };
};

export const setScreenShotButtonClickST = (data) => {
    return {
        type: SET_SCREENSHOT_BUTTON_CLICK,
        payload: data,
    };
};
export const setMapMarkerInfoST = (markerInfo) => {
    return {
        type: SET_MAP_MARKER_INFO,
        payload: markerInfo,
    };
};

export const setChangesTimeRange = (data) => {
    return {
        type: SET_CHANGES_TIME_RANGE,
        payload: data,
    };
}
export const setScreenShotLoader = (state) => {
    return {
        type: SET_SCREENSHOT_LOADER,
        payload: state,
    };
};





export const setRemoveCardId = (id) => {
    return {
        type: SET_REMOVE_CARD_ID,
        payload: id,
    };
};

// export const setTimeLineZoomChange = (data) => {
//   return {
//     type: SET_TIMELINE_ZOOM_CHANGE,
//     payload: data,
//   };
// };

export const setTimeLineMoveDataST = (state) => {
    return {
        type: SET_TIMELINE_MOVE_DATA_ST,
        payload: state,
    };
};

export const setTimeLineMoveDataStatusST = (state) => {
    return {
        type: SET_TIMELINE_MOVE_DATA_STATUS_ST,
        payload: state,
    };
};

export const setTopicStaticTitleST = (state) => {
    return {
        type: SET_TOPIC_STATIC_TITLE_ST,
        payload: state,
    };
};

export const setTimeLIneClickState = (state) => {
    return {
        type: SET_TIMELINE_CLICK_STATE,
        payload: state,
    };
};

export const setRulerPopupShowST = (state) => ({
    type: SET_RULER_POPUP_SHOW_ST,
    payload: state,
})

export const setIsAllToolboxStatesLoaded = (state) => ({
    type: SET_IS_ALL_TOOLBOX_STATES_LOADED,
    payload: state,
})

export const setSelectedToolUndoRedo = (state) => ({
    type: SET_SELECTED_TOOL_UNDO_REDO,
    payload: state,
})

export const setUndoRedoData = (state) => ({
    type: SET_UNDO_REDO_DATA,
    payload: state,
})

export const setMobileMenuClose = (state) => {
    return {
        type: SET_MOBILE_MENU_CLOSE,
        payload: state,
    };
};

export const setTimeLineHeightST = (state) => {
    return {
        type: SET_TIMELINE_HEIGHT,
        payload: state,
    };
}

export const setShowNotificationST = (state) => {
    return {
        type: SET_SHOW_NOTIFICATION,
        payload: state,
    };
}

export const setMapboxDrawToolboxGeoJSONState = (state) => {
    return {
        type: SET_MAPBOX_DRAW_TOOLBOX_GEO_JSON_STATE,
        payload: state,
    };
}

export const resetAllState = () => {
    return {
        type: RESET_ALL_STATE,
    };
}

export const setPolygonDrawToolboxPolygonsState = (state) => {
    return {
        type: SET_POLYGON_DRAW_TOOLBOX_LINES,
        payload: state,
    };
}

export const dispatchScreenShotLoader = (bool) => (dispatch) => {
    dispatch(setScreenShotLoading(bool))
}
export const dispatchMapCenterPositionStatusST = (bool) => (dispatch) => {
    dispatch(setMapCenterPositionStatusST(bool))
}
export const dispatchMapMarkerInfo = (markerInfo) => (dispatch) => {
    dispatch(setMapMarkerInfoST(markerInfo))
}
export const dispatchChangesTimeRange = (data) => (dispatch) => {
    dispatch(setChangesTimeRange(data))
}
export const dispatchUndoRedoData = (newData) => (dispatch, getState) => {
    const state = getState();
    const undoRedoData = getUndoRedoData(state);
    const selectedToolUndoRedo = getSelectedToolUndoRedo(state);
    const currentComponent = undoRedoData[selectedToolUndoRedo];
    let newHistory = [];
    // Truncate history if new data is added after undo
    if (currentComponent.index === currentComponent.history.length - 1) {
        newHistory = currentComponent.history.slice(0, currentComponent.index + 1);
        newHistory.push(newData);
    } else {
        for (let i = 0; i <= currentComponent.index; i++) {
            newHistory.push(currentComponent.history[i]);
        }
        newHistory.push(newData);
    }

    if (newHistory.length > 5) {
        newHistory.shift();
    }

    const updatedComponent = {
        history: newHistory,
        index: newHistory.length - 1,
    };

    const newUndoRedoData = {
        ...undoRedoData,
        [selectedToolUndoRedo]: updatedComponent,
    };

    dispatch(setUndoRedoData(newUndoRedoData));
};


export const getScreenShotDataFromAPI =
    (page, id, onClose, state = true, search, perPage, type = 'new') =>
        (dispatch) => {
            state && dispatch(setScreenShotLoader(false));
            const url = `${process.env.REACT_APP_IDENTITY_URL}/api/MapState/DataProvider`;
            const body = {
                page: page,
                perPage: perPage || 10,
                sort: [],
                columnFilters: {},
                userIds: [id],
                ...search
            };

            getScreenShotData(url, body)
                .then((data) => {
                    if (type === 'add' && page > 1) {
                        dispatch(addMapStateData(data));
                    } else {
                        dispatch(setMapStateData(data));
                    }
                    onClose?.();
                })
                .catch((err) => console.log("err", err))
                .finally(() => state && dispatch(setScreenShotLoader(true)));
        };
function updateChildFieldById(obj,  data, fieldToUpdate,revers) {
    // Base case: if the object has an id and matches the provided id, update the field
    if (obj.id && data[obj.id]) {
        if(Array.isArray(fieldToUpdate)){
            fieldToUpdate.forEach((el)=>{
                const bool = revers ? !obj[el] : !!obj[el]
                data[obj.id][el] = bool;
                obj[el] = bool;
            })
        }else{
            const bool = revers ? !obj[fieldToUpdate] : obj[fieldToUpdate]

            obj[fieldToUpdate] = bool;
            data[obj.id][fieldToUpdate] = bool;
        }
    }

    // Recursive case: if the object has children, recursively call the function on each child
    if (obj.categories && obj.categories.length > 0) {
        for (var i = 0; i < obj.categories.length; i++) {
            updateChildFieldById(obj.categories[i], data, fieldToUpdate,false);
        }
    }else if (obj.subCategories && obj.subCategories.length > 0) {
        for (var i = 0; i < obj.subCategories.length; i++) {
            updateChildFieldById(obj.subCategories[i], data, fieldToUpdate,false);
        }
    }else if (obj.articles && obj.articles.length > 0) {
        for (var i = 0; i < obj.articles.length; i++) {
            updateChildFieldById(obj.articles[i], data, fieldToUpdate,true);
        }
    }
}
export const setFirstTimeLineCursorStatusSTData = () => (dispatch) => {
    dispatch(
        setFirstTimeLineCursorStatusST(true)
    )
}
const debounce = (fn, wait) => {
    let timeoutID;
    // call as many times as you wish!
    // I'll do my job after `wait` miliseconds
    return (...arg) => {
        if (timeoutID) clearTimeout(timeoutID);
        timeoutID = setTimeout(() => fn(...arg), wait);
    };
};
const debeounced400 = debounce((fn) => fn(), 400);
export const getScreenShotSingleDataAPI = (id,type) => async  (dispatch,getState) => {
    const states = getState();

    const url = `${process.env.REACT_APP_IDENTITY_URL}/api/${type}/${id}`;
    let nextArg = null
    return {
        call: async () => {
            return await getScreenShotSingleData(url)
                .then((data) => {
                    nextArg = data
                    return data
                })
                .catch((err) => console.log("err-- in call", err));
        },
        serialized: (data) => {
            return Promise.resolve()
                .then(() => {
                    if(data?.state) data.data = data?.state

                    // console.log("data.state", data.data);
                    if (Object.keys(data).length > 0) {
                        const info = parse(data.data);
                        nextArg = {data,info}
                        console.log("info", info, {data,info});
                        return {data,info}
                    }
                    throw new Error('Data not found')
                    alert("Պահպանված քարտեզ գոյություն չունի");
                    window.close();
                    return false
                })
                .catch((err) => console.log("err-- in serialized", err));
        },
        setLanguage: (params) => {
            const {data,info} = params || {data:null,info:null}
            if(!data) return
            return Promise.resolve()
                .then(() => {
                    if (info.languageId) {
                        dispatch(setScreenShotLanguage(info.languageId || 1));
                        i18n.changeLanguage((info.languageId || 1) === 1 ? 'hy' : 'en');
                    }
                    nextArg = {data,info}
                    return {data,info}
                })
                .catch((err) => console.log("err-- in setLanguage", err));
        },
        setLocation: (params) => {
            const {data,info} = params || {data:'',info:''}
            return Promise.resolve()
                .then(() => {
                    // TODO OPTIMISATION THIS FUNCTIONAL
                    const url  = new URLSearchParams(info?.url?.split('?')[1])
                    const liveLocation  = new URLSearchParams(window.location.search)

                    const typeContent = url.get('type')
                    const contentID = url.get(`${typeContent}ID`)
                    const userID = url.get(`userId`)

                    const liveTypeContent = liveLocation.get('type')
                    const liveContentID = liveLocation.get(`${typeContent}ID`)
                    const liveUserID = liveLocation.get(`userId`)
                    //
                    // if(userID && userID !== liveUserID ){
                    //     liveLocation.set("userId", `${userID}`);
                    //     var currentQueryString = window.location.pathname;
                    //     var newQueryString = currentQueryString + liveLocation;
                    //     window.history.pushState({}, '', '?' + newQueryString);
                    //
                    // }
                    window.isScreenShotArticleMode = !!data?.articleId;
                    if(typeContent && (typeContent !== liveTypeContent || contentID !== liveContentID)){
                        liveLocation.delete(`${liveTypeContent}ID`);
                        liveLocation.set("type", `${typeContent}`);
                        liveLocation.set(`${typeContent}ID`, contentID);
                        if(info?.articleMode === 'true') liveLocation.set(`articleMode`, 'true');
                        window.location.search = liveLocation.toString();
                    }
                    // END OPTIM
                    nextArg = {data,info}
                    return {data,info}
                })
                .catch((err) => console.log("err-- in setLanguage", err));
        },
        setData: ({data,info}) => {
            return Promise.resolve()
                .then(() => {
                    const newData = {...data, id: data?.mapStateId || data?.id};
                    console.log("DATA, info, data ",info, data);

                    dispatch(setMapStateSingleData(newData));
                    dispatch(setTopicStaticTitleST(newData.title));
                    dispatch(setScreenShotSendDataST(stringify(newData)));
                    if(info?.getMapStyledId) {
                        info.baseMapGroupIdST = info.getMapStyledId
                    }
                    info.baseMapGroupIdST && dispatch(setScreenShotBaseMapGroupId(info.baseMapGroupIdST));
                    info.baseMapIsLight && dispatch(setScreenShotBaseMapIsLightST(info.baseMapIsLight));
                    info.baseMapGroupIdST && dispatch(changeMapStyles(info.baseMapGroupIdST,info.baseMapIsLight));
                    info.baseMapGroupIdST && dispatch(setMapStyleStatus(info.baseMapGroupIdST));
                    // info.getMapStyledId && dispatch(setMapStylesDark(info.getMapStyledId));
                    info.baseMapGroupIdST && dispatch(setMapStylesIdST(info.baseMapGroupIdST));
                    // info.getMapStyledDarkId && dispatch(setMapStylesDark(info.getMapStyledDarkId));
                    info.getMapStyledDarkId && dispatch(setMapStylesDarkId(info.getMapStyledDarkId));
                    info.selectedLesson && dispatch(setSelectedLesson(info.selectedLesson));
                    // dispatch(setScreenShotSendDataST(stringify(data)));
                    const leftBarCatOpenSTF = (subjectSettings) =>{
                        const newPromise = delay(100)
                        return newPromise.then(() => {
                          // info.categoryOpenID &&
                          dispatch(
                            setOpenCategoryID(info.categoryOpenID || null),
                          )
                          return delay(100)
                        })
                    }
                    const leftBarSTF = (subjectSettings) =>{
                        const newPromise = delay(100)
                        return newPromise
                            .then(()=>{
                                dispatch(setMenuExpend(false));

                                return delay(300)
                            })
                            .then(()=>{
                                dispatch(setIsReadMoreState(info.isReadMore));
                                dispatch(setArticlePosition(info.articlePosition));
                                dispatch(setIsGalleryState(info.isGalleryMode));
                                dispatch(setGalleryTiliIndex(info.galleryTiliIndex));
                                dispatch(setGalleryArticleId(info.articleId));
                                // info.menuExpend &&
                                dispatch(setMenuExpend(info.menuExpend || false));
                                info.hints &&
                                dispatch(setHints(info.hints));
                                info.getLampSelectedId &&
                                dispatch(setLampSelectedId(info.getLampSelectedId ));
                                info.getLampModalState &&
                                dispatch(setLampModalState(info.getLampModalState));
                                info.lampDataFiltered.length > 0 &&
                                dispatch(
                                    setLampDataFilteredScreenShot(Object.fromEntries(new Map(info.lampDataFiltered.filter(el=>el.isHide).map((item) => [item.id, item]))))
                                );
                                dispatch(setLampModalStateIndex(info.getLampModalStateIndex || 0));
                                info.getCategoryArticleClickedId &&
                                dispatch(getArticleFromAPI(info.getCategoryArticleClickedId,true));
                                // info.articleData.length > 0 &&
                                dispatch(setMapMarkerInfoST( info.markerInfo && Object.keys(info.markerInfo).length  ? {
                                    ...info.markerInfo,
                                    flyTo:false
                                } : {}))
                                // dispatch(setArticles(info.articleData || []));
                                if(!!info.articleData?.length || info.selectedArticleId){
                                    getArticleFromAPI(info.articleData?.[0]?.id || info.selectedArticleId, true)(dispatch, getState)
                                }
                                // if((info.isReadMore || info.isGalleryMode) && !info.articleData?.length && info.markerInfo && Object.keys(info.markerInfo)?.length){
                                //     dispatch(getArticleFromAPI(info.markerInfo.id));
                                // }

                                // info.getMapSelectedCluster && Object.keys(info.getMapSelectedCluster).length && dispatch(setMapSelectedCluster({
                                //     ...info.getMapSelectedCluster,
                                //     forScreenShoot: true
                                // }));
                                // info.articleData.length > 0 &&
                                // startPinAnimation(`article_${info.articleData[0].id}`);

                                info.quizPopup && dispatch(setQuizPopup(info.quizPopup));

                                if(!info.filteredCategories && Object.keys(info?.filteredCategoriesProxyData)?.length > 0) {
                                    getState().filteredCategories.data.updateDataStates(info?.filteredCategoriesProxyData)
                                    dispatch(setFilteredCategoriesST(getState().filteredCategories.data.getTarget()));
                                }
                                // support old screens
                                if(info.filteredCategories?.length > 0)
                                {
                                    const filteredCategories = structuredClone(info.filteredCategories)
                                    const object = structuredClone(info.filteredCategoriesProxyData)
                                    const newIndex = {}
                                    Object.keys(object).forEach(el=> {
                                        if (typeof object[el] == 'object'){
                                            newIndex[el] = {
                                                id: object[el].id,
                                                key: object[el].key,
                                                show: object[el].show,
                                                showInTimeLine: object[el].showInTimeLine,
                                            }
                                        }
                                    })
                                    updateChildFieldById({categories:filteredCategories},newIndex,['show','showInTimeLine'])
                                    const  newProxy = new arrayProxy(filteredCategories)
                                    newProxy.updateIndex(newIndex)
                                    const dataStates = newProxy.getDatagetState()
                                    getState().filteredCategories.data.updateDataStates(dataStates)
                                    dispatch(setFilteredCategoriesST(getState().filteredCategories.data.getTarget()));
                                }

                                if (!info.filteredLegends && Object.keys(info?.filteredLegendsProxyData)?.length > 0) {
                                    getState().legends.filteredData.updateDataStates(info?.filteredLegendsProxyData)
                                }

                                // support old screens
                                if (info.filteredLegends?.length > 0) {
                                    const newProxy = new LegendArrayProxy(info.filteredLegends)
                                    newProxy.updateIndex(info?.filteredLegendsProxyData)
                                    getState().legends.filteredData.updateDataStates(newProxy.getDatagetState())
                                    // dispatch(setFilteredLegends(getState().legends.filteredData.getTarget()));
                                }

                                info.mapRequirement &&
                                dispatch(setMapRequirement(info.mapRequirement));
                                info.mapRequirements?.length > 0 &&
                                dispatch(changeMapRequirementsScreenShot(info.mapRequirements));
                                info.mapRequirements?.length > 0 &&
                                dispatch(
                                    changeMapRequirements(
                                        info.mapRequirements.find((item) => item.active).value
                                    )
                                );

                                return delay(500)
                            })
                            .then(()=> {
                                dispatch(setGalleryIndex(info.galleryIndex));
                                return delay(100)
                            })
                            .then(()=> {
                                dispatch(setGalleryFullScreen(info.galleryFullScreen));
                                return delay(100)
                            })

                    }
                    const compassSTF = (subjectSettings) =>{
                        const newPromise = delay(0)
                        return newPromise
                            .then(()=> {
                                dispatch(setCompassNewAngle(info.getNewAngle || 0));
                                Object.keys(info?.getCompassCoordinates || {})?.length &&
                                dispatch(setCompassCoordinates(info?.getCompassCoordinates));
                                return delay(500)
                            })
                            .then(()=> {
                                info.getNewRotate &&
                                dispatch(setCompassRotate(info.getNewRotate));
                                return delay(0)
                            })
                    }
                    const toolBarSTF = (subjectSettings) =>{
                        const searchParams = new URLSearchParams(window.location.search);

                        const mapStateId = searchParams.get('screenShotId');

                        dispatch(setIsAllToolboxStatesLoaded(false));
                        dispatch(setEraserClickedState(false));
                        dispatch(setDrawerToolboxClickedState(false));
                        dispatch(setStickyNotesClickedState(false));
                        dispatch(setLineDrawToolboxState(false));
                        dispatch(setPolygonDrawToolboxState(false));
                        dispatch(setCommentToolboxState(false));
                        dispatch(setRulerClickedState(false));
                        dispatch(setTextToolboxState(false));

                        let {ruler, drawer, commentBox, lineDraw, polygonDraw, stickyNotes, eraser, textBox, shapeTool} = subjectSettings

                        if (process.env.REACT_APP_ENV === 'production' ) {
                            textBox = null;
                        }

                        if (mapStateId && data.mapStateId && +data.mapStateId !== +mapStateId) {
                            return;
                        }

                        const newPromise = delay(0)
                        return newPromise
                            .then(()=> {
                                if(ruler || drawer || commentBox|| lineDraw || eraser || stickyNotes || textBox){
                                    const isOpenPainterStart =  !!((info.getDrawerToolboxClickedState || info.getRulerClickedState || info.getCommentToolboxState || info.getStickyNotesClickedState || info.getLineDrawToolboxState || info.getEraserClickedState) && info.painterStart)
                                    dispatch(setPainterStart(isOpenPainterStart));
                                }
                                if(ruler) {
                                    info.getRulerPopupShowST &&
                                    dispatch(setRulerPopupShowST(info.getRulerPopupShowST));
                                }
                                return ruler || drawer ? delay(100) : delay(0)
                            })
                            .then(() => {
                                if(eraser) {
                                    dispatch(setEraserClickedState(!!info.getEraserClickedState));
                                }
                                return eraser ? delay(100) : delay(0);
                            })
                            .then(() => {
                                const searchParams = new URLSearchParams(window.location.search);

                                const screenSlideId = searchParams.get('screenSlide');
                                // const screenShot = searchParams.get('screenShot');
                                // const presentation = searchParams.get('presentation');
                                //!!shapeTool
                                if(shapeTool && ((String(id) === screenSlideId) || !screenSlideId) ) {
                                    info.shapeToolState && dispatch(setShapeToolState(info.shapeToolState));
                                    info.shapeToolState && dispatch(setShaperClickState(info.shapeToolState.shapeModeIsActive));
                                }
                                return shapeTool ? delay(100) : delay(0);
                            })
                            .then(()=> {
                                if(drawer){
                                    // OLD SCREENSHOTS FOR DRAWER SUPPORT FROM FRONT, WHEN BACK READY, DELETE IT
                                    (info.getDrawerToolboxClickedState || info.getPainterClickedState)
                                    && dispatch(setDrawerToolboxClickedState((info.getDrawerToolboxClickedState || info.getPainterClickedState)));

                                    // console.error("info.getPainterGeoJsonDataST", info.getPainterGeoJsonDataST);

                                    if (Object.keys(info.getPainterGeoJsonDataST).length > 0 && info.getPainterGeoJsonDataST.features.length && info.getPainterGeoJsonDataST.features[0].id && !uuidValidate(info.getPainterGeoJsonDataST.features[0].id)) {
                                        info.getPainterGeoJsonDataST.features = info.getPainterGeoJsonDataST.features.map(feature => {
                                            const id = uuidv4();
                                            feature.id = id;
                                            feature.geometry.coordinates = feature.geometry.coordinates.map(coords => [+coords[0], +coords[1]]);
                                            feature.properties = {
                                                ...feature.properties, id
                                            }
                                            return feature;
                                        })
                                    }

                                    (Object.keys(info.getPainterGeoJsonDataST).length > 0) && dispatch(setPainterGeoJsonDataST({
                                        ...info.getPainterGeoJsonDataST
                                    }));

                                }

                                return drawer ? delay(700)  : delay(0)
                            })
                            .then(()=> {
                                if(ruler && info.getRulerGeoJsonDataST && info.getRulerGeoJsonDataST.features?.length) {
                                    dispatch(setPainterStart(true));
                                    dispatch(setRulerClickedState(true));
                                    dispatch(setRulerGeoJsonDataST(info.getRulerGeoJsonDataST));
                                    dispatch(setRulerPopupShowST(true));
                                } else {
                                    dispatch(setRulerClickedState(false));
                                }
                                return ruler ? delay(500) : delay(0)
                            })
                            .then(()=> {
                                if((lineDraw || polygonDraw) && info.getMapboxDrawToolboxGeoJSONState) {
                                    const copyInfo = JSON.parse(JSON.stringify(info.getMapboxDrawToolboxGeoJSONState));
                                    if(lineDraw && polygonDraw) {
                                        dispatch(setMapboxDrawToolboxGeoJSONState(copyInfo.features && copyInfo.features.length ? copyInfo : {
                                            type: 'FeatureCollection',
                                            features: []
                                        }));

                                        return delay(100);
                                    }

                                    if(lineDraw && !polygonDraw && copyInfo.features && copyInfo.features.length) {
                                        copyInfo.features = copyInfo.features.filter(feature =>
                                            feature.geometry.type === 'LineString'
                                        );

                                        dispatch(setMapboxDrawToolboxGeoJSONState(copyInfo.features && copyInfo.features.length ? copyInfo : {
                                            type: 'FeatureCollection',
                                            features: []
                                        }));
                                    }

                                    else if(polygonDraw && !lineDraw && copyInfo.features && copyInfo.features.length) {
                                        copyInfo.features = copyInfo.features.filter(feature =>
                                            feature.geometry.type === 'Polygon'
                                        );

                                        dispatch(setMapboxDrawToolboxGeoJSONState( copyInfo.features && copyInfo.features.length ? copyInfo : {
                                            type: 'FeatureCollection',
                                            features: []
                                        }));
                                    }
                                    return delay(100);
                                } else {
                                    return delay(0);
                                }
                            })
                            .then(() => {
                                if(stickyNotes) {
                                    info.getStickyNotesMarkersState && dispatch(setStickyNotesMarkersState(info.getStickyNotesMarkersState));
                                } else {
                                    dispatch(setStickyNotesMarkersState([]));
                                }

                                return stickyNotes ? delay(100) : delay(0)
                            })
                            .then(() => {
                                if(textBox) {
                                    info.getTextToolboxMarkersState && dispatch(setTextToolboxMarkersState(info.getTextToolboxMarkersState));
                                } else {
                                    dispatch(setTextToolboxMarkersState([]));
                                }

                                dispatch(setTextToolboxMarkersLoadingState(false));
                                dispatch(setIsAllToolboxStatesLoaded(true));

                                return textBox ? delay(100) : delay(0)
                            })
                            .then(() => {
                                if(commentBox) {
                                    info.getCommentToolboxMarkersState && dispatch(setCommentToolboxMarkersState(info.getCommentToolboxMarkersState));
                                } else {
                                    dispatch(setCommentToolboxMarkersState([]));
                                }

                                dispatch(setCommentToolboxMarkersLoadingState(false));
                                dispatch(setIsAllToolboxStatesLoaded(true));

                                return commentBox ? delay(100) : delay(0)
                            })
                    }
                    const timelineSTF = (subjectSettings) =>{
                        const newPromise = delay(100)
                        return newPromise
                            .then(()=> {
                                info.getTimeLineHeightST && dispatch(setTimeLineHeightST(info.getTimeLineHeightST));
                                info.getTimeLineZoomST &&
                                dispatch(setTimeLineZoomST(info.getTimeLineZoomST));
                                Object.keys(info?.getTimeLineMovieDataST || {}).length &&
                                dispatch(setTimeLineMoveDataStatusST(true));
                                Object.keys(info?.getTimeLineMovieDataST || {}).length &&
                                dispatch(setTimeLineMoveDataST(info.getTimeLineMovieDataST));
                                Object.keys(info?.getTimeLineMovePositionST || {}).length > 0 &&
                                dispatch(setTimeLIneMoveStatus(true));
                                Object.keys(info?.getTimeLineMovePositionST || {}).length > 0 &&
                                dispatch(
                                    setTimeLienMovePos({
                                        start: info.getTimeLineMovePositionST.start,
                                        end: info.getTimeLineMovePositionST.end,
                                    })
                                );
                                info.getTimeLineMoveCountST &&
                                dispatch(setTimeLineMoveCountST(info.getTimeLineMoveCountST));

                                return delay(300)
                            })
                            .then(()=> {
                                info.getTimeLineCursorStatusST &&
                                dispatch(
                                    setTimeLineCursorStatusST(info.getTimeLineCursorStatusST)
                                );
                                dispatch(
                                    setFirstTimeLineCursorStatusST(true)
                                )
                                Object.keys(info.getTimeLineEventDataST || {}).length > 0 &&
                                dispatch(setTimeLineEventDataST(info.getTimeLineEventDataST));
                                return delay(500)
                            })
                            .then(()=> {
                                info.timelineExtend &&
                                dispatch(setTimeLineExpend(info.timelineExtend));
                                return delay(100)
                            })

                    }
                    const mapSTF = (subjectSettings) =>{
                        const newPromise = delay(700)
                        return newPromise
                            .then(()=> {
                                Object.keys(info.getMapCenterCoordinatesST || {}).length > 0 &&
                                dispatch(setMapCenterPositionStatusST(true));
                                Object.keys(info.getMapCenterCoordinatesST || {}).length > 0 &&
                                dispatch(
                                    setMapCenterPositionST(info.getMapCenterCoordinatesST)
                                );
                                info.getZoomDataST && dispatch(setMapZoom(info.getZoomDataST));
                                return delay(700)
                            })

                    }
                    const checkSettingsSTF = (subjectSettings) =>{
                        const {ruler,drawer,mapTypes, commentBox, lineDraw, polygonDraw, stickyNotes, textBox, shapeTool} = subjectSettings
                        const newPromise = delay(0)
                        return newPromise
                            .then(()=> {
                                dispatch(setShowNotificationST(2))
                                return delay(300)
                            })
                            .then(()=> {
                                if(
                                    ((info.getPainterGeoJsonDataST && info.getPainterGeoJsonDataST?.features?.length > 0 ) && !drawer)
                                    || (info.getRulerClickedState && !ruler)
                                    || (info.shapeToolState && info.shapeToolState?.shapes?.length && !shapeTool)
                                    || ((info.getCommentToolboxMarkersState && info.getCommentToolboxMarkersState?.length > 0) && !commentBox)
                                    || ((info.getStickyNotesMarkersState && info.getStickyNotesMarkersState?.length > 0) && !stickyNotes)
                                    || ((info.getTextToolboxMarkersState && info.getTextToolboxMarkersState?.length > 0) && !textBox)
                                    || ((info.getLineDrawToolboxState || (info.getMapboxDrawToolboxGeoJSONState && Object.keys(info.getMapboxDrawToolboxGeoJSONState)?.length && info.getMapboxDrawToolboxGeoJSONState.features.length) > 0) && !lineDraw)
                                    || ((info.getPolygonDrawToolboxState || (info.getMapboxDrawToolboxGeoJSONState && Object.keys(info.getMapboxDrawToolboxGeoJSONState)?.length && info.getMapboxDrawToolboxGeoJSONState.features.length) > 0) && !polygonDraw)
                                    || (info.baseMapGroupIdST && !mapTypes.find((el)=>el.mapTypeId === info.baseMapGroupIdST))
                                ) {
                                    if(info?.updateSeenDate < subjectSettings.modifiedAt || !info?.updateSeenDate){
                                        info.updateSeenDate = subjectSettings.modifiedAt
                                        const url = `${process.env.REACT_APP_IDENTITY_URL}/api/MapState/${data.id}`;
                                        const body = {
                                            title:info.topicStaticTitle,
                                            data: stringify(info),
                                        };
                                        editScreenShot(url, body).then((data) => {
                                            dispatch(setScreenShotButtonStateST(false));
                                        });
                                        return dispatch(setShowNotificationST(8))
                                    }else{
                                        return dispatch(setShowNotificationST(4))
                                    }
                                }
                                return delay(100)
                            })
                    }
                    const loadDoneSTF = (subjectSettings) =>{
                        const newPromise = delay(0)
                        return newPromise
                            .then(()=> {
                                dispatch(setScreenShotLoading(false));
                                return delay(100)
                            })
                    }
                    const loadBaseMap = (subjectSettings) =>{
                        const newPromise = delay(0)
                        return newPromise
                            .then(()=> {
                                if(info?.getMapStyledId) {
                                    info.baseMapGroupIdST = info.getMapStyledId
                                }
                                if(!info.baseMapGroupIdST) {
                                    return dispatch(changeMapStyles(subjectSettings.mapTypes.find(el => el.isMain)?.mapTypeId || 1))
                                }
                                if(!subjectSettings.mapTypes.find(el=>el.mapTypeId === info.baseMapGroupIdST)){
                                    info.baseMapGroupIdST = subjectSettings.mapTypes.find(el=>el.isMain)?.mapTypeId || 1
                                }
                                // NEW
                                info.baseMapGroupIdST && dispatch(setScreenShotBaseMapGroupId(info.baseMapGroupIdST));
                                info.baseMapIsLight && dispatch(setScreenShotBaseMapIsLightST(info.baseMapIsLight));
                                // END NEW
                                info.baseMapGroupIdST && dispatch(changeMapStyles(info.baseMapGroupIdST,info.baseMapIsLight));
                                info.baseMapGroupIdST && dispatch(setMapStyleStatus(info.baseMapGroupIdST));
                                info.baseMapGroupIdST && dispatch(setMapStylesIdST(info.baseMapGroupIdST));
                                // info.getMapStyledDarkId && dispatch(setMapStylesDark(info.getMapStyledDarkId));
                                info.getMapStyledDarkId && dispatch(setMapStylesDarkId(info.getMapStyledDarkId));
                                return delay(300)
                            })

                    }

                    const articleModeSTF = (subjectSettings) =>{
                        const newPromise = delay(200)
                        return newPromise
                            .then(()=> {
                                // TODO move to separate action
                                if (data?.articleId) {
                                    const categories = getState().categories?.data;

                                    dispatch(getArticleFromAPI(data?.articleId, false, ({articleData}) => {

                                        const subCategoryId = articleData?.subCategory?.find?.(
                                            (item) => item.isMain,
                                        )?.subCategoryId

                                        // let icon
                                        let color

                                        categories?.forEach((item) => {
                                            item.subCategories?.forEach?.((subCategory) => {
                                                if (subCategory?.id === subCategoryId) {
                                                    // icon = subCategory?.spriteIcon
                                                    color = subCategory?.color
                                                }
                                            })
                                        })
                                        console.log('articleData ---- ',articleData)

                                        if (articleData.cordinates?.[0]?.cordinate[0]) {
                                            dispatch(setMapMarkerInfoST({
                                                id:articleData.id,
                                                cordinates:[articleData.cordinates?.[0]?.cordinate[0]?.longitude, articleData.cordinates?.[0]?.cordinate[0]?.latitude],
                                                pointCount:1,
                                                catColor:color,
                                                flyTo:true
                                            }))
                                        }
                                    }))
                                }
                                return delay(300)
                            })
                    }

                    const microArticleSTF = () =>{
                        const newPromise = delay(200)
                        return;
                        return newPromise
                            .then(()=> {
                                // TODO move to separate action
                                if (data?.articleId) {
                                    const categories = getState().categories?.data;

                                    dispatch(getArticleFromAPI(data?.articleId, false, ({articleData}) => {

                                        const subCategoryId = articleData?.subCategory?.find?.(
                                            (item) => item.isMain,
                                        )?.subCategoryId

                                        // let icon
                                        let color

                                        categories?.forEach((item) => {
                                            item.subCategories?.forEach?.((subCategory) => {
                                                if (subCategory?.id === subCategoryId) {
                                                    // icon = subCategory?.spriteIcon
                                                    color = subCategory?.color
                                                }
                                            })
                                        })

                                        dispatch(setMapMarkerInfoST({
                                            id:articleData.id,
                                            cordinates:[articleData.cordinates?.[0]?.cordinate[0]?.longitude, articleData.cordinates?.[0]?.cordinate[0]?.latitude],
                                            pointCount:1,
                                            catColor:color,
                                            flyTo:true
                                        }))
                                    }))
                                }
                                return delay(300)
                            })
                    }
                    const searchParams = new URLSearchParams(window.location.search);

                    const isArticle = searchParams.get('articleMode');
                    const isMicroArticle = searchParams.get('microArticleMode');

                    let statesFunc = {
                        leftBarSTF,
                        compassSTF,
                        toolBarSTF,
                        timelineSTF,
                        mapSTF,
                        loadDoneSTF,
                        leftBarCatOpenSTF,
                        checkSettingsSTF,
                        loadBaseMap,
                        articleModeSTF
                    };

                    if (isArticle) {
                        statesFunc = {
                            articleModeSTF,
                            loadDoneSTF
                        };
                    }

                    if (isMicroArticle) {
                        statesFunc = {
                            microArticleSTF,
                            loadDoneSTF
                        };
                    }
                    Object.keys(statesFunc).forEach(key => {
                        statesFunc[key] = throttle(statesFunc[key], 1000, { leading: true, trailing: false });
                    });

                    const runScreenShotStates = (type) => {
                        const subjectSettings = getState()?.subjects?.settings;

                        if (!Array.isArray(type)) return statesFunc[type](subjectSettings);

                        const [first, ...rest] = type;
                        return statesFunc[first]?.(subjectSettings)?.finally(() => {
                                if (!rest.length) {
                                    return;
                                }
                                return runScreenShotStates(rest);
                            });
                    };

                    nextArg = runScreenShotStates
                    return  runScreenShotStates;
                })
                .catch((err) => console.log("err-- in setData", err));
        },
        nextArg: ()=>nextArg
    };


    // return await getScreenShotSingleData(url)
    //     .then((data) => {
    //         if(data?.state) data.data = data?.state
    //         if (Object.keys(data).length > 0) {
    //             const info = parse(data.data);
    //             return {data,info}
    //         }
    //         throw new Error('Data not found')
    //         alert("Պահպանված քարտեզ գոյություն չունի");
    //         window.close();
    //         return false
    //     })
    //     .then(({data,info}) => {
    //         const newData = {...data, id: data?.mapStateId || data?.id};
    //         dispatch(setScreenShotLanguage(info.languageId || 1));
    //         dispatch(setMapStateSingleData(newData));
    //         dispatch(setTopicStaticTitleST(newData.title));
    //         dispatch(setScreenShotSendDataST(stringify(newData)));
    //         return {data,info}
    //     })
    //     .then(({data,info}) => {
    //         // TODO OPTIMISATION THIS FUNCTIONAL
    //         const url  = new URLSearchParams(info.url.split('?')[1])
    //         const liveLocation  = new URLSearchParams(window.location.search)
    //
    //         const typeContent = url.get('type')
    //         const contentID = url.get(`${typeContent}ID`)
    //         const userID = url.get(`userId`)
    //
    //         const liveTypeContent = liveLocation.get('type')
    //         const liveContentID = liveLocation.get(`${typeContent}ID`)
    //         const liveUserID = liveLocation.get(`userId`)
    //         //
    //         // if(userID && userID !== liveUserID ){
    //         //     liveLocation.set("userId", `${userID}`);
    //         //     var currentQueryString = window.location.pathname;
    //         //     var newQueryString = currentQueryString + liveLocation;
    //         //     window.history.pushState({}, '', '?' + newQueryString);
    //         //
    //         // }
    //         if(typeContent !== liveTypeContent || contentID !== liveContentID){
    //             liveLocation.delete(`${liveTypeContent}ID`);
    //             liveLocation.set("type", `${typeContent}`);
    //             liveLocation.set(`${typeContent}ID`, contentID);
    //             window.location.search = liveLocation.toString();
    //         }
    //
    //                     // END OPTIM
    //
    //         if(info?.getMapStyledId) {
    //             info.baseMapGroupIdST = info.getMapStyledId
    //         }
    //         info.baseMapGroupIdST && dispatch(setScreenShotBaseMapGroupId(info.baseMapGroupIdST));
    //         info.baseMapIsLight && dispatch(setScreenShotBaseMapIsLightST(info.baseMapIsLight));
    //         info.baseMapGroupIdST && dispatch(changeMapStyles(info.baseMapGroupIdST,info.baseMapIsLight));
    //         info.baseMapGroupIdST && dispatch(setMapStyleStatus(info.baseMapGroupIdST));
    //         // info.getMapStyledId && dispatch(setMapStylesDark(info.getMapStyledId));
    //         info.baseMapGroupIdST && dispatch(setMapStylesIdST(info.baseMapGroupIdST));
    //         // info.getMapStyledDarkId && dispatch(setMapStylesDark(info.getMapStyledDarkId));
    //         info.getMapStyledDarkId && dispatch(setMapStylesDarkId(info.getMapStyledDarkId));
    //         info.selectedLesson && dispatch(setSelectedLesson(info.selectedLesson));
    //         // dispatch(setScreenShotSendDataST(stringify(data)));
    //         const leftBarCatOpenSTF = (subjectSettings) =>{
    //             const newPromise = delay(100)
    //             return newPromise
    //                 .then(()=> {
    //                     // info.categoryOpenID &&
    //                     dispatch(setOpenCategoryID(info.categoryOpenID || null));
    //                     return delay(100)
    //                 })
    //         }
    //         const articleModeSTF = (subjectSettings) =>{
    //             const newPromise = delay(400)
    //             console.log("SECOND ARTTSD");
    //             return newPromise
    //                 .then(()=> {
    //                     // info.categoryOpenID &&
    //                     dispatch(setMapMarkerInfoST( info.markerInfo && Object.keys(info.markerInfo).length  ? info.markerInfo : {}))
    //                     return delay(300)
    //                 })
    //         }
    //         const leftBarSTF = (subjectSettings) =>{
    //             const newPromise = delay(100)
    //             return newPromise
    //                 .then(()=>{
    //                     dispatch(setMenuExpend(false));
    //
    //                     return delay(300)
    //                 })
    //                 .then(()=>{
    //                     dispatch(setIsReadMoreState(info.isReadMore));
    //                     dispatch(setArticlePosition(info.articlePosition));
    //                     dispatch(setIsGalleryState(info.isGalleryMode));
    //                     dispatch(setGalleryTiliIndex(info.galleryTiliIndex));
    //                     dispatch(setGalleryArticleId(info.articleId));
    //                     // info.menuExpend &&
    //                     dispatch(setMenuExpend(info.menuExpend || false));
    //                     info.hints &&
    //                     dispatch(setHints(info.hints));
    //                     info.getLampSelectedId &&
    //                     dispatch(setLampSelectedId(info.getLampSelectedId ));
    //                     info.getLampModalState &&
    //                     dispatch(setLampModalState(info.getLampModalState));
    //                     info.lampDataFiltered.length > 0 &&
    //                     dispatch(
    //                         setLampDataFilteredScreenShot(Object.fromEntries(new Map(info.lampDataFiltered.filter(el=>el.isHide).map((item) => [item.id, item]))))
    //                     );
    //                     dispatch(setLampModalStateIndex(info.getLampModalStateIndex || 0));
    //                     info.getCategoryArticleClickedId &&
    //                     dispatch(getArticleFromAPI(info.getCategoryArticleClickedId,true));
    //                     // info.articleData.length > 0 &&
    //                     dispatch(setMapMarkerInfoST( info.markerInfo && Object.keys(info.markerInfo).length  ? {
    //                         ...info.markerInfo,
    //                         flyTo:false
    //                     } : {}))
    //                     dispatch(setArticles(info.articleData || []));
    //                     if((info.isReadMore || info.isGalleryMode) && !info.articleData.length && info.markerInfo && Object.keys(info.markerInfo).length){
    //                         dispatch(getArticleFromAPI(info.markerInfo.id,true));
    //                     }
    //
    //                     // info.getMapSelectedCluster && Object.keys(info.getMapSelectedCluster).length && dispatch(setMapSelectedCluster({
    //                     //     ...info.getMapSelectedCluster,
    //                     //     forScreenShoot: true
    //                     // }));
    //                     // info.articleData.length > 0 &&
    //                     // startPinAnimation(`article_${info.articleData[0].id}`);
    //
    //                     info.quizPopup && dispatch(setQuizPopup(info.quizPopup));
    //
    //                     if(!info.filteredCategories && Object.keys(info?.filteredCategoriesProxyData)?.length > 0) {
    //                         getState().filteredCategories.data.updateDataStates(info?.filteredCategoriesProxyData)
    //                         dispatch(setFilteredCategoriesST(getState().filteredCategories.data.getTarget()));
    //                     }
    //                     // support old screens
    //                     if(info.filteredCategories?.length > 0)
    //                     {
    //                         const filteredCategories = structuredClone(info.filteredCategories)
    //                         const object = structuredClone(info.filteredCategoriesProxyData)
    //                         const newIndex = {}
    //                         Object.keys(object).forEach(el=> {
    //                             if (typeof object[el] == 'object'){
    //                                 newIndex[el] = {
    //                                     id: object[el].id,
    //                                     key: object[el].key,
    //                                     show: object[el].show,
    //                                     showInTimeLine: object[el].showInTimeLine,
    //                                 }
    //                             }
    //                         })
    //                         updateChildFieldById({categories:filteredCategories},newIndex,['show','showInTimeLine'])
    //                         const  newProxy = new arrayProxy(filteredCategories)
    //                         newProxy.updateIndex(newIndex)
    //                         const dataStates = newProxy.getDatagetState()
    //                         getState().filteredCategories.data.updateDataStates(dataStates)
    //                         dispatch(setFilteredCategoriesST(getState().filteredCategories.data.getTarget()));
    //                     }
    //
    //                     if (!info.filteredLegends && Object.keys(info?.filteredLegendsProxyData)?.length > 0) {
    //                         getState().legends.filteredData.updateDataStates(info?.filteredLegendsProxyData)
    //                     }
    //
    //                     // support old screens
    //                     if (info.filteredLegends?.length > 0) {
    //                         const newProxy = new LegendArrayProxy(info.filteredLegends)
    //                         newProxy.updateIndex(info?.filteredLegendsProxyData)
    //                         getState().legends.filteredData.updateDataStates(newProxy.getDatagetState())
    //                         // dispatch(setFilteredLegends(getState().legends.filteredData.getTarget()));
    //                     }
    //
    //                     info.mapRequirement &&
    //                     dispatch(setMapRequirement(info.mapRequirement));
    //                     info.mapRequirements?.length > 0 &&
    //                     dispatch(changeMapRequirementsScreenShot(info.mapRequirements));
    //                     info.mapRequirements?.length > 0 &&
    //                     dispatch(
    //                         changeMapRequirements(
    //                             info.mapRequirements.find((item) => item.active).value
    //                         )
    //                     );
    //
    //                     return delay(300)
    //                 })
    //                 .then(()=> {
    //                     dispatch(setGalleryIndex(info.galleryIndex));
    //                     return delay(100)
    //                 })
    //                 .then(()=> {
    //                     dispatch(setGalleryFullScreen(info.galleryFullScreen));
    //                     return delay(100)
    //                 })
    //
    //         }
    //         const compassSTF = (subjectSettings) =>{
    //             const newPromise = delay(0)
    //             return newPromise
    //                 .then(()=> {
    //                     dispatch(setCompassNewAngle(info.getNewAngle || 0));
    //                     Object.keys(info.getCompassCoordinates).length &&
    //                     dispatch(setCompassCoordinates(info.getCompassCoordinates));
    //                     return delay(500)
    //                 })
    //                 .then(()=> {
    //                     info.getNewRotate &&
    //                     dispatch(setCompassRotate(info.getNewRotate));
    //                     return delay(0)
    //                 })
    //         }
    //         const toolBarSTF = (subjectSettings) =>{
    //             dispatch(setIsAllToolboxStatesLoaded(false));
    //             dispatch(setEraserClickedState(false));
    //             dispatch(setDrawerToolboxClickedState(false));
    //             dispatch(setStickyNotesClickedState(false));
    //             dispatch(setLineDrawToolboxState(false));
    //             dispatch(setPolygonDrawToolboxState(false));
    //             dispatch(setCommentToolboxState(false));
    //             dispatch(setRulerClickedState(false));
    //             dispatch(setTextToolboxState(false));
    //             let {ruler, drawer, commentBox, lineDraw, polygonDraw, stickyNotes, eraser, textBox, shapeTool} = subjectSettings
    //
    //             if (process.env.REACT_APP_ENV === 'production' ) {
    //                 textBox = null;
    //             }
    //
    //             const newPromise = delay(0)
    //             return newPromise
    //                 .then(()=> {
    //                     if(ruler || drawer || commentBox|| lineDraw || eraser || stickyNotes || textBox){
    //                         const isOpenPainterStart =  !!((info.getDrawerToolboxClickedState || info.getRulerClickedState || info.getCommentToolboxState || info.getStickyNotesClickedState || info.getLineDrawToolboxState || info.getEraserClickedState) && info.painterStart)
    //                         dispatch(setPainterStart(isOpenPainterStart));
    //                     }
    //                     if(ruler) {
    //                         info.getRulerPopupShowST &&
    //                         dispatch(setRulerPopupShowST(info.getRulerPopupShowST));
    //                     }
    //                     return ruler || drawer ? delay(100) : delay(0)
    //                 })
    //                 .then(() => {
    //                     if(eraser) {
    //                         dispatch(setEraserClickedState(!!info.getEraserClickedState));
    //                     }
    //                     return eraser ? delay(100) : delay(0);
    //                 })
    //                 .then(() => {
    //                     const searchParams = new URLSearchParams(window.location.search);
    //
    //                     const screenSlideId = searchParams.get('screenSlide');
    //                     // const screenShot = searchParams.get('screenShot');
    //                     // const presentation = searchParams.get('presentation');
    //                     //!!shapeTool
    //                     if(shapeTool && ((String(id) === screenSlideId) || !screenSlideId) ) {
    //                         info.shapeToolState && dispatch(setShapeToolState(info.shapeToolState));
    //                         info.shapeToolState && dispatch(setShaperClickState(info.shapeToolState.shapeModeIsActive));
    //                     }
    //                     return shapeTool ? delay(100) : delay(0);
    //                 })
    //                 .then(()=> {
    //                     if(drawer){
    //                         // OLD SCREENSHOTS FOR DRAWER SUPPORT FROM FRONT, WHEN BACK READY, DELETE IT
    //                         (info.getDrawerToolboxClickedState || info.getPainterClickedState)
    //                         && dispatch(setDrawerToolboxClickedState((info.getDrawerToolboxClickedState || info.getPainterClickedState)));
    //
    //
    //
    //                         if (Object.keys(info.getPainterGeoJsonDataST).length > 0 && info.getPainterGeoJsonDataST.features.length && info.getPainterGeoJsonDataST.features[0].id && !uuidValidate(info.getPainterGeoJsonDataST.features[0].id)) {
    //                             info.getPainterGeoJsonDataST.features = info.getPainterGeoJsonDataST.features.map(feature => {
    //                                 const id = uuidv4();
    //                                 feature.id = id;
    //                                 feature.geometry.coordinates = feature.geometry.coordinates.map(coords => [+coords[0], +coords[1]]);
    //                                 feature.properties = {
    //                                     ...feature.properties, id
    //                                 }
    //                                 return feature;
    //                             })
    //                         }
    //
    //                         (Object.keys(info.getPainterGeoJsonDataST).length > 0) && dispatch(setPainterGeoJsonDataST({
    //                             ...info.getPainterGeoJsonDataST
    //                         }));
    //
    //                     }
    //
    //                     return drawer ? delay(700)  : delay(0)
    //                 })
    //                 .then(()=> {
    //                     if(ruler && info.getRulerGeoJsonDataST && info.getRulerGeoJsonDataST.features?.length && info.getRulerGeoJsonDataST.features[0] !== null) {
    //                         dispatch(setPainterStart(true));
    //                         dispatch(setRulerClickedState(true));
    //                         dispatch(setRulerGeoJsonDataST(info.getRulerGeoJsonDataST));
    //                         dispatch(setRulerPopupShowST(true));
    //                     } else {
    //                         dispatch(setRulerClickedState(false));
    //                     }
    //                     return ruler ? delay(500) : delay(0)
    //                 })
    //                 .then(()=> {
    //                     if((lineDraw || polygonDraw) && info.getMapboxDrawToolboxGeoJSONState) {
    //                         const copyInfo = JSON.parse(JSON.stringify(info.getMapboxDrawToolboxGeoJSONState));
    //                         if(lineDraw && polygonDraw) {
    //                             dispatch(setMapboxDrawToolboxGeoJSONState(copyInfo.features && copyInfo.features.length ? copyInfo : {
    //                                 type: 'FeatureCollection',
    //                                 features: []
    //                             }));
    //
    //                             return delay(100);
    //                         }
    //
    //                         if(lineDraw && !polygonDraw && copyInfo.features && copyInfo.features.length) {
    //                             copyInfo.features = copyInfo.features.filter(feature =>
    //                                 feature.geometry.type === 'LineString'
    //                             );
    //
    //                             dispatch(setMapboxDrawToolboxGeoJSONState(copyInfo.features && copyInfo.features.length ? copyInfo : {
    //                                 type: 'FeatureCollection',
    //                                 features: []
    //                             }));
    //                         }
    //
    //                         else if(polygonDraw && !lineDraw && copyInfo.features && copyInfo.features.length) {
    //                             copyInfo.features = copyInfo.features.filter(feature =>
    //                                 feature.geometry.type === 'Polygon'
    //                             );
    //
    //                             dispatch(setMapboxDrawToolboxGeoJSONState( copyInfo.features && copyInfo.features.length ? copyInfo : {
    //                                 type: 'FeatureCollection',
    //                                 features: []
    //                             }));
    //                         }
    //                         return delay(100);
    //                     } else {
    //                         return delay(0);
    //                     }
    //                 })
    //                 .then(() => {
    //                     if(stickyNotes) {
    //                         info.getStickyNotesMarkersState && dispatch(setStickyNotesMarkersState(info.getStickyNotesMarkersState));
    //                     } else {
    //                         dispatch(setStickyNotesMarkersState([]));
    //                     }
    //
    //                     return stickyNotes ? delay(100) : delay(0)
    //                 })
    //                 .then(() => {
    //                     if(textBox) {
    //                         info.getTextToolboxMarkersState && dispatch(setTextToolboxMarkersState(info.getTextToolboxMarkersState));
    //                     } else {
    //                         dispatch(setTextToolboxMarkersState([]));
    //                     }
    //
    //                     dispatch(setTextToolboxMarkersLoadingState(false));
    //                     dispatch(setIsAllToolboxStatesLoaded(true));
    //
    //                     return textBox ? delay(100) : delay(0)
    //                 })
    //                 .then(() => {
    //                     if(commentBox) {
    //                         info.getCommentToolboxMarkersState && dispatch(setCommentToolboxMarkersState(info.getCommentToolboxMarkersState));
    //                     } else {
    //                         dispatch(setCommentToolboxMarkersState([]));
    //                     }
    //
    //                     dispatch(setCommentToolboxMarkersLoadingState(false));
    //                     dispatch(setIsAllToolboxStatesLoaded(true));
    //
    //                     return commentBox ? delay(100) : delay(0)
    //                 })
    //         }
    //         const timelineSTF = (subjectSettings) =>{
    //             const newPromise = delay(100)
    //             return newPromise
    //                 .then(()=> {
    //                     info.getTimeLineHeightST && dispatch(setTimeLineHeightST(info.getTimeLineHeightST));
    //                     info.getTimeLineZoomST &&
    //                     dispatch(setTimeLineZoomST(info.getTimeLineZoomST));
    //                     Object.keys(info.getTimeLineMovieDataST).length &&
    //                     dispatch(setTimeLineMoveDataStatusST(true));
    //                     Object.keys(info.getTimeLineMovieDataST).length &&
    //                     dispatch(setTimeLineMoveDataST(info.getTimeLineMovieDataST));
    //                     Object.keys(info.getTimeLineMovePositionST).length > 0 &&
    //                     dispatch(setTimeLIneMoveStatus(true));
    //                     Object.keys(info.getTimeLineMovePositionST).length > 0 &&
    //                     dispatch(
    //                         setTimeLienMovePos({
    //                             start: info.getTimeLineMovePositionST.start,
    //                             end: info.getTimeLineMovePositionST.end,
    //                         })
    //                     );
    //                     info.getTimeLineMoveCountST &&
    //                     dispatch(setTimeLineMoveCountST(info.getTimeLineMoveCountST));
    //
    //                     return delay(300)
    //                 })
    //                 .then(()=> {
    //                     info.getTimeLineCursorStatusST &&
    //                     dispatch(
    //                         setTimeLineCursorStatusST(info.getTimeLineCursorStatusST)
    //                     );
    //                     dispatch(
    //                         setFirstTimeLineCursorStatusST(true)
    //                     )
    //                     Object.keys(info.getTimeLineEventDataST).length > 0 &&
    //                     dispatch(setTimeLineEventDataST(info.getTimeLineEventDataST));
    //                     return delay(500)
    //                 })
    //                 .then(()=> {
    //                     info.timelineExtend &&
    //                     dispatch(setTimeLineExpend(info.timelineExtend));
    //                     return delay(100)
    //                 })
    //
    //         }
    //         const mapSTF = (subjectSettings) =>{
    //             const newPromise = delay(700)
    //             return newPromise
    //                 .then(()=> {
    //                     Object.keys(info.getMapCenterCoordinatesST).length > 0 &&
    //                     dispatch(setMapCenterPositionStatusST(true));
    //                     Object.keys(info.getMapCenterCoordinatesST).length > 0 &&
    //                     dispatch(
    //                         setMapCenterPositionST(info.getMapCenterCoordinatesST)
    //                     );
    //                     info.getZoomDataST && dispatch(setMapZoom(info.getZoomDataST));
    //                     return delay(700)
    //                 })
    //
    //         }
    //         const checkSettingsSTF = (subjectSettings) =>{
    //             const {ruler,drawer,mapTypes, commentBox, lineDraw, polygonDraw, stickyNotes, textBox, shapeTool} = subjectSettings
    //             const newPromise = delay(0)
    //             return newPromise
    //                 .then(()=> {
    //                     dispatch(setShowNotificationST(2))
    //                     return delay(300)
    //                 })
    //                 .then(()=> {
    //                     if(
    //                         ((info.getPainterGeoJsonDataST && info.getPainterGeoJsonDataST?.features?.length > 0 ) && !drawer)
    //                         || (info.getRulerClickedState && !ruler)
    //                         || (info.shapeToolState && info.shapeToolState?.shapes?.length && !shapeTool)
    //                         || ((info.getCommentToolboxMarkersState && info.getCommentToolboxMarkersState?.length > 0) && !commentBox)
    //                         || ((info.getStickyNotesMarkersState && info.getStickyNotesMarkersState?.length > 0) && !stickyNotes)
    //                         || ((info.getTextToolboxMarkersState && info.getTextToolboxMarkersState?.length > 0) && !textBox)
    //                         || ((info.getLineDrawToolboxState || (info.getMapboxDrawToolboxGeoJSONState && Object.keys(info.getMapboxDrawToolboxGeoJSONState)?.length && info.getMapboxDrawToolboxGeoJSONState.features.length) > 0) && !lineDraw)
    //                         || ((info.getPolygonDrawToolboxState || (info.getMapboxDrawToolboxGeoJSONState && Object.keys(info.getMapboxDrawToolboxGeoJSONState)?.length && info.getMapboxDrawToolboxGeoJSONState.features.length) > 0) && !polygonDraw)
    //                         || (info.baseMapGroupIdST && !mapTypes.find((el)=>el.mapTypeId === info.baseMapGroupIdST))
    //                     ) {
    //                         if(info?.updateSeenDate < subjectSettings.modifiedAt || !info?.updateSeenDate){
    //                             info.updateSeenDate = subjectSettings.modifiedAt
    //                             const url = `${process.env.REACT_APP_IDENTITY_URL}/api/MapState/${data.id}`;
    //                             const body = {
    //                                 title:info.topicStaticTitle,
    //                                 data: stringify(info),
    //                             };
    //                             editScreenShot(url, body).then((data) => {
    //                                 dispatch(setScreenShotButtonStateST(false));
    //                             });
    //                             return dispatch(setShowNotificationST(8))
    //                         }else{
    //                             return dispatch(setShowNotificationST(4))
    //                         }
    //                     }
    //                     return delay(100)
    //                 })
    //         }
    //         const loadDoneSTF = (subjectSettings) =>{
    //             const newPromise = delay(0)
    //             return newPromise
    //                 .then(()=> {
    //                     dispatch(setScreenShotLoading(false));
    //                     return delay(100)
    //                 })
    //         }
    //         const loadBaseMap = (subjectSettings) =>{
    //             const newPromise = delay(0)
    //             return newPromise
    //                 .then(()=> {
    //                     if(info?.getMapStyledId) {
    //                         info.baseMapGroupIdST = info.getMapStyledId
    //                     }
    //                     if(!info.baseMapGroupIdST) {
    //                         return dispatch(changeMapStyles(subjectSettings.mapTypes.find(el => el.isMain)?.mapTypeId || 1))
    //                     }
    //                     if(!subjectSettings.mapTypes.find(el=>el.mapTypeId === info.baseMapGroupIdST)){
    //                         info.baseMapGroupIdST = subjectSettings.mapTypes.find(el=>el.isMain)?.mapTypeId || 1
    //                     }
    //                     // NEW
    //                     info.baseMapGroupIdST && dispatch(setScreenShotBaseMapGroupId(info.baseMapGroupIdST));
    //                     info.baseMapIsLight && dispatch(setScreenShotBaseMapIsLightST(info.baseMapIsLight));
    //                     // END NEW
    //                     info.baseMapGroupIdST && dispatch(changeMapStyles(info.baseMapGroupIdST,info.baseMapIsLight));
    //                     info.baseMapGroupIdST && dispatch(setMapStyleStatus(info.baseMapGroupIdST));
    //                     info.baseMapGroupIdST && dispatch(setMapStylesIdST(info.baseMapGroupIdST));
    //                     // info.getMapStyledDarkId && dispatch(setMapStylesDark(info.getMapStyledDarkId));
    //                     info.getMapStyledDarkId && dispatch(setMapStylesDarkId(info.getMapStyledDarkId));
    //                     return delay(300)
    //                 })
    //
    //         }
    //
    //         const statesFunc = {
    //             leftBarSTF,
    //             compassSTF,
    //             toolBarSTF,
    //             timelineSTF,
    //             mapSTF,
    //             loadDoneSTF,
    //             leftBarCatOpenSTF,
    //             checkSettingsSTF,
    //             loadBaseMap,
    //             articleModeSTF
    //         };
    //
    //         Object.keys(statesFunc).forEach(key => {
    //             statesFunc[key] = throttle(statesFunc[key], 1200, { leading: true, trailing: false });
    //         });
    //
    //         const runScreenShotStates = (type) => {
    //             const subjectSettings = getState()?.subjects?.settings;
    //             if (!Array.isArray(type)) return statesFunc[type](subjectSettings);
    //
    //             const [first, ...rest] = type;
    //             return statesFunc[first](subjectSettings)
    //                 .finally(() => {
    //                     if (!rest.length) {
    //                         return;
    //                     }
    //                     return runScreenShotStates(rest);
    //                 });
    //         };
    //
    //         return  runScreenShotStates;
    //     })
    //     .catch((err) => console.log("err--", err));
};


export const createScreenShotAPI =  (languageId, title, data, topicId, lessonId, description, mediaId, imageUrl) => async (dispatch) => {
    const url = `${process.env.REACT_APP_IDENTITY_URL}/api/MapState`;
    const body = {
        languageId,
        title: title || 'empty',
        data: stringify(data),
        topicId,
        lessonId,
        description,
        mediaId,
        imageUrl
    };

    try {
        const response = await createScreenShot(url, body);
        dispatch(setScreenShotButtonStateST(false));
        return response;
    } catch (error) {
        console.log("error", error);
    }

    //  createScreenShot(url, body).then((data) => {
    //     dispatch(setScreenShotButtonStateST(false));
    // });
};

export const removeScreenShotCardAPI =
    (pageLoc, cardId, userId, onClose) => (dispatch) => {
        const url = `${process.env.REACT_APP_IDENTITY_URL}/api/MapState/${cardId}`;

        removeScreenShot(url).then((data) => {
            dispatch(setRemoveCardId(cardId));
            // dispatch(getScreenShotDataFromAPI(pageLoc, userId, onClose, false));
        });
    };

export const editScreenShotCardAPI =
    (pageLoc, userId, id, title, data, onClose,description,mediaId, imageUrl ) => (dispatch) => {

        const url = `${process.env.REACT_APP_IDENTITY_URL}/api/MapState/${id}`;

        const body = {
            userId, title, data, onClose, mediaId, imageUrl, description
        };

        editScreenShot(url, body).then((data) => {
            dispatch(getScreenShotDataFromAPI(pageLoc, userId, onClose, false));
        });
    };

export const editScreenDataAPI = (languageId, id, title, data, onClose, topicId, lessonId, description, screenshotUrl) => async (dispatch) => {
    try {
        const url = `${process.env.REACT_APP_IDENTITY_URL}/api/MapState/${id}`;
        const body = {
            title,
            data: stringify(data),
            topicId,
            lessonId,
            languageId,
            description,
            imageUrl: screenshotUrl || data.imageUrl
        };

        await editScreenShot(url, body);

        dispatch(setScreenShotButtonStateST(false));

        const updatedData = await getScreenShotSingleData(url);

        dispatch(setMapStateSingleData(updatedData));

        if (onClose) {
            onClose();
        }
    } catch (error) {
        console.error("Error in editScreenDataAPI:", error);
    }
};


const startPinAnimation = (id) => {
    let mapMarkers = document.getElementsByClassName(
        "mapboxgl-marker-anchor-center"
    );

    for (let i = 0; i < mapMarkers.length; i++) {
        if (mapMarkers[i].getAttribute("class").includes(id)) {
            mapMarkers[i].classList.add("tic_animation");
            mapMarkers[i].style.zIndex = "2";
            continue;
        }
        mapMarkers[i].classList.remove("tic_animation");
        mapMarkers[i].style.zIndex = "1";
    }
};
