import {useDispatch} from "react-redux";
import ConnectedSpriteImageComponent from "../ConnectedSpriteImageComponent";
import {setActiveMicroArticleId} from "../../../store/actions/microArticle";
import "./style.css"
import {DEFAULT_ICON_SPRITE_NAME, INITIAL_ICON_OBJECT, isIconKeyValid} from "../../microArticle/utils";

const MapElementsPopupRadius = (props) =>{
    const {child, selectELements,coordinates,activeStyle} = props

    const dispatch = useDispatch();

    const handleClick = (el) =>{
        if (el.properties && el.properties.isMicroArticle) {
            dispatch(setActiveMicroArticleId(el.properties.microArticleID))
        } else {
            selectELements(el,coordinates,child?.length || 0)
        }
    }

    return(
        <div className="elements-popup-radius-box">
            {
                child.map(el=>(
                    <div className="elements-popup-radius-content"
                         id={`element-${el.id || el?.properties?.id}`}
                         key={`element-${el.id || el?.properties?.id}`}
                         style={{
                             '--top':`${el.x}px`,
                             '--left': `${el.y}px`,
                             '--rotate': `rotate(${el.radians}rad)`,
                             '--radians': `rotate(${el.radians * -1}rad)`,
                         }}
                         onClick={() => handleClick(el)}
                    >
                        <div className="elements-popup-radius-content__line" ></div>
                        <div className="elements-popup-radius-content__info"
                             data-left={el.y>0}
                             data-title={el?.properties?.isMicroArticle ? el?.properties?.title : el?.properties?.name}
                             style={{
                                 // '--icon':`url("${el?.properties?.iconPath}")`,
                                 '--color':`${el?.properties?.isMicroArticle ? el?.properties?.backgroundColor : el?.properties?.color}`,
                                 '--text-color':activeStyle.color,
                                 '--text-halo-color':activeStyle['halo-color']
                             }}
                        >
                            <ConnectedSpriteImageComponent name={el?.properties && el?.properties?.isMicroArticle ? isIconKeyValid(el?.properties?.iconKey) ? el?.properties?.iconKey : DEFAULT_ICON_SPRITE_NAME : el?.properties?.spriteIcon} centered={true}/>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default MapElementsPopupRadius;
