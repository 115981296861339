import React from 'react'
import {useGetPresentationQuery} from '../store/presentationApi'
import {Button, Image} from 'antd'
import {useTranslation} from "react-i18next";
import ReactQuill from "react-quill";

const PresentationIntro = ({ id, onStart }) => {
    const { t } = useTranslation()
  const { data, isFetching } = useGetPresentationQuery({ id }, { skip: !id })

  const presentation = data?.data[0]


  if (isFetching) {
    return null
  }

  return (
    <div className="overflow-hidden rounded-md">
      {presentation?.imageUrl && (
        <Image
          rootClassName="w-full flex aspect-[6/4]  bg-[#f4f4f4] max-h-[37dvh]"
          className="object-contain"
          src={presentation?.imageUrl}
          preview={false}
        />
      )}
      <div className="px-3 md:px-6 py-2">
        <h3 className="uppercase text-[#2C476C]">{presentation?.title}</h3>
        {presentation?.creatorFirstName && (
          <h4 className="text-[#2C476C80] py-2">
            {t("presentation_mode.created_by")} {presentation?.creatorFirstName || ''} {presentation?.creatorLastName || ''}
          </h4>
        )}

        <div className="max-h-[130px] overflow-auto text-[#2C476C]">
          <ReactQuill value={presentation?.description || presentation?.shortDescription}  readOnly={true} className="border-none [&>.ql-toolbar]:hidden readably--quill"/>
        </div>

        <div className="flex gap-3 p-3 justify-center">
          <Button
            onClick={onStart}
            rootClassName="w-[200px] uppercase text-[16px] bg-[#FFDE5A] rounded-full"
            type="text">
            {t('presentation_mode.play')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default PresentationIntro
