import {
    SET_MICRO_ARTICLE_DATA,
    SET_CURRENT_FEATURE_ID,
    SET_VIEW_POPUP_VISIBILITY,
    SET_FORM_POPUP_VISIBILITY,
    SET_ALL_MICRO_ARTICLES,
    IS_MICRO_ARTICLES_LOADING,
    SET_ALL_MICRO_ARTICLES_BOARD,
    SET_ACTIVE_MICROARTICLE_ID,
    IS_SINGLE_MICRO_ARTICLE_LOADING,
    MICRO_ARTICLE_ICONS_SPRITE_CATEGORIES,
    MICRO_ARTICLE_ICONS_SPRITE_JSON,
    SET_CLOSE_AND_DEACTIVATE_ALL_ACTIVATOR,
    SET_MICRO_ARTICLE_SELECTED_CATEGORY_ID,
    SET_MICRO_ARTICLE_ICONS_KEYS, SET_MICRO_ARTICLE_SPRITE_ICONS_SEARCH, SET_POPUP_DETAIL_FONT_SIZE, SET_RECENT_COLORS
} from "../constants";

const initialState = {
    isMicroArticlesLoading: false,
    isSingleMicroArticleLoading: false,
    popupDetailFontSize: window.innerWidth > 768 && window.innerHeight > 720 ? 14 : 16,
    allMicroArticles: null, // after it's gonna be []
    activeMicroArticleId: null,
    allMicroArticlesBoard: null,
    microArticleData: null,
    currentFeatureId: null,
    viewPopupIsVisible: false,
    closeAndDeactivateAllActivator: false,
    isFormPopupVisible: false,
    microArticleIconsSpriteJSON: null,
    microArticleIconsSpriteCategories: null,
    microArticleSelectedCategoryId: null,
    microArticleIconsKeys: null,
    microArticleSpriteIconsSearch: '',
    recentColors: [],
};

export const microArticleReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_RECENT_COLORS: {
            return {
                ...state,
                recentColors: action.payload
            }
        }
        case SET_POPUP_DETAIL_FONT_SIZE: {
            return {
                ...state,
                popupDetailFontSize: action.payload
            }
        }
        case SET_MICRO_ARTICLE_SPRITE_ICONS_SEARCH: {
            return {
                ...state,
                microArticleSpriteIconsSearch: action.payload
            }
        }
        case SET_MICRO_ARTICLE_ICONS_KEYS: {
            return {
                ...state,
                microArticleIconsKeys: action.payload
            }
        }
        case SET_CLOSE_AND_DEACTIVATE_ALL_ACTIVATOR: {
            return {
                ...state,
                closeAndDeactivateAllActivator: action.payload
            }
        }
        case SET_MICRO_ARTICLE_SELECTED_CATEGORY_ID: {
            return {
                ...state,
                microArticleSelectedCategoryId: action.payload
            }
        }
        case MICRO_ARTICLE_ICONS_SPRITE_JSON: {
            return {
                ...state,
                microArticleIconsSpriteJSON: action.payload
            }
        }
        case MICRO_ARTICLE_ICONS_SPRITE_CATEGORIES: {
            return {
                ...state,
                microArticleIconsSpriteCategories: action.payload
            }
        }
        case IS_SINGLE_MICRO_ARTICLE_LOADING: {
            return {
                ...state,
                isSingleMicroArticleLoading: action.payload
            }
        }
        case SET_ACTIVE_MICROARTICLE_ID:
            return {
                ...state,
                activeMicroArticleId: action.payload,
            };
        case SET_ALL_MICRO_ARTICLES_BOARD:
            return {
                ...state,
                allMicroArticlesBoard: action.payload
            }
        case SET_MICRO_ARTICLE_DATA:
            return {
                ...state,
                microArticleData: action.payload,
            };
        case SET_CURRENT_FEATURE_ID:
            return {
                ...state,
                currentFeatureId: action.payload,
            };
        case SET_VIEW_POPUP_VISIBILITY:
            return {
                ...state,
                viewPopupIsVisible: action.payload,
            };
        case SET_FORM_POPUP_VISIBILITY:
            return {
                ...state,
                isFormPopupVisible: action.payload,
            };
        case SET_ALL_MICRO_ARTICLES:
            return {
                ...state,
                allMicroArticles: action.payload,
            };
        case IS_MICRO_ARTICLES_LOADING: {
            return {
                ...state,
                isMicroArticlesLoading: action.payload
            }
        }

        default:
            return state;
    }
};
