import React, {useEffect, useMemo, useState} from 'react'
import TextIcon from '../icons/TextIcon'
import PenIcon from '../icons/PenIcon'
import CopyIcon from '../icons/CopyIcon'
import TrashIcon from '../icons/TrashIcon'
import {Button, Dropdown, Modal} from 'antd'
import DotsIcon from '../icons/DotsIcon'
import {useSortable} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'
import {cn} from '../../../../utils/utils'
import {CircleDot, GripVertical, XIcon} from 'lucide-react'
import PresentationFormWrapper from '../form/PresentationFormWrapper'
import {
    useCloneSlideMutation,
    useGetArticleQuery,
    useGetMicroArticleQuery,
    useRemoveSlideMutation,
} from '../../store/presentationApi'
import ConnectedSpriteImageComponent from '../../../UI/ConnectedSpriteImageComponent'
import {useDispatch, useSelector} from 'react-redux'
import {getAllMicroArticles, getCategoriesData} from '../../../../store/selectors'
import {setCurrentSlide, setPlaybackPresentation,} from '../../store/presentationSlice'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import ReactQuill from "react-quill";
import {INITIAL_ICON_OBJECT, isIconKeyValid} from "../../../microArticle/utils";

const PresentationSlideItem = ({
  name,
  id,
  onClick,
  presentationId,
  onStart,
  image,
  number,
  articleId,
  microArticleId,
  presentation,
  description,
                                 viewMode,
                                 hideEdit,
}) => {
  const [patumIsOpen, setPatumIsOpen] = useState(false)
  const [editIsOpen, setIsEditMode] = useState(false)
  const [search] = useSearchParams()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: Number(id), disabled: viewMode })

  const [onClone, { isLoading: isCloning }] = useCloneSlideMutation()
  const [onDelete, { isLoading: isDeleting }] = useRemoveSlideMutation()

  const { data: microArticleData, refetch: refetchMicroArticle, isUninitialized } = useGetMicroArticleQuery(microArticleId, {
    skip: !microArticleId,
  })

  const { data: articleData } = useGetArticleQuery(articleId, {
    skip: !articleId,
  })
  const microArticles = useSelector(getAllMicroArticles)

  const article = articleData?.data?.[0]
  const microArticle = microArticleData?.data?.[0]

  const categories = useSelector(getCategoriesData)

  const startHandler = (e) => {
    e.stopPropagation()
    dispatch(setPlaybackPresentation(presentation))
    dispatch(setCurrentSlide(''))
    search.set('presentation', presentation?.id)
    if (!articleId) {
      // search.set('screenShot', true)
    } else {
      search.set('articleMode', articleId)
    }
    search.set('screenShot', true)
    search.set('loading', true)
    search.set('playBack', true)

    if (id) {
      search.set('screenSlide', id)
    }

    navigate(
      {
        pathname: window.location.pathname,
        search: search.toString(),
      },
      { replace: true },
    )

    window.location.reload()

    onStart?.(presentation)
  }

  const subCategoryInfo = useMemo(() => {
    const subCategoryId = article?.subCategory?.find(
      (item) => item.isMain,
    )?.subCategoryId
    if (!subCategoryId || !categories?.length) {
      return null
    }

    let icon
    let color

    categories?.forEach((item) => {
      item.subCategories?.forEach?.((subCategory) => {
        if (subCategory?.id === subCategoryId) {
          icon = subCategory?.spriteIcon
          color = subCategory?.color
        }
      })
    })

    return { icon, color }
  }, [article, categories])

  const items = [
    {
      icon: <TextIcon />,
      label: t('presentation_mode.narrative'),
      key: 'text',
      disabled: !description,
    },
    {
      icon: <PenIcon />,
      label: t('presentation_mode.edit'),
      key: 'editSlide',
      disabled: hideEdit
    },
    {
      icon: <CopyIcon />,
      label: t('presentation_mode.duplicate'),
      key: 'clone',
    },
    {
      icon: <TrashIcon />,
      label: t('presentation_mode.delete'),
      key: 'remove',
    },
  ]

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  const clickDropdownHandler = (e) => {
    e.domEvent.stopPropagation()
    switch (e.key) {
      case 'text':
        setPatumIsOpen(true)
        break
      case 'editSlide':
        setIsEditMode(true)
        break
      case 'copy_url':
        break
      case 'clone':
        onClone({
          presentationId,
          slideIds: [id],
        })
        break
      case 'remove':
        onDelete(id)
        break
      default:
        break
    }
  }

  const clickHandler = (e) => {
    setPatumIsOpen(false)
    onClick?.()
  }


  useEffect(() => {
    if (microArticles?.length && !isUninitialized
        // && microArticles?.find(item => +item?.id === +microArticleId)
    ) {
      refetchMicroArticle()
    }
  }, [microArticles,microArticleId, isUninitialized]);


  if (microArticleId && !microArticle) {
    return null
  }

  return (
    <>
      <div
        className="text-[#2C476C] hover:bg-[#F6F6F6] rounded-md"
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}>
        <div
          className={cn('presentation-slide--item', {
            'bg-[#F6F6F6]': isDragging,
          })}
          onClick={clickHandler}>
          {isDragging && <GripVertical className="!w-[15px]" />}
          <small>{number}</small>
          <div className="scale-75 w-[30px] h-[30px] [&>div] rounded-full flex items-center justify-center">
            {image ? (
              <img className="w-5 h-5 rounded" src={image} alt="slide" />
            ) : article ? (
              <ConnectedSpriteImageComponent name={subCategoryInfo?.icon} />
            ) : microArticle ? (
                <div style={{filter:"drop-shadow(0px 0px 1px #999)"}}>
                  <ConnectedSpriteImageComponent  name={isIconKeyValid(microArticle.iconKey) ? microArticle.iconKey : INITIAL_ICON_OBJECT.darkKey} />
                </div>
            ) : (
              <CircleDot
                color="#2C476C"
                fontSize={12}
                className="!h-[28px] !w-[28px]"
              />
            )}
          </div>

          <div className="flex flex-1 items-center">
            <span className="flex-1">{name}</span>

            {
              !viewMode &&
                <Dropdown
                    getPopupContainer={() => document.body}
                    overlayClassName="app-dropdown"
                    placement="bottomLeft"
                    menu={{items: items, onClick: clickDropdownHandler }}
                    trigger={['click']}>
                  <button
                      className="flex h-6 items-center"
                      onClick={(e) => e.stopPropagation()}>
                    <DotsIcon width="6" height="10" className="!h-4" />
                  </button>
                </Dropdown>
            }
          </div>
        </div>
        {patumIsOpen && (
          <div className="my-1.5 p-2 presentation-patum--editor pr-[3px]">
            <div className="flex justify-between gap-2 items-center mb-1">
              <span className="mb-1 block text-sm text-[#2C476C]">
                {t('presentation_mode.narrative')}
              </span>
              <Button
                type="text"
                size="small"
                icon={<XIcon fontSize={14} className="!w-4 !h-4" color="#2C476C"/>}
                onClick={() => setPatumIsOpen(false)}
              />
            </div>
            <div className="max-h-[80px] overflow-auto border-[#e4e4e4] rounded-md p-2 border border-solid">
              {/*<div dangerouslySetInnerHTML={{ __html: description }} />*/}
              <ReactQuill value={description}  readOnly={true} className="border-none [&>.ql-toolbar]:hidden readably--quill"
              />
            </div>

            {/*<TextEditor onClose={() => setPatumIsOpen(false)} placeholder="Ավելացնել պատում"/>*/}
          </div>
        )}
      </div>
      <Modal
        destroyOnClose
        title={presentation?.title}
        centered
        classNames={{
          body: 'max-h-[80vh] overflow-auto',
          header:
            'uppercase text-center !pb-3 tracking-wider text-[16px] font-medium !pt-4 !px-3',
        }}
        // getContainer={() => document.body}
        className="app-modal no-padding max-h-[90vh]"
        width={950}
        footer={false}
        style={{ padding: 0 }}
        onCancel={() => setIsEditMode(false)}
        open={editIsOpen}>
        {editIsOpen && (
          <PresentationFormWrapper id={presentationId} slideId={id} onSuccess={() => setIsEditMode(false)}/>
        )}
      </Modal>
    </>
  )
}

export default PresentationSlideItem
